import { ArrowRightIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IPayment } from '../../lib/interfaces';
import { PaymentMethod, PaymentStatus } from '../../lib/constants/payments';

interface Props {
  payment: IPayment;
}

const SingleTransaction: React.FC<Props> = ({ payment }) => {
  const { t } = useTranslation('DASHBOARD');
  return (
    <div className="rounded-sm bg-white py-4 px-3">
      <div className="mb-1 flex items-center justify-between">
        <div>
          <p className="text-xs text-gray-500">
            {moment(payment?.attributes?.createdAt).format('DD/MM/YYYY HH:mm')}{' '}
            -{' '}
            {payment.attributes?.status === PaymentStatus.PENDING && (
              <span className="text-yellow-600">
                {t('MY_PAYMENTS:PENDING')}
              </span>
            )}
            {payment.attributes?.status === PaymentStatus.PAID && (
              <span className="text-green-600">{t('MY_PAYMENTS:PAID')}</span>
            )}
            {payment.attributes?.status === PaymentStatus.FAILED && (
              <span className="text-red-600">{t('MY_PAYMENTS:FAILED')}</span>
            )}
            {payment.attributes?.status === PaymentStatus.ERROR && (
              <span className="text-red-600">{t('MY_PAYMENTS:ERROR')}</span>
            )}
            {payment.attributes?.status === PaymentStatus.PROCESSING && (
              <span className="text-yellow-600">
                {t('MY_PAYMENTS:PROCESSING')}
              </span>
            )}
          </p>
          <p className="text-base text-gray-800">
            {t('TRANSACTION_CODE')}:{' '}
            <span className="font-semibold">{payment?.id}</span>
          </p>
          <p className="text-xs text-gray-500">
            {t('TRANSACTIONS:PAYMENT_BY')}{' '}
            {payment.attributes?.method === PaymentMethod.WIRE_TRANSFER && (
              <b className="text-teal-500">{t('MY_CONTRACTS:WIRE_TRANSFER')}</b>
            )}
            {payment.attributes?.method === PaymentMethod.STRIPE && (
              <b className="text-teal-500">{t('MY_CONTRACTS:CREDIT_CARD')}</b>
            )}
          </p>
        </div>
        <ArrowRightIcon className="h-5 w-5 text-gray-800" />
        <p className="text-lg font-medium text-gray-400">
          {payment?.attributes?.totalAmount?.toLocaleString('it')}
        </p>
      </div>
    </div>
  );
};

export default SingleTransaction;
