import axios, { AxiosResponse } from 'axios';
import { IContractResponse } from '../../interfaces';

const { REACT_APP_CONTRACTS_URL } = process.env;

const signContract = async (
  token: string,
  id: number,
  isUpdatingSubAmount: boolean
) => {
  const response = await axios.post<IContractResponse>(
    `${REACT_APP_CONTRACTS_URL}/${id}/confirmContract`,
    { isUpdatingSubAmount },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default signContract;
