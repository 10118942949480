import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import { BackButton, PrimaryButtonFull } from '../../components/Buttons';
import AppHead from '../../components/Headers/AppHead';
import HeaderPage from '../../components/Navigation/HederPage';
import Sidebar from '../../components/Navigation/Sidebar';
import RadioGroupPayment from '../../components/VariusElements/RadioGroupPayment';
import { PaymentMethod, PaymentType } from '../../lib/constants/payments';
import { IWireTransferDataAttributes } from '../../lib/fetch/wireTransfer';
import { IProduct } from '../../lib/interfaces';
import OneTime from './Components/OneTime';
import OneTimePac from './Components/OneTimePac';
import Pac from './Components/Pac';
import { ISubscriptionData } from '../../lib/interfaces/IContract';

type Props = {
  loading: boolean;
  product?: IProduct;
  paymentType: PaymentType;
  wireTransferData?: IWireTransferDataAttributes;
  onCreatePayment: (
    values: ISubscriptionData & { contractTitle?: string }
  ) => Promise<void>;
};

const PaymentTypePresentational = (props: Props) => {
  const {
    loading = false,
    product,
    paymentType,
    wireTransferData,
    onCreatePayment = () => {},
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation('MY_CONTRACTS');

  const formMethods = useForm<ISubscriptionData & { contractTitle?: string }>({
    defaultValues: {},
  });
  const { handleSubmit, setValue, watch } = formMethods;

  useEffect(() => {
    if (product) {
      setValue(
        'pacAmount',
        paymentType === PaymentType.ONE_TIME
          ? undefined
          : product?.attributes.minimumSubscription || 20
      );
    }

    formMethods.reset({
      subscriptionType: paymentType,
      oneTimeAmount: 0,
      paymentMethod: PaymentMethod.WIRE_TRANSFER,
      pacAmount: 0,
      pacDuration: 60,
      productName: product?.attributes.name,
    });
  }, [product, paymentType]);

  return (
    <>
      <AppHead name={t('HEAD_NAME_PAYMENT')} />
      <div className="flex h-full min-h-screen font-sans">
        <Sidebar />
        <div className="flex-1 lg:pl-52">
          <div className="py-10 px-6">
            <HeaderPage title={t('PAYMENT')} />

            <div className="w-full">
              <BackButton
                text={t('COMMON:BACK')}
                onClick={() => navigate(-1)}
              />
            </div>

            <form onSubmit={handleSubmit(onCreatePayment)}>
              <FormProvider {...formMethods}>
                <div className="mt-6 w-full lg:grid lg:grid-cols-12 lg:gap-8">
                  <main className="col-span-12 lg:col-span-7 xl:block">
                    {match(paymentType)
                      .with(PaymentType.ONE_TIME, () => (
                        <OneTime product={product} />
                      ))
                      .with(PaymentType.ONE_TIME_PAC, () => (
                        <OneTimePac product={product} />
                      ))
                      .with(PaymentType.PAC, () => <Pac product={product} />)
                      .exhaustive()}
                  </main>

                  <aside className="col-span-12 mb-6 flex flex-col gap-4 md:flex-row lg:col-span-5 lg:flex-col">
                    <div className="w-full rounded-md border border-gray-200 bg-gray-100  py-6">
                      <div className="border-b border-gray-200 px-4">
                        <h3 className="mb-4 text-lg font-medium text-purple">
                          {t('CHOOSE_METOD')}
                        </h3>
                      </div>
                      <div className="px-4">
                        <RadioGroupPayment
                          wireTransferData={wireTransferData}
                          paymentMethod={watch('paymentMethod')}
                          onClick={(paymentMethod) => {
                            setValue('paymentMethod', paymentMethod);
                          }}
                        />

                        <PrimaryButtonFull
                          text={t('COMMON:CHOOSE_AND_CONTINUE')}
                          type="submit"
                          loading={loading}
                          disabled={loading}
                        />
                      </div>
                    </div>
                  </aside>
                </div>
              </FormProvider>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentTypePresentational;
