import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { listPayments } from '../../lib/fetch/payments';
import { IPayment } from '../../lib/interfaces';
import { useAuth } from '../../providers/AuthProvider';
import TransactionHistoryPresentational from './TransactionHistoryPresentational';
import getCheckoutUrl from '../../lib/fetch/payments/getCheckoutUrl';
import { useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';

const TransactionHistory: React.FC = () => {
  const [{ user, token }] = useAuth();
  const { id: contractId } = useParams();
  const [payments, setPayments] = useState<IPayment[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation();

  const fetchListPayments = async (contractId?: number) => {
    const { error, data } = await listPayments({
      token: token as string,
      userId: user?.id as number,
      contractId,
      limit: Number(process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) || 25,
      page: currentPage,
    });
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    if (data) {
      const { meta } = data;
      setPageCount(meta.pagination.pageCount);
      setPayments(data.data || []);
    }
  };

  useEffect(() => {
    fetchListPayments(contractId ? Number(contractId) : undefined);
  }, [contractId, currentPage]);

  const onGetCheckoutUrl = async (id: number) => {
    const { error, data } = await getCheckoutUrl(token!, id);
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    if (data) {
      window.location.href = data;
    }
  };

  return (
    <TransactionHistoryPresentational
      payments={payments}
      currentPage={currentPage}
      pageCount={pageCount}
      onChangePage={setCurrentPage}
      getCheckoutUrl={onGetCheckoutUrl}
    />
  );
};

export default TransactionHistory;
