import { useTranslation } from 'react-i18next';
import { IContract } from '../../lib/interfaces';
import { ProductStatistics } from '../../lib/interfaces/IStatistics';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import Spinner from '../Spinner';
import ContractActionsComponent from './ContractActionsComponent';
import StatisticsSummary from './StatisticsSummary';
import { getProductStatistics } from '../../lib/utils/helpers';
import TrendLabel from '../Labels/TrendLabel';
import { useMemo } from 'react';

interface Props {
  contract?: IContract;
  contractStatsQuery: Partial<
    UseQueryResult<
      {
        error: AxiosResponse<any, any> | undefined;
        data: ProductStatistics;
        status: number;
        statusText: string;
        headers: AxiosResponseHeaders;
      },
      unknown
    >
  >;
}

const InvestmentsBoxDetail: React.FC<Props> = ({
  contract,
  contractStatsQuery,
}) => {
  const { t } = useTranslation('DASHBOARD');
  const { data: contractStatsData, isFetching: isFetchingContractStats } =
    contractStatsQuery;

  const productStats = useMemo(
    () => getProductStatistics(contractStatsData?.data),
    [contractStatsData]
  );

  const showIncrement = useMemo(
    () => Math.round(Number(productStats?.currentBalance || 0) / 100) * 100 > 0,
    []
  );

  return (
    <div className="w-full rounded-md border border-gray-200 px-4 py-6">
      {isFetchingContractStats && (
        <Spinner className="my-4 flex w-full text-purple" />
      )}
      {contractStatsData?.data && !isFetchingContractStats ? (
        <>
          <p className="font-regular mb-4 text-base text-violet">
            {t('INVESTMENT_VALUE')}
          </p>
          <div className="flex items-start justify-end gap-4">
            <div className="text-right">
              <p className="mb-1 text-5xl font-bold text-purple">
                {Number(
                  productStats?.currentBalance?.toFixed(2)
                )?.toLocaleString('it')}
              </p>
              {showIncrement && (
                <TrendLabel
                  className="mb-1 text-2xl font-bold"
                  price={productStats?.increment}
                />
              )}
            </div>
            <span className="mr-4 font-bold uppercase text-purple">eur</span>
          </div>
        </>
      ) : !isFetchingContractStats ? (
        t('NO_DATA')
      ) : null}

      {contractStatsData?.data && !isFetchingContractStats && (
        <StatisticsSummary
          contract={contract}
          statistics={contractStatsData.data}
          hidePerformance
        />
      )}

      <ContractActionsComponent
        contract={contract}
        contractStatsQuery={contractStatsQuery}
      />
    </div>
  );
};

export default InvestmentsBoxDetail;
