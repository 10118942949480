const SmallPrimaryButtonFull = ({
  text,
  onClick,
  type = 'button',
}: {
  text: string;
  onClick?: () => any;
  type?: 'button' | 'submit' | 'reset';
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className="flex w-full justify-center rounded-md border border-transparent bg-violet py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-purpleDark focus:outline-none"
    >
      {text}
    </button>
  );
};

export default SmallPrimaryButtonFull;
