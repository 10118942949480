import { ArrowRightIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ContractHistory } from '../../lib/interfaces';
import PACVariationLabel from './PACVariationLabel';

interface Props {
  history: ContractHistory;
}

const PACVariation: React.FC<Props> = ({ history }) => {
  const { t } = useTranslation('PAC_VARIATIONS');

  return (
    <div className="flex flex-col items-center justify-between rounded-md bg-gray-100 p-4 sm:flex-row">
      <div>
        <p className="flex flex-row gap-2 text-center text-lg text-gray-800 sm:w-1/3 sm:text-left">
          <span>{t('VARIATION_PAC')} </span>
          <span className="font-semibold">{history?.id}</span>
        </p>
        <span>{moment(history.date).format('DD/MM/YYYY hh:mm')}</span>
      </div>

      <ArrowRightIcon className="my-2  h-5 w-5 rotate-90 text-gray-800 sm:w-1/3 sm:rotate-0" />
      <div className="text-center sm:w-1/3 sm:text-right">
        {history.fromAmount && history.toAmount && (
          <PACVariationLabel
            from={history.fromAmount}
            to={history.toAmount}
            label={t('UPDATE_PAC_LABEL')}
          />
        )}
        {history.fromBankData && history.toBankData && (
          <PACVariationLabel
            from={`${history.fromBankData?.bankName} - ${history.fromBankData?.IBANCode} - ${history.fromBankData?.holder}`}
            to={`${history.toBankData?.bankName} - ${history.toBankData?.IBANCode} - ${history.toBankData?.holder}`}
            label={t('UPDATE_BANK_DATA_LABEL')}
          />
        )}
        {/* <div className="flex items-center justify-between">
          <div>
            <span className="font-semibold">
              {t('FROM_TO', { from: history.fromAmount, to: history.toAmount })}
            </span>
          </div>
          <p className="text-base text-gray-800">
            {moment(history.date).format('DD/MM/YYYY hh:mm')}
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default PACVariation;
