import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputPresentational from '../../../components/Form/InputPresentational';
import InfoBox from '../../../components/VariusElements/InfoBox';
import { IProduct, ISubscriptionData } from '../../../lib/interfaces';

type Props = {
  product?: IProduct;
};

const OneTime = ({ product }: Props) => {
  const { t } = useTranslation('MY_CONTRACTS');
  const {
    register,
    formState: { errors },
  } = useFormContext<ISubscriptionData & { contractTitle?: string }>();

  return (
    <div className="mb-8">
      <div className="mb-6 border-b border-gray-200 pb-4">
        <h3 className="mb-2 text-xl font-medium text-purple">
          {t('PAYMENT_ONE-TIME_TITLE')}
        </h3>
        <p
          className="text-sm text-gray-500"
          dangerouslySetInnerHTML={{
            __html: t('PAYMENT_ONE-TIME_DESCRIPTION'),
          }}
        />
      </div>

      <div className="mb-5">
        <InputPresentational
          label={'Nome contratto (opzionale)'}
          id="contractTitle"
          {...register('contractTitle')}
          type="string"
          autoComplete="false"
          hasError={!!errors.contractTitle}
          helperText={errors.contractTitle?.message}
        />
      </div>

      <InputPresentational
        label={t('ADD_IMPORT')}
        id="amount"
        {...register('oneTimeAmount', {
          required: t('COMMON:REQUIRED') as string,
          min: {
            value: product?.attributes?.initialIssuePrice || 20,
            message: t('MINIMUM_AMOUNT_VALUE', {
              amount: product?.attributes?.initialIssuePrice || 20,
            }),
          },
        })}
        type="number"
        autoComplete="false"
        hasError={!!errors.oneTimeAmount}
        helperText={errors.oneTimeAmount?.message}
      />
      <InfoBox description={t('INVESTMENT_INFO_ONE_TIME')} type={'info'} />
    </div>
  );
};

export default OneTime;
