import axios, { AxiosResponse } from 'axios';
import QueryString from 'qs';
import { IContractResponse } from '../../interfaces';

const instance = axios.create();

const { REACT_APP_CONTRACTS_URL } = process.env;

const update = async (token: string, id: number, data: FormData) => {
  const query = {
    populate: {
      contractData: {
        populate: '*',
      },
      product: '*',
    },
  };
  const response = await instance.put<IContractResponse>(
    `${REACT_APP_CONTRACTS_URL}/${id}?${QueryString.stringify(query)}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default update;
