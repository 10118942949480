import axios, { AxiosResponse } from 'axios';
import { IContract } from '../../interfaces';
import qs from 'qs';

const { REACT_APP_CONTRACTS_URL } = process.env;

export interface IContractsResponse {
  data: IContract[];
}

const list = async ({
  token,
  userId,
  status,
}: {
  token: string;
  userId: number;
  status?: string;
}) => {
  const query = {
    filters: {
      $and: [
        {
          user: userId,
          status,
        },
      ],
    },
    populate: {
      compiledContract: {
        populate: true,
      },
      contractData: {
        populate: {
          customerData: '*',
          bankData: {
            populate: {
              transferFiles: '*',
            },
          },
          contractSignature: '*',
          privacySignature: '*',
          customerDocument: {
            populate: {
              documentFiles: '*',
            },
          },
          subscriptionData: {
            populate: { updatePACFrequencyModule: '*' },
          },
        },
      },
      updatingData: {
        populate: {
          customerData: '*',
          bankData: '*',
          contractSignature: '*',
          privacySignature: '*',
          customerDocument: {
            populate: {
              documentFiles: '*',
            },
          },
          subscriptionData: {
            populate: { updatePACFrequencyModule: '*' },
          },
        },
      },
      subscription: '*',
      product: '*',
      history: {
        populate: {
          customerData: '*',
          bankData: '*',
          contractSignature: '*',
          privacySignature: '*',
          customerDocument: {
            populate: {
              documentFiles: '*',
            },
          },
          subscriptionData: {
            populate: { updatePACFrequencyModule: '*' },
          },
        },
      },
    },
  };

  const response = await axios.get<IContractsResponse>(
    `${REACT_APP_CONTRACTS_URL}?${qs.stringify(query)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default list;
