import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePickerPresentational from '../../../components/Form/DatePickerPresentational';
import InputPresentational from '../../../components/Form/InputPresentational';
import SelectPresentational from '../../../components/Form/SelectPresentational';
import { genderOptions } from '../../../lib/constants/survey';
import { IContractPayload } from '../../../lib/interfaces';

const fiscalCodeRegExp =
  /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/;

interface IStep1 {
  disabled: boolean;
}

const Step1: React.FC<IStep1> = (props) => {
  const { t } = useTranslation('SURVEY');
  const [nations, setNations] = useState<{ name: string; value: string }[]>([]);
  const { disabled } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext<IContractPayload>();

  useEffect(() => {
    (async () => {
      const { data } = await axios.get<{ name: string; code: string }[]>(
        'nations.json'
      );
      setNations(
        data.map((nation) => ({ name: nation.name, value: nation.code }))
      );
    })();
  }, []);

  return (
    <div className="pt-6 pb-12 md:px-10">
      <h2 className="mb-6 text-3xl font-bold text-purple">
        {t('CONTRACT.STEP1_TITLE')}
      </h2>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2  lg:grid-cols-4">
        <InputPresentational
          label={t('NAME')}
          type="text"
          id="firstName"
          autoComplete="given-name"
          {...register('contractData.customerData.firstName', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.contractData?.customerData?.firstName}
          helperText={errors.contractData?.customerData?.firstName?.message}
        />
        <InputPresentational
          label={t('SURNAME')}
          type="text"
          id="lastName"
          autoComplete="family-name"
          {...register('contractData.customerData.lastName', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.contractData?.customerData?.lastName}
          helperText={errors.contractData?.customerData?.lastName?.message}
        />
        <SelectPresentational
          label={t('GENRE')}
          id="genere"
          autoComplete="sex"
          {...register('contractData.customerData.gender', {
            required: t('COMMON:REQUIRED') as string,
          })}
          options={genderOptions}
          disabled={disabled}
          hasError={!!errors.contractData?.customerData?.gender}
          helperText={errors.contractData?.customerData?.gender?.message}
          hasEmptyOption
        />
        <InputPresentational
          label={t('FISCAL_CODE')}
          type="text"
          {...register('contractData.customerData.fiscalCode', {
            required: t('COMMON:REQUIRED') as string,
            pattern: {
              value: fiscalCodeRegExp,
              message: t('INVALID_FISCALCODE'),
            },
          })}
          disabled={disabled}
          hasError={!!errors.contractData?.customerData?.fiscalCode}
          helperText={errors.contractData?.customerData?.fiscalCode?.message}
        />
        <InputPresentational
          label={t('CELL_PHONE')}
          type="text"
          autoComplete="tel"
          {...register('contractData.customerData.phone', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.contractData?.customerData?.phone}
          helperText={errors.contractData?.customerData?.phone?.message}
        />
        <DatePickerPresentational
          id="birthDate"
          label={t('BIRTH_DATE')}
          autoComplete="bday"
          maxDate={moment().subtract(18, 'years').format('YYYY-MM-DD')}
          {...register('contractData.customerData.birthDate', {
            required: t('COMMON:REQUIRED') as string,
            validate: (date) =>
              new Date().getFullYear() - new Date(date!).getFullYear() >= 18 ||
              (t('DATE_UNDER_AGE') as string),
          })}
          disabled={disabled}
          hasError={!!errors.contractData?.customerData?.birthDate}
          helperText={errors.contractData?.customerData?.birthDate?.message}
        />
        <SelectPresentational
          label={t('BIRTH_STATE')}
          id="birthState"
          autoComplete="country-name"
          {...register('contractData.customerData.birthCountry', {
            required: t('COMMON:REQUIRED') as string,
          })}
          options={nations}
          disabled={disabled}
          hasError={!!errors?.contractData?.customerData?.birthCountry}
          helperText={errors?.contractData?.customerData?.birthCountry?.message}
          hasEmptyOption
        />
        {/* TODO: SELECT WITH OPTIONS */}
        <InputPresentational
          label={t('BIRTH_PROVINCE')}
          id="birthProvince"
          autoComplete="address-level2"
          {...register('contractData.customerData.birthDistrict', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors?.contractData?.customerData?.birthDistrict}
          helperText={
            errors?.contractData?.customerData?.birthDistrict?.message
          }
        />
        {/* TODO: SELECT WITH OPTIONS */}
        <InputPresentational
          label={t('BIRTH_PLACE')}
          id="birthPlace"
          autoComplete="address-level3"
          {...register('contractData.customerData.birthCity', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors?.contractData?.customerData?.birthCity}
          helperText={errors?.contractData?.customerData?.birthCity?.message}
        />
        <InputPresentational
          label={t('RESIDENTIAL_ADDRESS')}
          id="residentialAddress"
          autoComplete="address-line1"
          {...register('contractData.customerData.address', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors?.contractData?.customerData?.address}
          helperText={errors?.contractData?.customerData?.address?.message}
        />
        <InputPresentational
          label={t('STREET_NUMBER')}
          type="text"
          autoComplete="address-line2"
          {...register('contractData.customerData.houseNumber', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors?.contractData?.customerData?.houseNumber}
          helperText={errors?.contractData?.customerData?.houseNumber?.message}
        />
        <InputPresentational
          label={t('CAP')}
          type="text"
          autoComplete="postal-code"
          {...register('contractData.customerData.postalCode', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors?.contractData?.customerData?.postalCode}
          helperText={errors?.contractData?.customerData?.postalCode?.message}
        />
        <SelectPresentational
          label={t('STATE')}
          id="state"
          autoComplete="country-name"
          {...register('contractData.customerData.country', {
            required: t('COMMON:REQUIRED') as string,
          })}
          options={nations}
          disabled={disabled}
          hasError={!!errors?.contractData?.customerData?.country}
          helperText={errors?.contractData?.customerData?.country?.message}
          hasEmptyOption
        />
        {/* TODO: SELECT WITH OPTIONS */}
        <InputPresentational
          label={t('PROVINCE')}
          type="text"
          autoComplete="address-level2"
          {...register('contractData.customerData.district', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors?.contractData?.customerData?.district}
          helperText={errors?.contractData?.customerData?.district?.message}
        />
        {/* TODO: SELECT WITH OPTIONS */}
        <InputPresentational
          label={t('CITY')}
          type="text"
          autoComplete="address-level3"
          {...register('contractData.customerData.city', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors?.contractData?.customerData?.city}
          helperText={errors?.contractData?.customerData?.city?.message}
        />
      </div>

      <fieldset className="mt-8 space-y-5 md:w-2/4">
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="terms-privacy"
              aria-describedby="terms-description"
              type="checkbox"
              {...register('contractData.subscriptionData.privacyConsent', {
                required: t('COMMON:REQUIRED') as string,
              })}
              className="h-4 w-4 rounded border-gray-300 text-purple focus:outline-none focus:ring-0"
            />
          </div>
          <div className="ml-3 text-xs">
            <label
              htmlFor="terms-privacy"
              className="font-normal text-gray-400"
            >
              Ho preso visione dell'
              <a
                href={process.env.PUBLIC_URL + '/documents/TermsConditions.pdf'}
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer text-purple"
              >
                informativa sul trattamento dei dati personali
              </a>{' '}
              ai sensi degli artt. 13 e 14 del Regolamento (UE) 2016/679 (GDPR)
              *
            </label>
          </div>
        </div>

        {!!errors.contractData?.subscriptionData?.privacyConsent && (
          <>
            {/* TODO: NEED DESIGNER HELP FOR POSITIONING */}
            {/* <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="m-2 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div> */}
            <p className="mt-2 text-sm text-red-600" id={'terms-error'}>
              {errors.contractData?.subscriptionData?.privacyConsent.message}
            </p>
          </>
        )}
      </fieldset>

      <fieldset className="mt-8 space-y-5 md:w-2/4">
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="terms"
              aria-describedby="terms-description"
              type="checkbox"
              {...register(
                'contractData.subscriptionData.pacAutomaticChargeConsent',
                {
                  required: t('COMMON:REQUIRED') as string,
                }
              )}
              className="h-4 w-4 rounded border-gray-300 text-purple focus:outline-none focus:ring-0"
            />
          </div>
          <div className="ml-3 text-xs">
            <label htmlFor="terms" className="font-normal text-gray-400">
              Fai un bonifico subito e dal secondo è preselezionato l'addebito
              in conto *
            </label>
          </div>
        </div>

        {!!errors.contractData?.subscriptionData?.pacAutomaticChargeConsent && (
          <>
            {/* TODO: NEED DESIGNER HELP FOR POSITIONING */}
            {/* <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="m-2 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div> */}
            <p className="mt-2 text-sm text-red-600" id={'terms-error'}>
              {
                errors.contractData?.subscriptionData?.pacAutomaticChargeConsent
                  .message
              }
            </p>
          </>
        )}
      </fieldset>
    </div>
  );
};

export default Step1;
