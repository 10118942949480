import { PlusIcon } from '@heroicons/react/outline';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputPresentational from '../../../components/Form/InputPresentational';
import SelectPresentational from '../../../components/Form/SelectPresentational';
import InfoBox from '../../../components/VariusElements/InfoBox';
import {
  SUBSCRIPTION_DURATION_OPTIONS,
  SUBSCRIPTION_FREQUENCY_OPTIONS,
} from '../../../lib/constants/subscription';
import { IProduct } from '../../../lib/interfaces';
import { ISubscriptionData } from '../../../lib/interfaces/IContract';

type Props = {
  product?: IProduct;
};

const OneTimePac = ({ product }: Props) => {
  const { t } = useTranslation('MY_CONTRACTS');
  const {
    register,
    formState: { errors },
  } = useFormContext<ISubscriptionData & { contractTitle?: string }>();
  return (
    <div className="mb-8">
      <div className="mb-6 border-b border-gray-200 pb-4">
        <div className="flex items-center justify-between gap-2">
          <h3 className="mb-2 text-xl font-medium text-purple">
            {t('PAYMENT_ONE-TIME_PAC_TITLE')}
          </h3>
          <p className="text-sm lowercase text-violet">
            {t('MINIMUM_MONTHLY')}
            <span className="ml-2 inline-flex items-center rounded-md bg-violet px-2.5 py-0.5 text-sm font-medium text-white">
              € {product?.attributes?.minimumSubscription}
            </span>
          </p>
        </div>
        <p
          className="text-sm text-gray-500"
          dangerouslySetInnerHTML={{
            __html: t('PAYMENT_ONE-TIME_PAC_DESCRIPTION'),
          }}
        />
      </div>

      <div className="mb-10">
        <InputPresentational
          label={'Nome contratto (opzionale)'}
          id="contractTitle"
          {...register('contractTitle')}
          type="string"
          autoComplete="false"
          hasError={!!errors.contractTitle}
          helperText={errors.contractTitle?.message}
        />
      </div>

      <div className="w-full rounded-md border border-gray-200 p-4">
        <p className="mb-4 text-center">
          <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-purple">
            {t('PAYMENT_ONE-TIME_TITLE')}
          </span>
        </p>
        <InputPresentational
          label={t('ADD_IMPORT')}
          id="amount"
          {...register('oneTimeAmount', {
            required: t('COMMON:REQUIRED') as string,
            min: {
              value: product?.attributes?.initialIssuePrice || 20,
              message: t('MINIMUM_AMOUNT_VALUE', {
                amount: product?.attributes?.initialIssuePrice || 20,
              }),
            },
          })}
          type="number"
          autoComplete="false"
          hasError={!!errors.oneTimeAmount}
          helperText={errors.oneTimeAmount?.message}
        />
      </div>

      <PlusIcon className="mx-auto my-4 h-8 w-8 text-violet" />

      <div className="mb-6 w-full rounded-md border border-gray-200 p-4">
        <p className="mb-4 text-center">
          <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-purple">
            {t('PAYMENT_PAC_TITLE')}
          </span>
        </p>
        <div className="mb-6 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <InputPresentational
            label={t('ADD_IMPORT')}
            id="subscriptionAmount"
            {...register('pacAmount', {
              required: t('COMMON:REQUIRED') as string,
              min: {
                value: product?.attributes?.minimumSubscription || 20,
                message: t('MINIMUM_AMOUNT_VALUE', {
                  amount: product?.attributes?.minimumSubscription || 20,
                }),
              },
            })}
            type="number"
            autoComplete="false"
            hasError={!!errors.pacAmount}
            helperText={errors.pacAmount?.message}
          />
          <SelectPresentational
            label={t('INVESTMENT_DURATION')}
            id="subscriptionDuration"
            {...register('pacDuration', {
              required: t('COMMON:REQUIRED') as string,
            })}
            autoComplete="false"
            hasError={!!errors.pacDuration}
            helperText={errors.pacDuration?.message}
            options={SUBSCRIPTION_DURATION_OPTIONS}
          />
          <SelectPresentational
            label={t('INVESTMENT_FREQUENCY')}
            id="subscriptionFrequency"
            {...register('pacFrequency', {
              required: t('COMMON:REQUIRED') as string,
            })}
            autoComplete="false"
            hasError={!!errors.pacFrequency}
            helperText={errors.pacFrequency?.message}
            options={SUBSCRIPTION_FREQUENCY_OPTIONS}
          />
        </div>
        <InfoBox
          description={t('INVESTMENT_INFO_ONE_TIME_PAC')}
          type={'info'}
        />
      </div>
    </div>
  );
};

export default OneTimePac;
