import { PencilIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import ProfileMenu from './ProfileMenu';
import SidebarMobile from './SidebarMobile';
import { useMatch } from 'react-router-dom';

export default function HeaderPage({ title }: { title: string }) {
  const isProfileRoute = useMatch('/profile');
  return (
    <div className="mb-8 flex items-center justify-between">
      <div className="flex items-center">
        <SidebarMobile />
        <h2 className="font-regular text-xl text-purple md:text-3xl">
          {title}
        </h2>
        {isProfileRoute && (
          <div className="ml-[10px] flex items-center justify-between text-sm font-medium text-purple">
            <Link className="ml-auto" to="/survey" state={{ isEditing: true }}>
              <PencilIcon className="h-10 w-10 rounded-md border border-gray-200 bg-white p-1 text-purple" />
            </Link>
          </div>
        )}
      </div>

      <ProfileMenu />
    </div>
  );
}
