import { useEffect, useMemo, useState } from 'react';
import { getContract } from '../../lib/fetch/contracts';
import { useAuth } from '../../providers/AuthProvider';
import ContractDetailPresentational from './ContractDetailPresentational';
import { IContract, IPayment } from '../../lib/interfaces';
import { useParams } from 'react-router-dom';
import { listPayments } from '../../lib/fetch/payments';
import { useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import { ChartFilters } from '../Dashboard';
import updateContractTitle from '../../lib/fetch/contracts/updateContractTitle';
import { AlertType } from '../../providers/AlertProvider/index';
import { useQuery } from '@tanstack/react-query';
import { getContractStats } from '../../lib/fetch/statistics';
import moment from 'moment';
import { getChartData } from '../../lib/fetch/products';
import { ProductType } from '../../lib/constants/payments';
import couponBondsAPIs from '../../lib/fetch/couponBonds/intex';
import { useQueryParam } from 'use-query-params';

const ContractDetail: React.FC<{}> = () => {
  const [{ token, user }] = useAuth();
  const { id: contractId } = useParams();
  const [contract, setContract] = useState<IContract>();
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [bondsCouponPage] = useQueryParam('page');

  const [paymentsDateFilter, setPaymentsDateFilter] = useState<
    string | undefined
  >();
  const [chartFilters, setChartFilters] = useState<ChartFilters>({
    from: moment().subtract(1, 'month').toDate(),
    to: moment().toDate(),
  });
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation();

  const updateContractName = async (contractTitle: string) => {
    const { error } = await updateContractTitle(token!, {
      id: contract?.id,
      contractTitle,
    });
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    dispatchAlertChange({
      open: true,
      message: 'Nome contratto aggiornato correttamente',
      type: AlertType.Success,
    });
    fetchContract(contract!.id);
  };

  const fetchContract = async (id: IContract['id']) => {
    const { error, data } = await getContract(token as string, id);
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    if (data) {
      setContract(data.data);
    }
  };

  useEffect(() => {
    if (contractId) fetchContract(Number(contractId));
  }, [contractId]);

  const fetchListPayments = async (contractId: number) => {
    const { data, error } = await listPayments({
      token: token as string,
      userId: user?.id as number,
      contractId,
      page: 1,
      limit: 2,
      createdAt: paymentsDateFilter,
    });
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    if (data) {
      setPayments(data.data || []);
    }
  };

  useEffect(() => {
    if (!contract) return;
    fetchListPayments(contract.id);
  }, [contract, paymentsDateFilter]);

  const contractStatsQuery = useQuery(
    ['getContractStats'],
    () =>
      getContractStats({
        token,
        contractId: contractId,
        userId: user?.id + '',
      }),
    { cacheTime: 0, staleTime: 0, refetchOnWindowFocus: false }
  );

  // const contractChartQuery = useQuery(
  //   ['getContractChart', paymentsDateFilter],
  //   () => getContractChart({ token, id: contractId }),
  //   {
  //     refetchOnWindowFocus: false,
  //     onError: (err: any) => {
  //       return dispatchAlertChange({
  //         open: true,
  //         message: err.message
  //           ? t(`ERRORS:${err.message}`)
  //           : t('COMMON:GENERIC_ERROR'),
  //       });
  //     },
  //   }
  // );

  const contractChartQuery = useQuery(
    ['getChartData', contract?.attributes?.product?.data?.id],
    () => {
      if (contract?.attributes?.product?.data?.id)
        return getChartData(token!, contract?.attributes?.product?.data?.id!);
      return null;
    },
    {
      onError: (error: any) => {
        let { data: { error: { message = undefined } = {} } = {} } = error;
        return dispatchAlertChange({
          open: true,
          message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
        });
      },
      enabled:
        contract?.attributes?.product?.data?.attributes?.type ===
        ProductType.STANDARD,
    }
  );

  const filteredChartData = useMemo(() => {
    if (!chartFilters.from && !chartFilters.to)
      return contractChartQuery.data?.data;
    return contractChartQuery.data?.data.filter(
      (d) =>
        d.timestamp > (chartFilters.from?.valueOf() || 0) &&
        d.timestamp < (chartFilters.to?.valueOf() || Infinity)
    );
  }, [contractChartQuery.data?.data, chartFilters]);

  const { data: couponBondsData } = useQuery(
    ['getCoupons', bondsCouponPage, contract?.id],
    () =>
      couponBondsAPIs.find({
        token,
        query: {
          pagination: {
            pageSize: 10,
            page: bondsCouponPage || 1,
          },
          filters: {
            contract: contract?.id,
          },
          sort: {
            date: 'desc',
            id: 'desc',
          },
        },
      }),
    {
      enabled:
        !!contract?.id &&
        contract.attributes?.product?.data?.attributes?.type ===
          ProductType.STATIC,
    }
  );

  return (
    <ContractDetailPresentational
      contract={contract}
      payments={payments}
      chartData={filteredChartData || []}
      chartFilters={chartFilters}
      onChangeChartFilters={setChartFilters}
      onChangePaymentsDateFilter={setPaymentsDateFilter}
      paymentsDateFilter={paymentsDateFilter}
      onUpdateTitle={updateContractName}
      contractStatsQuery={contractStatsQuery}
      contractChartQuery={contractChartQuery}
      couponBondsData={couponBondsData}
    />
  );
};

export default ContractDetail;
