import axios, { AxiosResponse } from 'axios';
import { ContractSignatureType } from '../../interfaces';

const { REACT_APP_CONTRACTS_URL } = process.env;

const signContract = async (
  token: string,
  id: number,
  signatureName: ContractSignatureType,
  code: string,
  isUpdatingSubAmount: boolean
) => {
  const response = await axios.post(
    `${REACT_APP_CONTRACTS_URL}/${id}/verifySignature`,
    {
      signatureName,
      code,
      isUpdatingSubAmount,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
    throw error;
  }

  return response;
};

export default signContract;
