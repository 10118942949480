import axios, { AxiosResponse } from 'axios';
import { IUser } from '../../interfaces';

const { REACT_APP_UPDATE_PROFILE_URL } = process.env;

const update = async (token: string, updatedUser: Partial<IUser>) => {
  const response = await axios.put<IUser>(
    `${REACT_APP_UPDATE_PROFILE_URL!}/${updatedUser.id}`,
    updatedUser,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default update;
