import moment from 'moment';
import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePickerPresentational from '../../../components/Form/DatePickerPresentational';
import DragAndDrop from '../../../components/Form/DragAndDrop';
import FileUploadPrev from '../../../components/Form/FileUploadPrev';
import InputPresentational from '../../../components/Form/InputPresentational';
import SelectPresentational from '../../../components/Form/SelectPresentational';
import { documentTypeOptions } from '../../../lib/constants/survey';
import { IContractPayload, IStrapiFile } from '../../../lib/interfaces';
import { useAuth } from '../../../providers/AuthProvider';
interface IStep2 {
  files: IStrapiFile[];
  onFileDelete: (_: IStrapiFile) => Promise<void>;
  disabled: boolean;
}

const Step2: React.FC<IStep2> = (props) => {
  const [{ user }] = useAuth();
  const { files = [], onFileDelete, disabled } = props;
  const { t } = useTranslation('SURVEY');
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<IContractPayload>();

  const formFiles = watch('contractData.customerDocument.documentFiles', []);

  return (
    <div className="pt-6 pb-12 md:px-10">
      <h2 className="mb-6 text-3xl font-bold text-purple">
        {t('CONTRACT.STEP2_TITLE')}
      </h2>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2  lg:grid-cols-4">
        <SelectPresentational
          label={t('DOCUMENT_TYPE')}
          id="documentType"
          {...register('contractData.customerDocument.type', {
            required: t('COMMON:REQUIRED') as string,
          })}
          options={documentTypeOptions}
          disabled={!!user?.kyc?.documentType}
          hasError={!!errors?.contractData?.customerData?.birthCountry}
          helperText={errors?.contractData?.customerData?.birthCountry?.message}
        />
        <InputPresentational
          label={t('DOCUMENT_NUMBER')}
          type="text"
          id="documentNumber"
          {...register('contractData.customerDocument.number', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={!!user?.kyc?.documentNumber}
          hasError={!!errors.contractData?.customerDocument?.number}
          helperText={errors.contractData?.customerDocument?.number?.message}
        />
        <DatePickerPresentational
          id="birthDate"
          label={t('DOCUMENT_ISSUE_DATE')}
          maxDate={moment().format('YYYY-MM-DD')}
          {...register('contractData.customerDocument.releaseDate', {
            required: t('COMMON:REQUIRED') as string,
            // validate: (date) =>
            //   new Date().getFullYear() - new Date(date!).getFullYear() >= 18 ||
            //   (t('DATE_UNDER_AGE') as string),
          })}
          disabled={!!user?.kyc?.documentIssueDate}
          hasError={!!errors.contractData?.customerDocument?.releaseDate}
          helperText={
            errors.contractData?.customerDocument?.releaseDate?.message
          }
        />
        <InputPresentational
          label={t('DOCUMENT_ISSUE_ENTITY')}
          type="text"
          id="documentIssuingEntity"
          {...register('contractData.customerDocument.issuingEntity', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={!!user?.kyc?.documentIssueEntity}
          hasError={!!errors.contractData?.customerDocument?.issuingEntity}
          helperText={
            errors.contractData?.customerDocument?.issuingEntity?.message
          }
        />
        <InputPresentational
          label={t('DOCUMENT_CONTRY')}
          type="text"
          id="documentCountry"
          {...register('contractData.customerDocument.country', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={!!user?.kyc?.documentCountry}
          hasError={!!errors.contractData?.customerDocument?.country}
          helperText={
            errors.contractData?.customerDocument?.issuingEntity?.message
          }
        />
      </div>
      <div className="mt-10 grid grid-cols-1 gap-6 md:grid-cols-2  lg:grid-cols-2">
        <div>
          {!disabled && (
            <>
              <label className="block font-sans text-base font-normal text-purple">
                Foto Documento
              </label>
              <DragAndDrop
                maxFiles={2}
                acceptedFiles={['image/png', 'image/jpeg', 'application/pdf']}
                onFileChange={async (files) =>
                  setValue('contractData.customerDocument.documentFiles', [
                    ...(formFiles || []),
                    ...files,
                  ])
                }
                {...register('contractData.customerDocument.documentFiles', {
                  required: t('COMMON:REQUIRED') as string,
                })}
              />
              {errors.contractData?.customerDocument?.documentFiles && (
                <p className={`mt-2 text-sm text-red-600`}>
                  {
                    (
                      errors.contractData?.customerDocument
                        ?.documentFiles as unknown as FieldError
                    ).message
                  }
                </p>
              )}
            </>
          )}

          <div className="mt-8 flex flex-col gap-4">
            {files.map((file, index) => (
              <FileUploadPrev
                key={index}
                file={file}
                onDelete={(file) => onFileDelete(file as IStrapiFile)}
                disabled={disabled}
              />
            ))}
            {formFiles?.map((file, index) =>
              file instanceof File ? (
                <FileUploadPrev
                  key={index}
                  file={file}
                  onDelete={async () =>
                    setValue(
                      'contractData.customerDocument.documentFiles',
                      formFiles.filter((_, idx) => index !== idx)
                    )
                  }
                />
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
