/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmPhone, sendPhoneConfirmation } from '../../lib/fetch/2fa';
import { useAlert } from '../../providers/AlertProvider';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import ConfirmNumberPresentational from './ConfirmNumberPresentational';

export interface IVerifyCodeForm {
  code: string;
}

const ConfirmNumber = () => {
  const [loading, setLoading] = useState(false);
  const [, dispatchAlertChange] = useAlert();
  const [{ token }, dispatchAuthChange] = useAuth();
  const location = useLocation();
  const { redirectTo } = (location.state || {}) as { redirectTo: string };
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [lang] = i18n.languages;
  const [locale] = lang.split('-');

  // TODO: UNCOMMENT WHEN SMS OTP IS OK

  // useEffect(() => {
  //   sendPhoneVerify();
  // }, []);

  // const sendPhoneVerify = async () => {
  //   setLoading(true);
  //   const { error } = await sendPhoneConfirmation(token!, locale);
  //   if (error) {
  //     let { data: { error: { message = undefined } = {} } = {} } = error;
  //     setLoading(false);
  //     return dispatchAlertChange({
  //       open: true,
  //       message,
  //     });
  //   }

  //   setLoading(false);
  // };

  const onVerifyCode = async (values: IVerifyCodeForm) => {
    setLoading(true);
    const { error, data } = await confirmPhone(token!, values.code);
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }
    const { user } = data;
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user,
    });

    setLoading(false);
    redirectTo ? navigate(redirectTo) : navigate('/');
  };

  useEffect(() => {
    redirectTo ? navigate(redirectTo) : navigate('/');

    return () => {};
  }, [redirectTo]);

  return (
    <ConfirmNumberPresentational loading={loading} onSubmit={onVerifyCode} />
  );
};

export default ConfirmNumber;
