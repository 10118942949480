import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputPresentational from '../../../components/Form/InputPresentational';
import { IContractPayload, IStrapiFile } from '../../../lib/interfaces';

const ibanRegExp = /^[A-Z]{2}([a-zA-Z0-9]){13,33}$/;

interface IStep3 {
  files: IStrapiFile[];
  onFileDelete: (_: IStrapiFile) => Promise<void>;
  disabled: boolean;
}

const Step3: React.FC<IStep3> = ({ disabled }) => {
  const { t } = useTranslation('SURVEY');

  const {
    register,
    formState: { errors },
  } = useFormContext<IContractPayload>();

  return (
    <div className="pt-6 pb-12 md:px-10">
      <h2 className="mb-6 text-3xl font-bold text-purple">
        {t('CONTRACT.STEP3_TITLE')}
      </h2>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2  lg:grid-cols-3">
        <InputPresentational
          label={t('BANK')}
          type="text"
          id="bank"
          {...register('contractData.bankData.bank', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.contractData?.bankData?.bank}
          helperText={errors.contractData?.bankData?.bank?.message}
        />
        <InputPresentational
          label={t('IBAN')}
          type="text"
          id="iban"
          {...register('contractData.bankData.iban', {
            required: t('COMMON:REQUIRED') as string,
            pattern: {
              value: ibanRegExp,
              message: t('INVALID_IBAN'),
            },
          })}
          disabled={disabled}
          hasError={!!errors.contractData?.bankData?.iban}
          helperText={errors.contractData?.bankData?.iban?.message}
        />
        <InputPresentational
          label={t('REGISTERED_TO')}
          type="text"
          id="registeredTo"
          {...register('contractData.bankData.registeredTo', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.contractData?.bankData?.registeredTo}
          helperText={errors.contractData?.bankData?.registeredTo?.message}
        />
      </div>
    </div>
  );
};

export default Step3;
