import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/solid';
import classNames from '../../lib/utils/classNames';

interface Props {
  onChangePage: (page: number) => void;
  currentPage: number;
  pageCount: number;
}

const Pagination: React.FC<Props> = ({
  currentPage,
  pageCount,
  onChangePage,
}) => {
  return (
    <nav className="mt-8 flex items-center justify-between border-t border-gray-200 py-2 px-4 sm:px-0 lg:pl-52">
      <div className="page-item -mt-px flex w-0 flex-1">
        {currentPage > 1 && (
          <button
            onClick={() => onChangePage(currentPage - 1)}
            className="page-link relative block rounded border-0 bg-transparent py-1.5 px-3 text-sm font-medium text-gray-500 outline-none transition-all duration-300 hover:border-gray-300 hover:text-gray-700"
          >
            <ArrowNarrowLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </button>
        )}
      </div>

      <div className="page-item -mt-px hidden w-0 flex-1 md:-mt-px md:flex">
        {[...Array(pageCount)].map((_, idx) => (
          <button
            className={classNames(
              currentPage === idx + 1 && '!bg-violet text-white',
              'page-link relative block rounded border-0 bg-transparent py-1.5 px-3 text-sm font-medium text-gray-500 outline-none transition-all duration-300 hover:border-gray-300 hover:text-gray-700'
            )}
            onClick={() => onChangePage(idx + 1)}
            key={idx}
          >
            {idx + 1}
          </button>
        ))}
      </div>
      <div className="page-item -mt-px flex w-0 flex-1 justify-end">
        {currentPage < pageCount && (
          <button
            onClick={() => onChangePage(currentPage + 1)}
            className="page-link relative block rounded border-0 bg-transparent py-1.5 px-3 text-sm font-medium text-gray-500 outline-none transition-all duration-300 hover:border-gray-300 hover:text-gray-700"
          >
            Next
            <ArrowNarrowRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        )}
      </div>
    </nav>
  );
};

export default Pagination;
