import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createEnumParam, NumberParam, useQueryParams } from 'use-query-params';
import { PaymentType } from '../../lib/constants/payments';
import { getProduct } from '../../lib/fetch/products';
import {
  IWireTransferDataAttributes,
  list as getWireTransferData,
} from '../../lib/fetch/wireTransfer';
import { IProduct, ISubscriptionData } from '../../lib/interfaces';
import UnauthorizedPage from '../../lib/utils/UnauthorizedPage';
import { useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import PaymentPresentational from './PaymentPresentational';
import qs from 'query-string';

const Payment = () => {
  const [product, setProduct] = useState<IProduct>();
  const [{ productId, paymentType, contractId }] = useQueryParams({
    productId: NumberParam,
    contractId: NumberParam,
    paymentType: createEnumParam([
      PaymentType.ONE_TIME,
      PaymentType.ONE_TIME_PAC,
      PaymentType.PAC,
    ]),
  });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [, dispatchAlertChange] = useAlert();
  const navigate = useNavigate();
  const [{ token, isVerified }] = useAuth();
  const [wireTransferData, setWireTransferData] = useState<
    IWireTransferDataAttributes | undefined
  >();

  useEffect(() => {
    fetchWireTransferData();
  }, []);

  useEffect(() => {
    if (!productId) return;
    fetchProduct(productId);
  }, [productId]);

  const fetchWireTransferData = async () => {
    setLoading(true);
    const { data, error } = await getWireTransferData(token!);
    setLoading(false);
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    if (data && data.data.attributes) {
      setWireTransferData(data.data.attributes);
    }
  };

  const fetchProduct = async (id: number) => {
    setLoading(true);
    const { error, data } = await getProduct(token!, id);
    setLoading(false);
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
      return navigate('/products');
    }
    if (data) {
      setProduct(data.data);
    }
  };

  const onCreatePayment = async (
    values: ISubscriptionData & { contractTitle?: string }
  ) => {
    const query = {
      ...values,
      productId: product?.id,
      isUpdatingSubAmount: !!contractId || undefined,
      contractId: contractId || undefined,
    };
    return navigate(`/subscribe?${qs.stringify(query)}`);
  };

  if (!isVerified)
    return (
      <UnauthorizedPage
        message="Completa il tuo profilo per effettuare un versamento"
        redirect={'/profile'}
      />
    );

  if (!productId)
    return (
      <UnauthorizedPage
        message="Nessun prodotto selezionato"
        redirect={'/products'}
      />
    );

  if (!paymentType)
    return (
      <UnauthorizedPage
        message="Nessun metodo di pagamento selezionato"
        redirect={`/payment-type?productId=${productId}`}
      />
    );

  return (
    <PaymentPresentational
      loading={loading}
      product={product}
      paymentType={paymentType}
      wireTransferData={wireTransferData}
      onCreatePayment={onCreatePayment}
    />
  );
};

export default Payment;
