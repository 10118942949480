import React from 'react';
import { useTranslation } from 'react-i18next';

import FileReadPrev from '../../../components/Form/FileReadPrev ';
import { IContract } from '../../../lib/interfaces';
import { useMutation } from '@tanstack/react-query';
import { downloadFile } from '../../../lib/fetch/commons/downloadFilte';
import { useAlert } from '../../../providers/AlertProvider';

interface IStep4 {
  contract: IContract | undefined;
  isUpdatingSubAmount: boolean;
  isLoading: boolean;
}

const Step4: React.FC<IStep4> = ({ contract, isUpdatingSubAmount }) => {
  const { t } = useTranslation('SURVEY');
  const [, dispatchAlertChange] = useAlert();

  const { mutate: download } = useMutation(['downloadFile'], downloadFile, {
    onSuccess: (data) => {},
    onError: (err) => {
      console.log('err', JSON.stringify(err, null, 2));
      dispatchAlertChange({
        open: true,
        message: t('COMMON:GENERIC_ERROR'),
      });
    },
  });

  const { compiledContract } = contract?.attributes || {};
  const subscriptionUpdateForm =
    contract?.attributes?.updatingData?.subscriptionData
      ?.updatePACFrequencyModule;

  return (
    <div className="pt-6 pb-12 md:px-10">
      <h2 className="mb-6 text-3xl font-bold text-purple">
        {t('CONTRACT.STEP4_TITLE')}
      </h2>
      <div className="flex flex-wrap items-center gap-4 rounded-md bg-gray-100 py-6 px-4">
        {!!compiledContract && !isUpdatingSubAmount && (
          <FileReadPrev
            file={compiledContract?.data?.attributes || {}}
            onClick={() =>
              download({
                url: compiledContract?.data?.attributes.url,
                fileName: compiledContract?.data?.attributes.name,
              })
            }
          />
        )}
        {!!isUpdatingSubAmount && subscriptionUpdateForm && (
          <FileReadPrev
            file={subscriptionUpdateForm?.data?.attributes || {}}
            onClick={() =>
              download({
                url: compiledContract?.data?.attributes.url,
                fileName: subscriptionUpdateForm?.data?.attributes.name,
              })
            }
          />
        )}
        <FileReadPrev
          file={{
            name: 'Allegato alla sottoscrizione.pdf',
            url:
              process.env.PUBLIC_URL +
              '/documents/Allegato alla sottoscrizione.pdf',
          }}
          onClick={() =>
            download({
              url:
                process.env.PUBLIC_URL +
                '/documents/Allegato alla sottoscrizione.pdf',
              fileName: 'Allegato alla sottoscrizione.pdf',
            })
          }
        />
      </div>
    </div>
  );
};

export default Step4;
