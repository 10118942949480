import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  // OutlinePrimaryButtonFull,
  PrimaryButtonFull,
} from '../../components/Buttons';
import AppHead from '../../components/Headers/AppHead';
import BgLogin from '../../components/VariusElements/BgLogin';
import RespLogoLogin from '../../components/VariusElements/RespLogoLogin';

const RegistrationMadePresentational = () => {
  const { t } = useTranslation('LOGIN');
  return (
    <>
      <AppHead name={t('HEAD_NAME_REGISTRATION_MADE')} />
      <div className="flex min-h-screen font-sans">
        <BgLogin />

        <div className="relative flex w-3/5 flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <RespLogoLogin />

          <div className="mx-auto w-full max-w-xl">
            <h2 className="text-3xl font-bold text-purple">
              {t('REGISTRATION_MADE')}
            </h2>
            <p className="mt-2 text-base text-gray-800">
              {t('REGISTRATION_MADE_DESCRIPTION')}
            </p>
            <div className="mt-12 flex w-full flex-col items-center gap-6">
              <Link to="/survey" className="w-full">
                <PrimaryButtonFull text={t('FILL_IN_THE_QUESTIONNAIRE')} />
              </Link>
              {/* <Link to="/kyc" className="w-full">
                <OutlinePrimaryButtonFull text={t('IDENTIFY')} />
              </Link> */}
              <Link
                to="/"
                className="font-bold text-purple underline underline-offset-1 hover:text-violet"
              >
                {t('GO_TO_DASHBOARD')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationMadePresentational;
