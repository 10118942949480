import axios, { AxiosResponse } from 'axios';
import { IUserAssessment } from '../../interfaces';

const { REACT_APP_USER_ASSESSMENTS_URL } = process.env;

export interface IUserAssessmentCreate extends Omit<IUserAssessment, 'id'> {
  users_permissions_user: number;
}

export interface IUserAssessmentCreateResponse {
  id: number;
  attributes: Omit<IUserAssessment, 'id'>;
}

const create = async (token: string, assesment: IUserAssessmentCreate) => {
  const response = await axios.post<{ data: IUserAssessmentCreateResponse }>(
    REACT_APP_USER_ASSESSMENTS_URL!,
    { data: assesment },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default create;
