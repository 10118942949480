import classNames from '../../lib/utils/classNames';
import Spinner from '../Spinner';

interface IPrimaryButtonFull {
  text: string;
  type?: 'button' | 'submit';
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}
const PrimaryButtonFull = ({
  text,
  type = 'button',
  loading = false,
  disabled = false,
  onClick = () => {},
}: IPrimaryButtonFull) => {
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
      className={classNames(
        'flex w-full justify-center rounded-md border border-transparent bg-violet py-2 px-4 text-base font-bold text-white shadow-sm  focus:outline-none',
        'hover:bg-purple disabled:cursor-not-allowed disabled:bg-violet'
      )}
    >
      {loading ? <Spinner /> : text}
    </button>
  );
};

export default PrimaryButtonFull;
