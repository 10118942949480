import React from 'react';
import { useTranslation } from 'react-i18next';
import AppHead from '../../components/Headers/AppHead';
import HeaderPage from '../../components/Navigation/HederPage';
import Sidebar from '../../components/Navigation/Sidebar';
import ContractBox from '../../components/VariusElements/ContractBox';
import InfoBox from '../../components/VariusElements/InfoBox';
import InvestmentsBoxDashboard from '../../components/VariusElements/InvestmentsBoxDashboard';
import TransactionsBoxDashboard from '../../components/VariusElements/TransactionsBoxDashboard';
import { ContractStatus } from '../../lib/constants/contracts';
import { IContract, IPayment } from '../../lib/interfaces';
import { UserStatistics } from '../../lib/interfaces/IStatistics';
import ContractStatusFiltersTab from '../../components/VariusElements/ContractStatusFiltersTab';

const DashboardPresentational: React.FC<{
  payments: IPayment[];
  userStatistics?: UserStatistics;
  isLoading: boolean;
  contracts: IContract[];
  onChangePaymentsDateFilter: (paymentsDateFilter?: string) => void;
  onChangeContractStatusFilter: (status?: ContractStatus) => void;
  value?: string;
  paymentsDateFilter: string | undefined;
}> = (props) => {
  const {
    payments,
    contracts,
    onChangePaymentsDateFilter = () => {},
    onChangeContractStatusFilter,
    paymentsDateFilter,
    value,
    userStatistics,
    isLoading,
  } = props;
  const { t } = useTranslation('DASHBOARD');

  return (
    <>
      <AppHead name="Dashboard" />
      <div className="flex h-full min-h-screen font-sans">
        <Sidebar />
        <div className="flex-1 lg:pl-52">
          <div className="py-10 px-6">
            <HeaderPage title={t('TITLE')} />

            <h4 className="block font-sans text-lg font-normal text-purple">
              {t('MY_INVESTMENTS')}
            </h4>
            <div className="mt-6 w-full lg:grid lg:grid-cols-12 lg:gap-4">
              <main className="col-span-12 mb-6 flex flex-col gap-4 md:flex-row lg:col-span-3 lg:flex-col">
                <InvestmentsBoxDashboard
                  isLoading={isLoading}
                  userStatistics={userStatistics}
                />
                <TransactionsBoxDashboard
                  payments={payments}
                  onChangePaymentsDateFilter={onChangePaymentsDateFilter}
                  paymentsDateFilter={paymentsDateFilter}
                />
              </main>

              <aside className="col-span-12 lg:col-span-9 xl:block">
                <div className="w-full rounded-md border border-gray-200 px-4 py-6">
                  <div className="flex flex-row justify-between">
                    <h3 className="mb-1 text-xl font-medium text-gray-800 md:text-3xl">
                      {t('MY_CONTRACTS:TITLE')}
                    </h3>
                    <ContractStatusFiltersTab
                      onChangeContractStatusFilter={
                        onChangeContractStatusFilter
                      }
                    />
                  </div>
                  {!contracts ||
                    (!contracts.length && value === 'ACTIVE' && (
                      <InfoBox
                        type="info"
                        description={t('MY_CONTRACTS:NO_CONTRACTS')}
                      />
                    ))}
                  {!contracts ||
                    (!contracts.length && value === 'DRAFT' && (
                      <InfoBox
                        type="info"
                        description={t('MY_CONTRACTS:NO_DRAFT_CONTRACTS')}
                      />
                    ))}
                  {!contracts ||
                    (!contracts.length && value === 'CLOSED' && (
                      <InfoBox
                        type="info"
                        description={t('MY_CONTRACTS:NO_CLOSED_CONTRACTS')}
                      />
                    ))}
                  {!contracts ||
                    (!contracts.length && value === 'SUSPENDED' && (
                      <InfoBox
                        type="info"
                        description={t('MY_CONTRACTS:NO_SUSPENDED_CONTRACTS')}
                      />
                    ))}
                  <div className="flex flex-row flex-wrap">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                      {contracts?.map((contract, idx) => (
                        <div key={`mycontract-${idx}`} className="flex w-full">
                          <ContractBox contract={contract} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPresentational;
