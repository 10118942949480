import {
  BellIcon,
  CogIcon,
  InformationCircleIcon,
  LockClosedIcon,
  TemplateIcon,
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import classNames from '../../lib/utils/classNames';

export default function VerticalNavigation() {
  const { t } = useTranslation('COMMON');
  const { pathname } = useLocation();

  const navigation = [
    {
      name: 'Dashboard',
      icon: TemplateIcon,
      href: '/#',
      current: pathname === '/',
    },
    // {
    //   name: 'I miei contratti',
    //   icon: LockClosedIcon,
    //   href: '/#/contracts',
    //   current: pathname.includes('contracts'),
    // },
    {
      name: 'Prodotti',
      icon: InformationCircleIcon,
      href: '/#/products',
      current: pathname.includes('products'),
    },
    {
      name: 'Novità',
      icon: BellIcon,
      href: '/#/news',
      current: pathname.includes('news'),
    },
    // {
    //   name: 'Sottoscrizioni in sospeso',
    //   icon: DotsCircleHorizontalIcon,
    //   href: '#',
    //   current: false
    // },
    // {
    //   name: 'Nuova sottoscrizione',
    //   icon: CurrencyEuroIcon,
    //   href: '#',
    //   current: false
    // }
  ];
  return (
    <>
      <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
        <div className="mx-auto">
          <img
            className="h-12 w-auto"
            src="../images/icon-rigsave-white.svg"
            alt="Workflow"
          />
        </div>
        <nav className="mt-10 flex-1 space-y-1" aria-label="Sidebar">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={classNames(
                item.current
                  ? 'bg-white text-purple'
                  : 'text-gray-200 hover:bg-violet',
                'group flex items-center px-4 py-3 font-sans text-sm font-normal'
              )}
            >
              <item.icon className="mr-3 h-5 w-5" aria-hidden="true" />
              <span className="flex-1">{item.name}</span>
            </a>
          ))}
        </nav>
      </div>
      <div
        className={classNames(
          pathname.includes('settings')
            ? 'bg-white text-purple'
            : 'text-gray-200 hover:bg-violet',
          'flex flex-shrink-0 border-t border-violet p-4'
        )}
      >
        <Link
          to="/settings?tab=general"
          className="group block w-full flex-shrink-0"
        >
          <div className="flex items-center">
            <CogIcon className="h-5 w-5" />
            <div className="ml-3">
              <p className="text-sm font-normal">{t('SETTINGS:TITLE')}</p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
