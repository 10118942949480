import { useEffect, useState } from 'react';
import { listPayments } from '../../lib/fetch/payments';
import { IContract, IPayment } from '../../lib/interfaces';
import { useAuth } from '../../providers/AuthProvider';
import DashboardPresentational from './DashboardPresentational';
import listContracts from '../../lib/fetch/contracts/list';
import { useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { useQuery } from '@tanstack/react-query';
import getProductStats from '../../lib/fetch/statistics/userStats';

export type ChartFilters = { from: Date | undefined; to: Date | undefined };

const Dashboard: React.FC<{}> = () => {
  const [{ user, token }] = useAuth();
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [paymentsDateFilter, setPaymentsDateFilter] = useState<
    string | undefined
  >();
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation();
  const [status, setStatus] = useQueryParam('status', StringParam);

  const fetchListPayments = async () => {
    const { error, data } = await listPayments({
      token: token as string,
      userId: user?.id as number,
      page: 1,
      limit: 2,
      createdAt: paymentsDateFilter,
    });
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    if (data) {
      setPayments(data.data || []);
    }
  };

  const { data, isFetching: isGettingUserStats } = useQuery(
    ['getUserStatistics'],
    () => getProductStats({ token, userId: user?.id + '' }),
    { refetchOnWindowFocus: false }
  );

  const fetchContracts = async () => {
    const { error, data } = await listContracts({
      token: token as string,
      userId: user?.id as number,
      status: status || undefined,
    });
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    if (data) {
      setContracts(data.data || []);
    }
  };

  useEffect(() => {
    // if (!status) {
    //   return navigate('/?status=ACTIVE', { replace: true });
    // }

    fetchContracts();
  }, [status]);

  useEffect(() => {
    fetchListPayments();
  }, [paymentsDateFilter]);

  return (
    <DashboardPresentational
      userStatistics={data?.data}
      payments={payments}
      contracts={contracts}
      onChangePaymentsDateFilter={setPaymentsDateFilter}
      paymentsDateFilter={paymentsDateFilter}
      onChangeContractStatusFilter={(_status) => setStatus(_status)}
      value={status || ''}
      isLoading={isGettingUserStats}
    />
  );
};

export default Dashboard;
