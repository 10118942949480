import { ExclamationCircleIcon } from '@heroicons/react/solid';

interface Props {
  text: string;
  onClick: () => any;
  icon?: boolean;
  className?: string;
}

const WarningOutlineButton: React.FC<Props> = ({
  text,
  onClick,
  icon,
  className = '',
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`flex w-full justify-center rounded-md border border-yellow-500 bg-white py-2 px-4 text-sm font-bold text-yellow-500 shadow-sm hover:text-yellow-700 focus:outline-none ${className}`}
    >
      {text}
      {icon && (
        <ExclamationCircleIcon
          className="fixed right-6 m-2 my-auto h-5  w-5 text-yellow-500"
          aria-hidden="true"
        />
      )}
    </button>
  );
};

export default WarningOutlineButton;
