import moment from 'moment';
import { Dispatch, SetStateAction, useMemo } from 'react';
import classNames from '../../lib/utils/classNames';
import { ChartFilters } from '../../pages/Dashboard';

type Props = {
  chartFilters: ChartFilters;
  onChangeChartFilters: Dispatch<SetStateAction<ChartFilters>>;
};

const TabsChartFilter = (props: Props) => {
  const { chartFilters, onChangeChartFilters } = props;

  const today = moment.utc().startOf('day');

  const tabs = useMemo(
    () => [
      {
        name: '1m',
        value: {
          from: today.clone().subtract(1, 'month').toDate(),
          to: today.toDate(),
        },
        current:
          moment(chartFilters.from).format('L') ===
            today.clone().subtract(1, 'month').format('L') &&
          moment(chartFilters.to).format('L') === today.format('L'),
      },
      {
        name: '3m',
        value: {
          from: today.clone().subtract(3, 'month').toDate(),
          to: today.toDate(),
        },
        current:
          moment(chartFilters.from).format('L') ===
            today.clone().subtract(3, 'month').format('L') &&
          moment(chartFilters.to).format('L') === today.format('L'),
      },
      {
        name: '6m',
        value: {
          from: today.clone().subtract(6, 'month').toDate(),
          to: today.toDate(),
        },
        current:
          moment(chartFilters.from).format('L') ===
            today.clone().subtract(6, 'month').format('L') &&
          moment(chartFilters.to).format('L') === today.format('L'),
      },
      {
        name: '1y',
        value: {
          from: today.clone().subtract(12, 'month').toDate(),
          to: today.toDate(),
        },
        current:
          moment(chartFilters.from).format('L') ===
            today.clone().subtract(12, 'month').format('L') &&
          moment(chartFilters.to).format('L') === today.format('L'),
      },
      {
        name: 'All',
        value: {
          from: undefined,
          to: undefined,
        },
        current: !chartFilters.from && !chartFilters.to,
      },
    ],
    [chartFilters]
  );

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 text-xs focus:border-violet focus:ring-0"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-2" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={classNames(
                tab.current
                  ? 'bg-gray-100 text-gray-800'
                  : 'text-gray-500 hover:text-gray-800',
                'rounded-md p-2 text-xs font-medium'
              )}
              aria-current={tab.current ? 'page' : undefined}
              onClick={() => onChangeChartFilters(tab.value)}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};
export default TabsChartFilter;
