import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import StaticInput from '../../../components/Form/StaticInput';
import { useAuth } from '../../../providers/AuthProvider';
import axios from 'axios';

const Registry: React.FC<{}> = () => {
  const { t } = useTranslation('SURVEY');
  const [{ user }] = useAuth();
  const [nations, setNations] = useState<{ name: string; value: string }[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get<{ name: string; code: string }[]>(
        'nations.json'
      );
      setNations(
        data.map((nation) => ({ name: nation.name, value: nation.code }))
      );
    })();
  }, []);

  const getNationNameByValue = (value?: string) =>
    nations.find((nation) => nation.value === value)?.name || '';

  return (
    <div className="mb-6 rounded-md border border-gray-200 px-4 py-6">
      <h4 className="font-regular mb-4 text-xl text-violet">{t('REGISTRY')}</h4>
      <div className="grid grid-cols-2 gap-6 lg:grid-cols-4">
        <StaticInput title={t('NAME')} value={`${user?.firstName || '-'}`} />
        <StaticInput title={t('SURNAME')} value={`${user?.lastName || '-'}`} />
        <StaticInput
          title={t('GENRE')}
          value={user?.gender ? t(`GENDER_${user.gender}`) : '-'}
        />
        <StaticInput
          title={t('CITIZENSHIP')}
          value={getNationNameByValue(user?.citizenship) || '-'}
        />
        <StaticInput
          title={t('FISCAL_CODE')}
          value={`${user?.fiscalCode || '-'}`}
        />
        <StaticInput
          title={t('COMMON:EMAIL')}
          value={`${user?.email || '-'}`}
        />
        <StaticInput title={t('CELL_PHONE')} value={`${user?.phone || '-'}`} />
        <StaticInput
          title={t('OTHER_PHONE')}
          value={`${user?.phone2 || '-'}`}
        />
        <StaticInput
          title={t('BIRTH_DATE')}
          value={`${
            (user?.birthDate && moment(user?.birthDate).format('L')) || '-'
          }`}
        />
        <StaticInput
          title={t('BIRTH_STATE')}
          value={getNationNameByValue(user?.birthCountry) || '-'}
        />
        <StaticInput
          title={t('BIRTH_PROVINCE')}
          value={`${user?.birthDistrict || '-'}`}
        />
        <StaticInput
          title={t('BIRTH_PLACE')}
          value={`${user?.birthCity || '-'}`}
        />
        <StaticInput
          title={t('RESIDENTIAL_ADDRESS')}
          value={`${user?.address || '-'}`}
        />
        <StaticInput
          title={t('STREET_NUMBER')}
          value={`${user?.houseNumber || '-'}`}
        />
        <StaticInput title={t('CAP')} value={`${user?.postalCode || '-'}`} />
        <StaticInput
          title={t('STATE')}
          value={getNationNameByValue(user?.country) || '-'}
        />
        <StaticInput title={t('PROVINCE')} value={`${user?.district || '-'}`} />
        <StaticInput title={t('CITY')} value={`${user?.city || '-'}`} />
        <StaticInput
          title={t('CONVENTION_CODE')}
          value={`${user?.conventionCode || '-'}`}
        />
      </div>
    </div>
  );
};

export default Registry;
