import WarningOutlineButton from '../Buttons/WarningOutlineButton';
import InfoBox from './InfoBox';

type Props = {
  condition: boolean;
  message: string;
  actionText: string;
  action: () => void;
};

const ActionInfoBox = (props: Props) => {
  const { condition, message, actionText, action } = props;
  if (!condition) return null;
  return (
    <div className="my-2 flex">
      <InfoBox
        description={message}
        className="flex justify-between"
        type={'warning'}
        children={
          <div className="ml-3">
            <WarningOutlineButton
              className="px-6"
              text={actionText}
              onClick={action}
            />
          </div>
        }
      />
    </div>
  );
};

export default ActionInfoBox;
