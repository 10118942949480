import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { Link } from 'react-router-dom';
import { PrimaryButtonFull } from '../../components/Buttons';
import AppHead from '../../components/Headers/AppHead';
import errorLottie from '../../lotties/error-occurred.json';

type Props = {};

const PaymentFailedPresentational = (props: Props) => {
  const { t } = useTranslation('PAYMENT_FAILED');
  return (
    <>
      <AppHead name={t('HEAD_NAME')} />
      <div className="flex min-h-screen bg-gray-50 font-sans">
        <div className="flex w-full flex-col items-center justify-center px-12 pb-12">
          <div className="mx-auto max-w-3xl">
            <Lottie
              loop
              animationData={errorLottie}
              play
              style={{ width: 150, height: 150 }}
              className="mx-auto mb-2"
            />

            <h1
              className="mb-8 text-center text-4xl font-bold text-violet"
              dangerouslySetInnerHTML={{
                __html: t('TITLE'),
              }}
            />

            <Link to="/">
              <PrimaryButtonFull text={t('BACK_DASHBOARD_BTN')} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFailedPresentational;
