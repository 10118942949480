import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButtonFull } from '../../../components/Buttons';
import InputPresentational from '../../../components/Form/InputPresentational';
import { ContractSignatureType } from '../../../lib/interfaces';
import { IContract } from '../../../lib/interfaces/IContract';

interface IStep5 {
  contract?: IContract;
  onGenerateSignatureCode: (
    signatureName: ContractSignatureType
  ) => Promise<void>;
  onSignContract: (
    signatureName: ContractSignatureType,
    code: string
  ) => Promise<void>;
  sectionData: 'updatingData' | 'contractData';
  isLoading: boolean;
}

const Step5: React.FC<IStep5> = ({
  contract,
  onGenerateSignatureCode,
  onSignContract,
  sectionData,
  isLoading,
}) => {
  const { t } = useTranslation('SURVEY');
  const [state, setState] = useState({
    otp1: '',
    otp2: '',
  });

  const code1Sent =
    !!contract?.attributes[sectionData]?.contractSignature
      ?.OTPGenerationTimestamp;
  const code1Verified =
    !!contract?.attributes[sectionData]?.contractSignature?.signed;
  const code2Sent =
    !!contract?.attributes[sectionData]?.privacySignature
      ?.OTPGenerationTimestamp;
  const code2Verified =
    !!contract?.attributes[sectionData]?.privacySignature?.signed;

  return (
    <div className="pt-6 pb-12 md:px-10">
      <h2 className="mb-6 text-3xl font-bold text-purple">
        {t('CONTRACT.STEP5_TITLE')}
      </h2>
      <div className="">
        <h3 className="mb-6 text-xl text-purple">Contratto</h3>
        {code1Verified ? (
          <h3 className="mb-6 text-lg font-bold text-green-600">Firmato</h3>
        ) : !code1Sent ? (
          <PrimaryButtonFull
            text="Inviami il codice"
            onClick={() =>
              onGenerateSignatureCode(ContractSignatureType.CONTRACT_SIGNATURE)
            }
            loading={isLoading}
          />
        ) : (
          <>
            <InputPresentational
              label={'Codice OTP'}
              type="text"
              id="otp1"
              name="otp1"
              onChange={(e) => setState({ ...state, otp1: e.target.value })}
            />
            <div className="mt-3">
              <PrimaryButtonFull
                text="Firma"
                onClick={() =>
                  onSignContract(
                    ContractSignatureType.CONTRACT_SIGNATURE,
                    state.otp1
                  )
                }
                loading={isLoading}
              />
            </div>
          </>
        )}
      </div>
      <div className="mt-10">
        <h3 className="mb-6 text-xl text-purple">Consenso Privacy</h3>
        {code2Verified ? (
          <h3 className="mb-6 text-lg font-bold text-green-600">Firmato</h3>
        ) : !code2Sent ? (
          <PrimaryButtonFull
            text="Inviami il codice"
            onClick={() =>
              onGenerateSignatureCode(ContractSignatureType.PRIVACY_SIGNATURE)
            }
            loading={isLoading}
          />
        ) : (
          <>
            <InputPresentational
              label={'Codice OTP'}
              type="text"
              id="otp2"
              name="otp2"
              onChange={(e) => setState({ ...state, otp2: e.target.value })}
            />
            <div className="mt-3">
              <PrimaryButtonFull
                text="Firma"
                onClick={() =>
                  onSignContract(
                    ContractSignatureType.PRIVACY_SIGNATURE,
                    state.otp2
                  )
                }
                loading={isLoading}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Step5;
