import axios, { AxiosResponse } from 'axios';
import { IContract } from '../../interfaces';

const { REACT_APP_CONTRACTS_URL } = process.env;

interface Props {
  id?: number;
  contractTitle: string;
}

const updateContractTitle = async (token: string, data: Props) => {
  const response = await axios.put<{ data: IContract }>(
    `${REACT_APP_CONTRACTS_URL!}/${data.id}`,
    { data: { contractTitle: data.contractTitle } },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default updateContractTitle;
