import axios, { AxiosResponse } from 'axios';
import QueryString from 'qs';
import { IContract } from '../../interfaces';

const { REACT_APP_CONTRACTS_URL } = process.env;

const get = async (token: string, id: number) => {
  const query = {
    populate: {
      compiledContract: {
        populate: true,
      },
      couponBonds: {
        populate: true,
      },
      contractData: {
        populate: {
          customerData: '*',
          bankData: {
            populate: {
              transferFiles: '*',
            },
          },
          contractSignature: '*',
          privacySignature: '*',
          customerDocument: {
            populate: {
              documentFiles: '*',
            },
          },
          subscriptionData: {
            updatePACFrequencyModule: '*',
          },
        },
      },
      updatingData: {
        populate: {
          customerData: '*',
          bankData: '*',
          contractSignature: '*',
          privacySignature: '*',
          customerDocument: {
            populate: {
              documentFiles: '*',
            },
          },
          subscriptionData: {
            populate: { updatePACFrequencyModule: '*' },
          },
        },
      },
      subscription: '*',
      product: {
        populate: {
          marketValues: '*',
          couponBond: '*',
        },
      },
      history: {
        populate: {
          customerData: '*',
          bankData: '*',
          contractSignature: '*',
          privacySignature: '*',
          customerDocument: {
            populate: {
              documentFiles: '*',
            },
          },
          subscriptionData: {
            populate: { updatePACFrequencyModule: '*' },
          },
        },
      },
    },
  };
  const response = await axios.get<{ data: IContract }>(
    `${REACT_APP_CONTRACTS_URL}/${id}?${QueryString.stringify(query)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default get;
