const EMPTY_OPTION = Object.freeze({ name: '', value: '' });

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export const genderOptions = Object.freeze(
  Object.keys(Gender).map((k) => ({
    name: `SURVEY:GENDER_${k}`,
    value: k,
  }))
);

export enum DocumentType {
  PASSPORT = 'PASSPORT',
  IDENTITY_CARD = 'IDENTITY_CARD',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
}

export const documentTypeOptions = Object.freeze([
  EMPTY_OPTION,
  ...Object.keys(DocumentType).map((k) => ({
    name: `SURVEY:DOCUMENT_TYPE_${k}`,
    value: k,
  })),
]);

export enum MaritalStatus {
  MARRIED = 'MARRIED',
  WIDOWED = 'WIDOWED',
  SEPARATED = 'SEPARATED',
  DIVORCED = 'DIVORCED',
  SINGLE = 'SINGLE',
}

export const maritalStatusOptions = Object.freeze([
  EMPTY_OPTION,
  ...Object.keys(MaritalStatus).map((k) => ({
    name: `SURVEY:MARITAL_STATUS_${k}`,
    value: k,
  })),
]);

export enum StudyDegree {
  PRIMARY_SCHOOL = 'PRIMARY_SCHOOL',
  FIRST_DEGREE_SECONDARY_SCHOOL = 'FIRST_DEGREE_SECONDARY_SCHOOL',
  SECOND_DEGREE_SECONDARY_SCHOOL = 'SECOND_DEGREE_SECONDARY_SCHOOL',
  LYCEUM = 'LYCEUM',
  TECHNICAL_INSTITUTE = 'TECHNICAL_INSTITUTE',
  PROFESSIONAL_INSTITUTE = 'PROFESSIONAL_INSTITUTE',
  UNIVERSITY = 'UNIVERSITY',
}

export const studyDegreeOptions = Object.freeze([
  EMPTY_OPTION,
  ...Object.keys(StudyDegree).map((k) => ({
    name: `SURVEY:STUDY_DEGREE_${k}`,
    value: k,
  })),
]);

export enum FundsSource {
  OWN_SAVING = 'OWN_SAVING',
  INHERITANCE = 'INHERITANCE',
  SALES_INCOMES = 'SALES_INCOMES',
  OTHER = 'OTHER',
}

export const fundsSourceOptions = Object.freeze([
  EMPTY_OPTION,
  ...Object.keys(FundsSource).map((k) => ({
    name: `SURVEY:FUNDS_SOURCE_${k}`,
    value: k,
  })),
]);

export enum GoodsAmount {
  UP_TO_75K = 'UP_TO_75K',
  UP_TO_150K = 'UP_TO_150K',
  UP_TO_300K = 'UP_TO_300K',
  MORE_THAN_300K = 'MORE_THAN_300K',
}

export const goodsAmountOptions = Object.freeze(
  Object.keys(GoodsAmount).map((k) => ({
    name: `SURVEY:GOODS_AMOUNT_${k}`,
    value: k,
  }))
);

export enum FinancialKnowledge {
  SCHOOL_EDUCATION = 'SCHOOL_EDUCATION',
  PROFESSION = 'PROFESSION',
  INVESTMENT_EXPERIENCE = 'INVESTMENT_EXPERIENCE',
  OTHER = 'OTHER',
}

export const financialKnowledgeOptions = Object.freeze(
  Object.keys(FinancialKnowledge).map((k) => ({
    name: `SURVEY:FINANCIAL_KNOWLEDGE_${k}`,
    value: k,
  }))
);

export enum RiskAppetite {
  VERY_LOW_RISK = 'VERY_LOW_RISK',
  LOW_RISK = 'LOW_RISK',
  MEDIUM_RISK = 'MEDIUM_RISK',
  HIGH_RISK = 'HIGH_RISK',
  VERY_HIGH_RISK = 'VERY_HIGH_RISK',
}

export const riskAppetiteOptions = Object.freeze(
  Object.keys(RiskAppetite).map((k) => ({
    name: `SURVEY:RISK_APPETITE_${k}`,
    value: k,
  }))
);

export enum InvestmentIntention {
  PENSIONS = 'PENSIONS',
  ACCUMULATE_ASSETS = 'ACCUMULATE_ASSETS',
  FAMILY_FINANCIAL_SAFETY = 'FAMILY_FINANCIAL_SAFETY',
  SAVING_SHOPPING = 'SAVING_SHOPPING',
}

export const investmentIntentionOptions = Object.freeze(
  Object.keys(InvestmentIntention).map((k) => ({
    name: `SURVEY:INVESTMENT_INTENTION_${k}`,
    value: k,
  }))
);

export enum InvestmentGoal {
  REGULAR_INCOME = 'REGULAR_INCOME',
  INCREASE_VALUE = 'INCREASE_VALUE',
  PROFESSIONAL_PURPOSES = 'PROFESSIONAL_PURPOSES',
}

export const investmentGoalOptions = Object.freeze(
  Object.keys(InvestmentGoal).map((k) => ({
    name: `SURVEY:INVESTMENT_GOAL_${k}`,
    value: k,
  }))
);
