import { useEffect, useState } from 'react';
import { IProduct } from '../../lib/interfaces';
import ProductsListPresentational from './ProductsListPresentational';
import listProducts from '../../lib/fetch/products/list';
import { useAuth } from '../../providers/AuthProvider';
import { useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';

const ListProducts = () => {
  const [{ token }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation();
  const [products, setProducts] = useState<IProduct[]>([]);

  const fetchProducts = async () => {
    const { error, data } = await listProducts(token as string);
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    if (data) {
      setProducts(data.data || []);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return <ProductsListPresentational products={products} />;
};

export default ListProducts;
