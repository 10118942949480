import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getChartData, getProduct } from '../../lib/fetch/products';
import { IProduct } from '../../lib/interfaces';
import { useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import { ChartFilters } from '../Dashboard';
import InfoProductPresentational from './InfoProductPresentational';
import { useQuery } from '@tanstack/react-query';

const InfoProduct: React.FC<{}> = () => {
  const [{ token }] = useAuth();
  const [product, setProduct] = useState<IProduct>();
  const [chartFilters, setChartFilters] = useState<ChartFilters>({
    from: undefined,
    to: undefined,
  });
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation();

  const fetchProduct = async (id?: number | null) => {
    if (id) {
      const { error, data } = await getProduct(token as string, id);
      if (error) {
        let { data: { error: { message = undefined } = {} } = {} } = error;
        return dispatchAlertChange({
          open: true,
          message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
        });
      }
      if (data) {
        setProduct(data.data);
      }
    }
  };

  // const fetchChartData = async (chartId: number) => {
  //   const { error, data } = await getChartData(token!, chartId);
  //   if (error) {
  //     let { data: { error: { message = undefined } = {} } = {} } = error;
  //     return dispatchAlertChange({
  //       open: true,
  //       message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
  //     });
  //   }
  //   setChartData(data || []);
  // };

  const { data, isLoading } = useQuery(
    ['getChartData', product?.id],
    () => {
      if (product?.id) return getChartData(token!, product?.id!);
      return null;
    },
    {
      onError: (error: any) => {
        let { data: { error: { message = undefined } = {} } = {} } = error;
        return dispatchAlertChange({
          open: true,
          message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
        });
      },
    }
  );

  const filteredChartData = useMemo(() => {
    if (!chartFilters.from && !chartFilters.to) return data?.data || [];
    return data?.data.filter(
      (d) =>
        d.timestamp > (chartFilters.from?.valueOf() || 0) &&
        d.timestamp < (chartFilters.to?.valueOf() || Infinity)
    );
  }, [data?.data, chartFilters]);

  useEffect(() => {
    if (window) fetchProduct(Number(window.location.href.split('/').pop()));
  }, [window]);

  return (
    <InfoProductPresentational
      product={product}
      chartData={filteredChartData || []}
      chartFilters={chartFilters}
      onChangeChartFilters={setChartFilters}
      isLoading={isLoading}
    />
  );
};

export default InfoProduct;
