import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputPresentational from '../../../components/Form/InputPresentational';
import RadioGroupPresentational from '../../../components/Form/RadioGroupPresentational';
import SelectPresentational from '../../../components/Form/SelectPresentational';
import {
  financialKnowledgeOptions,
  fundsSourceOptions,
  goodsAmountOptions,
  investmentGoalOptions,
  investmentIntentionOptions,
  maritalStatusOptions,
  riskAppetiteOptions,
  studyDegreeOptions,
} from '../../../lib/constants/survey';

const Step4: React.FC<{}> = () => {
  const { t } = useTranslation('SURVEY');
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="pt-6 pb-12 md:px-10">
      <h2 className="mb-6 text-3xl font-bold text-purple">{t('TITLE')}</h2>

      <div className="mb-8 border-b pb-8">
        <h3 className="mb-4 text-lg font-medium leading-6 text-violet">
          {t('SURVEY1_TITLE')}
        </h3>
        <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
          <SelectPresentational
            label={t('MARITAL_STATUS')}
            id="maritalStatus"
            {...register('maritalStatus')}
            options={maritalStatusOptions}
            hasError={!!errors.maritalStatus}
            helperText={errors.maritalStatus?.message}
          />
          <SelectPresentational
            label={t('EDUCATION_QUALIFICATION')}
            id="qualification"
            {...register('studyDegree')}
            options={studyDegreeOptions}
            hasError={!!errors.studyDegree}
            helperText={errors.studyDegree?.message}
          />
          <InputPresentational
            label={t('INCOME_AMOUNT')}
            type="number"
            {...register('monthlyIncome', {
              min: {
                value: 0,
                message: t('MIN_VALUE_ERROR', { minValue: 0 }),
              },
            })}
            hasError={!!errors.monthlyIncome}
            helperText={errors.monthlyIncome?.message}
          />
          <SelectPresentational
            label={t('FUNDS_INVESTED')}
            id="fundsSource"
            {...register('fundsSource')}
            options={fundsSourceOptions}
            hasError={!!errors.fundsSource}
            helperText={errors.fundsSource?.message}
          />
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <RadioGroupPresentational
            id="goodsAmount"
            label={t('MY_VALUE')}
            {...register('goodsAmount')}
            options={goodsAmountOptions}
            hasError={!!errors.goodsAmount}
            helperText={errors.goodsAmount?.message}
          />
          <RadioGroupPresentational
            id="politicalExposure"
            label={`${t('POLITICAL_EXPOSURE_1')} ${t('POLITICAL_EXPOSURE_2')}`}
            {...register('politicalExposure')}
            options={[
              { name: t('COMMON:YES'), value: 'YES' },
              { name: t('COMMON:NO'), value: 'NO' },
            ]}
            hasError={!!errors.politicalExposure}
            helperText={errors.politicalExposure?.message}
          />
        </div>
      </div>
      <div className="mb-8 border-b pb-8">
        <h3 className="mb-4 text-lg font-medium leading-6 text-violet">
          {t('SURVEY2_TITLE')}
        </h3>
        <div className="grid grid-cols-1 gap-6">
          <RadioGroupPresentational
            id="financialKnowledge"
            label={t('EXPERIENCE')}
            {...register('financialKnowledge')}
            options={financialKnowledgeOptions}
            hasError={!!errors.financialKnowledge}
            helperText={errors.financialKnowledge?.message}
          />
          <RadioGroupPresentational
            id="riskAppetite"
            label={t('RISK_PROPENSION')}
            {...register('riskAppetite')}
            options={riskAppetiteOptions}
            hasError={!!errors.riskAppetite}
            helperText={errors.riskAppetite?.message}
          />
          <RadioGroupPresentational
            id="investmentIntention"
            label={t('INVESTMENT_INTENTION')}
            {...register('investmentIntention')}
            options={investmentIntentionOptions}
            hasError={!!errors.investmentIntention}
            helperText={errors.investmentIntention?.message}
          />
          <RadioGroupPresentational
            id="investmentGoal"
            label={t('INVESTMENT_OBJECTIVE')}
            {...register('investmentGoal')}
            options={investmentGoalOptions}
            hasError={!!errors.investmentGoal}
            helperText={errors.investmentGoal?.message}
          />
        </div>
      </div>

      <fieldset className="mt-8 space-y-5 md:w-2/4">
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="terms"
              aria-describedby="terms-description"
              {...register('surveyTermsConditions', {
                required: t('COMMON:REQUIRED') as string,
              })}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-purple focus:outline-none focus:ring-0"
            />
          </div>
          <div className="ml-3 text-xs">
            <label htmlFor="terms" className="font-normal text-gray-400">
              {t('SURVEY:PRIVACY_POLICY')}
            </label>
          </div>
        </div>
        {!!errors.surveyTermsConditions && (
          <>
            {/* TODO: NEED DESIGNER HELP FOR POSITIONING */}
            {/* <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="m-2 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div> */}
            <p
              className={`mt-2 text-sm text-${
                !!errors.surveyTermsConditions ? 'red-600' : 'gray-500'
              }`}
              id={`terms-${
                !!errors.surveyTermsConditions ? 'error' : 'helper'
              }`}
            >
              {errors.surveyTermsConditions.message}
            </p>
          </>
        )}
      </fieldset>
    </div>
  );
};

export default Step4;
