import axios, { AxiosResponse } from 'axios';
import { IWireTransferData } from './index';

const { REACT_APP_WIRE_TRANSFER_DATA_URL = '' } = process.env;

const list = async (token: string) => {
  const response = await axios.get<{ data: IWireTransferData }>(
    `${REACT_APP_WIRE_TRANSFER_DATA_URL}?populate=*`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default list;
