import React from 'react';
import { useTranslation } from 'react-i18next';
import FileReadPrev from '../../../components/Form/FileReadPrev ';
import { IStrapiFile } from '../../../lib/interfaces';

interface IIdentityDoc {
  files: IStrapiFile[];
  onClick?: (url: string, fileName: string) => void;
}

const IdentityDoc: React.FC<IIdentityDoc> = (props) => {
  const { files, onClick } = props;
  const { t } = useTranslation('SURVEY');
  return (
    <div className="mb-6 rounded-md border border-gray-200 px-4 py-6">
      <h4 className="font-regular mb-4 text-xl text-violet">
        {t('IDENTITY_DOCUMENTS')}
      </h4>
      <div className="flex flex-wrap items-center gap-4 rounded-md bg-gray-100 py-6 px-4">
        {!!files.length &&
          files.map((file, index) => (
            <FileReadPrev key={index} file={file} onClick={onClick} />
          ))}
        {!files.length && <span>Nessun documento caricato</span>}
      </div>
    </div>
  );
};

export default IdentityDoc;
