import Spinner from '../Spinner';

const OutlinePrimaryButtonFull = ({
  text,
  loading = false,
  onClick,
}: {
  text: string;
  loading?: boolean;
  onClick?: () => any;
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className="flex w-full justify-center rounded-md border border-violet bg-white py-2 px-4 text-base font-bold text-violet shadow-sm hover:text-purple focus:outline-none"
    >
      {loading ? <Spinner style={{ color: '#606285' }} /> : text}
    </button>
  );
};

export default OutlinePrimaryButtonFull;
