import moment from 'moment';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackButton, PrimaryButtonFull } from '../../components/Buttons';
import AppHead from '../../components/Headers/AppHead';
import HeaderPage from '../../components/Navigation/HederPage';
import Sidebar from '../../components/Navigation/Sidebar';
import InvestmentsBoxDetail from '../../components/VariusElements/InvestmentsBoxDetail';
import TransactionsBoxDashboard from '../../components/VariusElements/TransactionsBoxDashboard';
import { IContract, IPayment } from '../../lib/interfaces';
import { Link } from 'react-router-dom';
import { IProductChartRecord } from '../../lib/interfaces/IProduct';
import { ChartFilters } from '../Dashboard';
import { ProductStatistics } from '../../lib/interfaces/IStatistics';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import InfoBox from '../../components/VariusElements/InfoBox';
import {
  PaymentMethod,
  PaymentType,
  ProductType,
} from '../../lib/constants/payments';
import ContractStatusBadge from '../../components/Badges/ContractStatusBadge';
import WarningOutlineButton from '../../components/Buttons/WarningOutlineButton';
import { shouldUserUploadBankDocs } from '../../lib/utils/helpers';
import ContractChart from '../../components/VariusElements/ContractChart';
import { StrapiResponseList } from '../../lib/interfaces/Generics';
import { CouponBond } from '../../lib/interfaces/ICouponBonds';

interface Props {
  contract?: IContract;
  payments: IPayment[];
  onChangePaymentsDateFilter: (paymentsDateFilter?: string) => void;
  paymentsDateFilter: string | undefined;
  chartData: IProductChartRecord[];
  couponBondsData: StrapiResponseList<CouponBond> | undefined;
  chartFilters: ChartFilters;
  onChangeChartFilters: Dispatch<SetStateAction<ChartFilters>>;
  onUpdateTitle?: (contractTitle: string) => void;
  contractStatsQuery: Partial<
    UseQueryResult<
      {
        error: AxiosResponse<any, any> | undefined;
        data: ProductStatistics;
        status: number;
        statusText: string;
        headers: AxiosResponseHeaders;
      },
      unknown
    >
  >;
  contractChartQuery: UseQueryResult<
    {
      error: AxiosResponse<any, any> | undefined;
      data: IProductChartRecord[];
      status: number;
      statusText: string;
      headers: AxiosResponseHeaders;
      request?: any;
    } | null,
    any
  >;
}

const ContractDetailPresentational: React.FC<Props> = ({
  contract,
  payments,
  onChangePaymentsDateFilter = () => {},
  paymentsDateFilter,
  chartData,
  chartFilters,
  onChangeChartFilters,
  onUpdateTitle,
  contractStatsQuery,
  contractChartQuery,
  couponBondsData,
}) => {
  const { t } = useTranslation('DASHBOARD');
  const navigate = useNavigate();
  const [contractTitle, setContractTitle] = useState(
    contract?.attributes.contractTitle
  );
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const handleChange = (e: any) => {
    setContractTitle(e.target.value);
  };

  const handleClick = (contractTitle: string) => {
    if (contractTitle.length > 200) return;
    onUpdateTitle && onUpdateTitle(contractTitle);
    setIsEditingTitle(false);
  };

  const onChangeChartFilter = useCallback(
    (filter: 'from' | 'to') => (ev: React.ChangeEvent<HTMLInputElement>) => {
      onChangeChartFilters((chartFilters) => ({
        ...chartFilters,
        [filter]: moment.utc(ev.target.value).toDate(),
      }));
    },
    [onChangeChartFilters]
  );

  useEffect(() => {
    setContractTitle(contract?.attributes.contractTitle);
  }, [contract]);

  return (
    <>
      <AppHead name={t('HEAD_NAME_CONTRACT_DETAIL')} />
      <div className="flex h-full min-h-screen font-sans">
        <Sidebar />
        <div className="flex-1 lg:pl-52">
          <div className="py-10 px-6">
            <HeaderPage
              title={`${t('MY_CONTRACTS:DETAIL_CONTRACT')}${
                contract?.attributes.contractTitle
                  ? ` - "${contract?.attributes.contractTitle}"`
                  : ''
              }`}
            />

            <div className="flex max-w-min" onClick={() => navigate('/')}>
              <BackButton text={t('COMMON:BACK')} />
            </div>
            {/* <InfoBox
              description="Informativa dettaglio contratto, lorem ipsum dolor sit amet. Donec ullamcorper nulla non metus auctor fringilla"
              type={'info'}
            /> */}
            {contract && (
              <div className="">
                <label className="font-sans text-base font-normal text-purple">
                  Nome Contratto
                </label>

                <div className="relative">
                  <div className="">
                    {!contract?.attributes.contractTitle || isEditingTitle ? (
                      <div className="mt-1 flex w-1/2 flex-row">
                        <input
                          className="block h-full w-full rounded-md border border-gray-300  border-transparent py-2 px-3 text-sm text-gray-800 focus:border-purple focus:outline-none focus:ring-0"
                          type="text"
                          value={contractTitle}
                          onChange={handleChange}
                          defaultValue={contractTitle}
                        />
                        <div className="ml-5 w-1/3">
                          <PrimaryButtonFull
                            text="Salva"
                            onClick={() => handleClick(contractTitle || '')}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="mt-1 flex w-1/2 flex-row items-center">
                        <span className="text-xl text-gray-800">
                          {contract?.attributes.contractTitle}
                        </span>
                        <div className="ml-5">
                          <PrimaryButtonFull
                            text={t('EDIT_CONTRACT_NAME')}
                            onClick={() => setIsEditingTitle(true)}
                          />
                        </div>
                        {[PaymentType.PAC, PaymentType.ONE_TIME_PAC].includes(
                          contract.attributes.contractData?.subscriptionData
                            ?.subscriptionType
                        ) &&
                          contract?.attributes?.product?.data?.attributes
                            ?.type === ProductType.STANDARD && (
                            <div className="ml-5">
                              <Link
                                to={`/payment?paymentType=${PaymentType.PAC}&productId=${contract.attributes.product.data.id}&contractId=${contract.id}`}
                              >
                                <PrimaryButtonFull text={t('EDIT_PAC_DATA')} />
                              </Link>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                  <div className="absolute top-0 right-0 pt-3">
                    <ContractStatusBadge status={contract.attributes.status} />
                  </div>
                </div>
              </div>
            )}

            {shouldUserUploadBankDocs(contract) && (
              <div className="flex justify-between">
                <InfoBox
                  type="warning"
                  description={t(
                    'DASHBOARD:UPLOAD_TRANSFER_DOC_WRNING_MSG_DESC'
                  )}
                  title={t('DASHBOARD:UPLOAD_TRANSFER_DOC_WRNING_MSG')}
                  className="flex w-full flex-row justify-between py-2"
                  children={
                    <WarningOutlineButton
                      text={t('DASHBOARD:UPLOAD_BANK_DOC')}
                      onClick={() =>
                        navigate(
                          `/subscribe?contractId=${contract?.id}&contractNumber=${contract?.attributes.contractNumber}&paymentMethod=${PaymentMethod.WIRE_TRANSFER}`
                        )
                      }
                      className="mx-4 w-60"
                    />
                  }
                />
              </div>
            )}

            <div className="mt-6 w-full lg:grid lg:grid-cols-12 lg:gap-4">
              <main className="col-span-12 mb-6 flex flex-col gap-4 md:flex-row lg:col-span-4 lg:flex-col lg:justify-between">
                <InvestmentsBoxDetail
                  contract={contract}
                  contractStatsQuery={contractStatsQuery}
                />
                <TransactionsBoxDashboard
                  payments={payments}
                  contractId={contract?.id}
                  onChangePaymentsDateFilter={onChangePaymentsDateFilter}
                  paymentsDateFilter={paymentsDateFilter}
                />
              </main>

              <ContractChart
                chartFilters={chartFilters}
                chartData={chartData}
                contractChartQuery={contractChartQuery}
                contractStatsQuery={contractStatsQuery}
                onChangeChartFilter={onChangeChartFilter}
                onChangeChartFilters={onChangeChartFilters}
                contract={contract}
                couponBondsData={couponBondsData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractDetailPresentational;
