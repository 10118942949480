import { useEffect, useState } from 'react';
import ThankYouPresentational from './ThankYouPresentational';
import {
  IWireTransferDataAttributes,
  list as getWireTransferData,
} from '../../lib/fetch/wireTransfer';
import { useAuth } from '../../providers/AuthProvider';
import { useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import { createEnumParam, useQueryParam } from 'use-query-params';
import { PaymentMethod } from '../../lib/constants/payments';

const ThankYou = () => {
  const [{ token }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const [wireTransferData, setWireTransferData] = useState<
    IWireTransferDataAttributes | undefined
  >();
  const [paymentMethod] = useQueryParam(
    'paymentMethod',
    createEnumParam([PaymentMethod.STRIPE, PaymentMethod.WIRE_TRANSFER])
  );
  const { t } = useTranslation();

  useEffect(() => {
    fetchWireTransferData();
  }, []);

  const fetchWireTransferData = async () => {
    const { data, error } = await getWireTransferData(token!);
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    if (data && data.data.attributes) {
      setWireTransferData(data.data.attributes);
    }
  };

  return (
    <ThankYouPresentational
      paymentMethod={paymentMethod}
      wireTransferData={wireTransferData}
    />
  );
};

export default ThankYou;
