import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';
import { ContractStatus } from '../../lib/constants/contracts';
import { IContract } from '../../lib/interfaces';
import classNames from '../../lib/utils/classNames';
import SmallOutlinePrimaryButtonFull from '../Buttons/SmallOutlinePrimaryButtonFull';
import UserPaymentElegibilityModal from '../Modals/UserPaymentElegibilityModal';
import ContractStatusBadge from '../Badges/ContractStatusBadge';
import { shouldUserUploadBankDocs } from '../../lib/utils/helpers';
import { PaymentMethod } from '../../lib/constants/payments';

interface Props {
  contract: IContract;
}

const ContractBox: React.FC<Props> = ({ contract }) => {
  const { t } = useTranslation('DASHBOARD');

  // const canMakePayment =
  //   contract.attributes.status === ContractStatus.ACTIVE &&
  //   user?.kyc &&
  //   !moment(user.kyc.documentExpirationDate).isBefore(moment());

  return (
    <div className="my-2 mr-2 w-full rounded-md bg-purple px-4 py-6">
      <div className="mb-1 flex items-center justify-between">
        <p className="h-28 text-base font-light text-white md:h-16 lg:h-28 xl:h-20">
          <span className="font-bold">
            {t('MY_CONTRACTS:CONTRACT')}{' '}
            {contract.attributes?.contractNumber ||
              String(contract.id).padStart(6, '0')}
          </span>{' '}
          - {t('MY_CONTRACTS:PRODUCT')}:{' '}
          {contract.attributes.product.data?.attributes?.name}
        </p>

        {match(contract)
          .with({ attributes: { status: ContractStatus.DRAFT } }, () => (
            <ContractStatusBadge status={ContractStatus.DRAFT} />
          ))
          .with({ attributes: { status: ContractStatus.ACTIVE } }, () => (
            <ContractStatusBadge status={ContractStatus.ACTIVE} />
          ))
          .with({ attributes: { status: ContractStatus.CLOSED } }, () => (
            <ContractStatusBadge status={ContractStatus.CLOSED} />
          ))
          .with({ attributes: { status: ContractStatus.SUSPENDED } }, () => (
            <ContractStatusBadge status={ContractStatus.SUSPENDED} />
          ))
          .exhaustive()}
      </div>
      <p className="text-base font-light text-white">
        <span className="font-bold">{contract.attributes.contractTitle}</span>
      </p>
      <div className="mb-2">
        <p className="text-sm font-light text-white opacity-80">
          {t('TOTAL_PAID')}:
        </p>
      </div>

      {match(contract)
        .with({ attributes: { status: ContractStatus.DRAFT } }, () => (
          <div className="h-[52px]" />
        ))
        .with(
          { attributes: { status: ContractStatus.ACTIVE } },
          { attributes: { status: ContractStatus.CLOSED } },
          { attributes: { status: ContractStatus.SUSPENDED } },
          (contract) => (
            <div
              className={classNames(
                'flex items-start justify-end gap-4',
                !contract.attributes.contractTitle && 'mt-8'
              )}
            >
              <div className="text-right">
                <p className="mb-1 text-5xl font-bold text-white">
                  {contract.attributes.totalInvestment?.toLocaleString('it')}
                </p>
              </div>
              <span className="mr-4 font-bold uppercase text-white">EUR</span>
            </div>
          )
        )
        .exhaustive()}

      <div className="mb-auto flex flex-col justify-end gap-4">
        {/* {canMakePayment && (
          <UserPaymentElegibilityModal
            buttonText={t('POUR')}
            link={`/payment-type?productId=${contract.attributes.product.data.id}`}
          />
        )} */}
        {/* <Link to={`/contracts/${contract.id}`}>
          <SmallOutlinePrimaryButtonFull text={t('DETAIL')} />
        </Link> */}
        {contract.attributes.status === ContractStatus.DRAFT && (
          <UserPaymentElegibilityModal
            buttonText={t('MY_CONTRACTS:RESUME_SUBSCRIPTION')}
            link={`/subscribe?contractId=${contract.id}`}
          />
        )}
        {contract.attributes.status !== ContractStatus.DRAFT && (
          <Link to={`/contracts/${contract.id}`}>
            <SmallOutlinePrimaryButtonFull text={t('DETAIL')} />
          </Link>
        )}
        {shouldUserUploadBankDocs(contract) && (
          <UserPaymentElegibilityModal
            buttonText={t('UPLOAD_BANK_DOC')}
            link={`/subscribe?contractId=${contract.id}&contractNumber=${contract.attributes.contractNumber}&paymentMethod=${PaymentMethod.WIRE_TRANSFER}`}
          />
        )}
      </div>
    </div>
  );
};

export default ContractBox;
