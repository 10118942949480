import axios from 'axios';

const { REACT_APP_OTP_URL } = process.env;

const sendOTP = async (
  token: string,
  mode: 'phone' | 'email',
  lang: string
) => {
  const response = await axios.post(`${REACT_APP_OTP_URL!}/send`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      mode,
      locale: lang,
    },
  });

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default sendOTP;
