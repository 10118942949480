import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePickerPresentational from '../../../components/Form/DatePickerPresentational';
import InputPresentational from '../../../components/Form/InputPresentational';
import SelectPresentational from '../../../components/Form/SelectPresentational';
import { genderOptions } from '../../../lib/constants/survey';
import { IUser } from '../../../lib/interfaces';
import { useAuth } from '../../../providers/AuthProvider';

const fiscalCodeRegExp =
  /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/;

interface IStep1 {}

const Step1: React.FC<IStep1> = (props) => {
  const { t } = useTranslation('SURVEY');
  const [{ user }] = useAuth();
  const [nations, setNations] = useState<{ name: string; value: string }[]>([]);

  const {
    register,
    formState: { errors },
  } = useFormContext<IUser>();

  const disabled = user?.onboardingDone;

  useEffect(() => {
    (async () => {
      const { data } = await axios.get<{ name: string; code: string }[]>(
        'nations.json'
      );
      setNations(
        data.map((nation) => ({ name: nation.name, value: nation.code }))
      );
    })();
  }, []);

  return (
    <div className="pt-6 pb-12 md:px-10">
      <h2 className="mb-6 text-3xl font-bold text-purple">{t('REGISTRY')}</h2>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2  lg:grid-cols-4">
        <InputPresentational
          label={t('NAME')}
          type="text"
          id="firstName"
          autoComplete="given-name"
          {...register('firstName', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.firstName}
          helperText={errors.firstName?.message}
        />
        <InputPresentational
          label={t('SURNAME')}
          type="text"
          id="lastName"
          autoComplete="family-name"
          {...register('lastName', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.lastName}
          helperText={errors.lastName?.message}
        />
        <SelectPresentational
          label={t('GENRE')}
          id="genere"
          autoComplete="sex"
          {...register('gender', {
            required: t('COMMON:REQUIRED') as string,
          })}
          options={genderOptions}
          disabled={disabled}
          hasError={!!errors.gender}
          helperText={errors.gender?.message}
          hasEmptyOption
        />
        <SelectPresentational
          label={t('CITIZENSHIP')}
          id="citizenship"
          {...register('citizenship', {
            required: t('COMMON:REQUIRED') as string,
          })}
          options={nations}
          disabled={disabled}
          hasError={!!errors.citizenship}
          helperText={errors.citizenship?.message}
          hasEmptyOption
        />
        <InputPresentational
          label={t('FISCAL_CODE')}
          type="text"
          {...register('fiscalCode', {
            required: t('COMMON:REQUIRED') as string,
            pattern: {
              value: fiscalCodeRegExp,
              message: t('INVALID_FISCALCODE'),
            },
          })}
          disabled={disabled}
          hasError={!!errors.fiscalCode}
          helperText={errors.fiscalCode?.message}
        />
        <InputPresentational
          label={t('COMMON:EMAIL')}
          type="text"
          id="email"
          autoComplete="email"
          {...register('email')}
          disabled
        />
        <InputPresentational
          label={t('CELL_PHONE')}
          type="text"
          autoComplete="tel"
          {...register('phone')}
          disabled
        />
        <InputPresentational
          label={t('OTHER_PHONE')}
          type="text"
          autoComplete="tel"
          {...register('phone2')}
          hasError={!!errors.phone2}
          helperText={errors.phone2?.message}
        />
        <DatePickerPresentational
          id="birthDate"
          label={t('BIRTH_DATE')}
          autoComplete="bday"
          maxDate={moment().subtract(18, 'years').format('YYYY-MM-DD')}
          {...register('birthDate', {
            required: t('COMMON:REQUIRED') as string,
            validate: (date) =>
              new Date().getFullYear() - new Date(date!).getFullYear() >= 18 ||
              (t('DATE_UNDER_AGE') as string),
          })}
          disabled={disabled}
          hasError={!!errors.birthDate}
          helperText={errors.birthDate?.message}
        />
        <SelectPresentational
          label={t('BIRTH_STATE')}
          id="birthState"
          autoComplete="country-name"
          {...register('birthCountry', {
            required: t('COMMON:REQUIRED') as string,
          })}
          options={nations}
          disabled={disabled}
          hasError={!!errors.birthCountry}
          helperText={errors.birthCountry?.message}
          hasEmptyOption
        />
        {/* TODO: SELECT WITH OPTIONS */}
        <InputPresentational
          label={t('BIRTH_PROVINCE')}
          id="birthProvince"
          autoComplete="address-level2"
          {...register('birthDistrict', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.birthDistrict}
          helperText={errors.birthDistrict?.message}
        />
        {/* TODO: SELECT WITH OPTIONS */}
        <InputPresentational
          label={t('BIRTH_PLACE')}
          id="birthPlace"
          autoComplete="address-level3"
          {...register('birthCity', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.birthCity}
          helperText={errors.birthCity?.message}
        />
        <InputPresentational
          label={t('RESIDENTIAL_ADDRESS')}
          id="residentialAddress"
          autoComplete="address-line1"
          {...register('address', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.address}
          helperText={errors.address?.message}
        />
        <InputPresentational
          label={t('STREET_NUMBER')}
          type="text"
          autoComplete="address-line2"
          {...register('houseNumber', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.houseNumber}
          helperText={errors.houseNumber?.message}
        />
        <InputPresentational
          label={t('CAP')}
          type="text"
          autoComplete="postal-code"
          {...register('postalCode', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.postalCode}
          helperText={errors.postalCode?.message}
        />
        <SelectPresentational
          label={t('STATE')}
          id="state"
          autoComplete="country-name"
          {...register('country', {
            required: t('COMMON:REQUIRED') as string,
          })}
          options={nations}
          disabled={disabled}
          hasError={!!errors.country}
          helperText={errors.country?.message}
          hasEmptyOption
        />
        {/* TODO: SELECT WITH OPTIONS */}
        <InputPresentational
          label={t('PROVINCE')}
          type="text"
          autoComplete="address-level2"
          {...register('district', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.district}
          helperText={errors.district?.message}
        />
        {/* TODO: SELECT WITH OPTIONS */}
        <InputPresentational
          label={t('CITY')}
          type="text"
          autoComplete="address-level3"
          {...register('city', {
            required: t('COMMON:REQUIRED') as string,
          })}
          disabled={disabled}
          hasError={!!errors.city}
          helperText={errors.city?.message}
        />
        <InputPresentational
          label={t('CONVENTION_CODE')}
          type="text"
          {...register('conventionCode')}
          disabled={disabled}
          hasError={!!errors.conventionCode}
          helperText={errors.conventionCode?.message}
        />
        {/* <InputPresentational
          label={t('FISCAL_RESIDENCE')}
          name="fiscalResidence"
          type="text"
        /> */}
      </div>

      <fieldset className="mt-8 space-y-5 md:w-2/4">
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="terms"
              aria-describedby="terms-description"
              type="checkbox"
              {...register('termsConditions', {
                required: t('COMMON:REQUIRED') as string,
              })}
              className="h-4 w-4 rounded border-gray-300 text-purple focus:outline-none focus:ring-0"
            />
          </div>
          <div className="ml-3 text-xs">
            <label htmlFor="terms" className="font-normal text-gray-400">
              {t('SURVEY:PRIVACY_POLICY')}
              Ho preso visione dell'
              <a
                href={process.env.PUBLIC_URL + '/documents/TermsConditions.pdf'}
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer text-purple"
              >
                informativa sul trattamento dei dati personali
              </a>{' '}
              ai sensi degli artt. 13 e 14 del Regolamento (UE) 2016/679 (GDPR)
              *
            </label>
          </div>
        </div>

        {!!errors.termsConditions && (
          <>
            {/* TODO: NEED DESIGNER HELP FOR POSITIONING */}
            {/* <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="m-2 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div> */}
            <p
              className={`mt-2 text-sm text-${
                !!errors.termsConditions ? 'red-600' : 'gray-500'
              }`}
              id={`terms-${!!errors.termsConditions ? 'error' : 'helper'}`}
            >
              {errors.termsConditions.message}
            </p>
          </>
        )}
      </fieldset>
    </div>
  );
};

export default Step1;
