import moment from 'moment';
import { useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { IProductChartRecord } from '../../lib/interfaces/IProduct';

type Props = {
  data: IProductChartRecord[];
};

const LineChartDashboard = (props: Props) => {
  const { data = [] } = props;

  const chartDomain = useMemo(() => {
    const values = data.map((d) => d.price);
    const min = Math.floor(Math.min(...values) / 10) * 10;
    const max = Math.ceil(Math.max(...values) / 10) * 10;
    return [min, max];
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height={600} key={data.length}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="timestamp"
          fontSize={12}
          scale="time"
          type="number"
          domain={[data[0]?.timestamp, data[data.length - 1]?.timestamp]}
          tickFormatter={(date) => moment(date).format('DD/MM/YYYY')}
        />
        <YAxis style={{ fontSize: 12 }} domain={chartDomain} />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey="price"
          stroke="#2C2C5D"
          activeDot={{ r: 8 }}
          dot={false}
          strokeWidth={3}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartDashboard;

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  const getVariationColor = (variation: number) => {
    switch (true) {
      case variation > 0:
        return 'text-green-500';
      case variation < 0:
        return 'text-red-500';
      default:
        return '';
    }
  };

  const { timestamp, price = 0, variation = 0 } = payload?.[0]?.payload || {};

  if (active) {
    return (
      <div className="m-0 border border-solid border-gray-400 bg-white py-2 px-4">
        <p>{timestamp && moment(timestamp).format('DD/MM/YYYY')}</p>
        <p className=" text-purple">EUR: {Number(price).toFixed(2)}</p>
        <p className={getVariationColor(variation.toFixed(2))}>
          {Number(Number(variation).toFixed(2)) > 0 && '+'}
          {Number(Number(variation).toFixed(2)) || 0} %
        </p>
      </div>
    );
  }

  return null;
};
