import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButtonFull } from '../../components/Buttons';
import InputPresentational from '../../components/Form/InputPresentational';
import AppHead from '../../components/Headers/AppHead';
import BgLogin from '../../components/VariusElements/BgLogin';
import RespLogoLogin from '../../components/VariusElements/RespLogoLogin';

export interface IAdditionalDataForm {
  phone: string;
  phoneConfirm: string;
}

const AdditionalDataPresentational: React.FC<{
  onSubmit: (values: IAdditionalDataForm) => void;
}> = ({ onSubmit = () => {} }) => {
  const { t } = useTranslation('LOGIN');
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<IAdditionalDataForm>();

  return (
    <>
      <AppHead name={t('HEAD_NAME_ADDITIONAL_DATA')} />
      <div className="flex min-h-screen font-sans">
        <BgLogin />
        <div className="relative flex w-3/5 flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <RespLogoLogin />

          <div className="mx-auto w-full max-w-xl">
            <h2 className="text-3xl font-bold text-purple">
              {t('ADDITIONAL_DATA')}
            </h2>
            <p className="mt-2 text-base text-gray-800">
              {t('ADDITIONAL_DATA_DESCRIPTION')}
            </p>

            <div className="mt-8">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 gap-6">
                  <InputPresentational
                    label={t('COMMON:TELEPHONE_NUMBER')}
                    id="phone"
                    type="string"
                    autoComplete="tel"
                    {...register('phone', {
                      required: t('COMMON:REQUIRED') as string,
                    })}
                    hasError={!!errors.phone}
                    helperText={errors.phone?.message}
                  />
                  <InputPresentational
                    label={t('CONFIRM_TELEPHONE_NUMBER')}
                    id="phone"
                    type="string"
                    autoComplete="tel"
                    {...register('phoneConfirm', {
                      required: t('COMMON:REQUIRED') as string,
                      validate: (phoneConfirm: string) => {
                        return (
                          phoneConfirm === getValues('phone') ||
                          (t('COMMON:FIELDS_NOT_MATCH') as string)
                        );
                      },
                    })}
                    hasError={!!errors.phoneConfirm}
                    helperText={errors.phoneConfirm?.message}
                  />
                </div>

                <div className="mx-auto mt-12 w-3/5 lg:mr-auto lg:ml-0">
                  <PrimaryButtonFull text={t('COMMON:CONFIRM')} type="submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalDataPresentational;
