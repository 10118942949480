import { useEffect } from 'react';
import { me } from '../../lib/fetch/auth';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import SurveyCompletedPresentational from './SurveyCompletedPresentational';

const SurveyCompleted = () => {
  const [{ token }, dispatchAuthChange] = useAuth();

  const refetchProfile = async () => {
    const { data: updatedUser } = await me(token!);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
  };

  useEffect(() => {
    refetchProfile();
  }, []);

  return <SurveyCompletedPresentational />;
};

export default SurveyCompleted;
