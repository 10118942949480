interface Props {
  label?: string;
  from?: string | number | null;
  to?: string | number | null;
  showPreposition?: boolean;
  className?: string;
}

const PACVariationLabel: React.FC<Props> = ({
  label,
  from,
  to,
  showPreposition = true,
  className = '',
}) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <label className="text-sm text-gray-600">{label}</label>
      <PACVariationRow
        rowText={from}
        preposition={showPreposition ? 'Da' : ''}
      />
      <PACVariationRow rowText={to} preposition={showPreposition ? 'A' : ''} />
    </div>
  );
};

const PACVariationRow: React.FC<{
  rowText?: string | number | null;
  preposition: string;
}> = ({ rowText = '', preposition }) => {
  if (!rowText) return <></>;

  return (
    <div>
      <span>{preposition}</span>{' '}
      <span className="text-md font-semibold">{rowText}</span>
    </div>
  );
};

export default PACVariationLabel;
