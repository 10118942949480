import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import { SmallPrimaryButtonFull } from '../Buttons';
import { useNavigate } from 'react-router-dom';
import WarningOutlineButton from '../Buttons/WarningOutlineButton';
import { KYCStatus } from '../../lib/interfaces/IUser';

interface Props {
  link: string;
  buttonText: string;
}

const UserPaymentElegibilityModal: React.FC<Props> = ({ link, buttonText }) => {
  const navigate = useNavigate();
  const [{ user, isVerified }] = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation('PRODUCTS');

  const toggle = () => setIsOpen((v) => !v);

  const handleRedirect = () => {
    if (isVerified) return navigate(link);
    toggle();
  };

  return (
    <>
      <SmallPrimaryButtonFull text={buttonText} onClick={handleRedirect} />
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto font-sans"
          onClose={toggle}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-2xl sm:p-6 sm:align-middle">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={toggle}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex-col sm:flex sm:items-start">
                  <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t('INCOMPLETE_PROFILE_WARNING')}
                    </Dialog.Title>
                  </div>
                  {!user?.onboardingDone && (
                    <div className="mt-5 w-full">
                      <WarningOutlineButton
                        text={'Completa il tuo profilo'}
                        onClick={() => navigate('/survey')}
                        icon={true}
                      />
                    </div>
                  )}
                  {user?.kyc?.status !== KYCStatus.SUCCESS && (
                    <div className="mt-5 w-full">
                      <WarningOutlineButton
                        text={'Verifica la tua identità'}
                        onClick={() => navigate('/kyc')}
                        icon={true}
                      />
                    </div>
                  )}
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {/* <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-violet focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={toggle}
                  >
                    {t('COMMON:CANCEL')}
                  </button> */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default UserPaymentElegibilityModal;
