import { useEffect, useState } from 'react';
import IFaq from '../../lib/interfaces/IFaq';
import { useAuth } from '../../providers/AuthProvider';
import SettingsPresentational from './SettingsPresentational';
import listFaqs from '../../lib/fetch/faqs/list';
import { ISecurityUpdateForm } from './Components/SecurityTab';
import { updateSecurity } from '../../lib/fetch/auth';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Settings: React.FC<{}> = () => {
  const [{ token, user }] = useAuth();
  const navigate = useNavigate();
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation();
  const [faqs, setFaqs] = useState<IFaq[]>([]);

  const fetchFaqs = async () => {
    const { error, data } = await listFaqs(token as string);
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    if (data) {
      setFaqs(data.data || []);
    }
  };

  const onUpdateSecuritySettings = async (values: ISecurityUpdateForm) => {
    const { data: updatedUser, error } = await updateSecurity(
      token!,
      user!.id,
      {
        id: user!.id,
        email: values.email,
        phone: values.phone,
        password: values.newPassword ? values.newPassword : undefined,
      }
    );
    if (error) {
      const { data } = error || {};
      let { message } = data || {};
      message ||= t('COMMON:SOMETHING_WENT_WRONG');

      return dispatchAlertChange({
        open: true,
        type: AlertType.Error,
        message,
      });
    }
    dispatchAlertChange({
      open: true,
      type: AlertType.Success,
      message: t('SETTINGS:SECURITY_SETTINGS_UPDATED'),
    });
    if (!updatedUser.emailConfirmed) {
      return navigate('/email-confirm', {
        state: { redirectTo: '/settings?tab=security' },
      });
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  return (
    <SettingsPresentational
      faqs={faqs}
      onSubmitSecurity={onUpdateSecuritySettings}
    />
  );
};

export default Settings;
