import moment from 'moment';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import AppHead from '../../components/Headers/AppHead';
import UserPaymentElegibilityModal from '../../components/Modals/UserPaymentElegibilityModal';
import HeaderPage from '../../components/Navigation/HederPage';
import Sidebar from '../../components/Navigation/Sidebar';
import LineChartDashboard from '../../components/VariusElements/LineChartDashboard';
import TabsChartFilter from '../../components/VariusElements/TabsChartFilter';
import { IProduct } from '../../lib/interfaces';
import { IProductChartRecord } from '../../lib/interfaces/IProduct';
import currency from '../../lib/utils/currency';
import { ChartFilters } from '../Dashboard';
import Spinner from '../../components/Spinner';
import TrendLabel from '../../components/Labels/TrendLabel';

interface Props {
  product?: IProduct;
  chartData: IProductChartRecord[];
  chartFilters: ChartFilters;
  onChangeChartFilters: Dispatch<SetStateAction<ChartFilters>>;
  isLoading?: boolean;
}
const InfoProductPresentational: React.FC<Props> = ({
  product,
  chartFilters,
  chartData,
  onChangeChartFilters,
  isLoading,
}) => {
  const { t } = useTranslation('MY_CONTRACTS');
  const { id } = product || {};
  const onChangeChartFilter = useCallback(
    (filter: 'from' | 'to') => (ev: React.ChangeEvent<HTMLInputElement>) => {
      onChangeChartFilters((chartFilters) => ({
        ...chartFilters,
        [filter]: moment.utc(ev.target.value).toDate(),
      }));
    },
    [onChangeChartFilters]
  );
  return (
    <>
      <AppHead name={t('HEAD_NAME_CONTRACT')} />
      <div className="flex h-full min-h-screen font-sans">
        <Sidebar />
        <div className="flex-1 lg:pl-52">
          <div className="py-10 px-6">
            <HeaderPage title={t('INFO_PRODUCT_TITLE')} />

            <div className="w-full">
              <h4 className="mb-4 block text-lg font-bold text-purple">
                {product?.attributes?.name}
              </h4>

              <p className="text-base font-normal text-violet">
                {product?.attributes?.description}
              </p>
              <div className="mt-6 w-full lg:grid lg:grid-cols-12 lg:gap-4">
                <main className="col-span-12 mb-6 flex flex-col gap-4 md:flex-row lg:col-span-4 lg:flex-col">
                  <h4 className="mt-4 mb-4 block text-lg font-bold text-purple">
                    {t('MINIMUM_INVESTMENT_AMOUNT')}
                  </h4>
                  <p className="text-base font-normal text-violet">
                    {t('STARTING_FROM')}
                    {currency(product?.attributes?.initialIssuePrice || 0)}
                  </p>

                  <h4 className="mt-4 mb-4 block text-lg font-bold text-purple">
                    {t('MINIMUM_SUBSCRIPTION_AMOUNT')}
                  </h4>
                  <p className="text-base font-normal text-violet">
                    {t('STARTING_FROM')}
                    {currency(product?.attributes?.minimumSubscription || 0)}
                  </p>
                  <div className="mt-8 w-full">
                    <UserPaymentElegibilityModal
                      buttonText={t('PRODUCTS:BUY_NOW')}
                      link={`/payment-type?productId=${id}`}
                    />
                  </div>
                </main>
                <aside className="col-span-12 lg:col-span-8 xl:block">
                  <div className="w-full rounded-md border border-gray-200 px-4 py-6">
                    <h3 className="mb-1 text-xl font-medium text-gray-800 md:text-3xl">
                      {t('DASHBOARD:TREND_TITLE')}
                    </h3>
                    <div className="flex flex-col items-center justify-between gap-3 md:flex-row">
                      <TabsChartFilter
                        chartFilters={chartFilters}
                        onChangeChartFilters={onChangeChartFilters}
                      />
                      <div className="flex items-center gap-3">
                        <label className="text-xs font-normal text-gray-800">
                          {t('COMMON:FROM')}:
                        </label>
                        <input
                          type="date"
                          value={
                            chartFilters.from?.toISOString().slice(0, 10) || ''
                          }
                          onChange={onChangeChartFilter('from')}
                          className="block rounded-md border-transparent bg-gray-200 py-2 px-3 text-xs text-gray-800 focus:border-purple focus:outline-none focus:ring-0"
                          placeholder="Seleziona una data"
                        />
                        <label className="text-xs font-normal text-gray-800">
                          {t('COMMON:TO')}
                        </label>
                        <input
                          type="date"
                          value={
                            chartFilters.to?.toISOString().slice(0, 10) || ''
                          }
                          onChange={onChangeChartFilter('to')}
                          className="block rounded-md border-transparent bg-gray-200 py-2 px-3 text-xs text-gray-800 focus:border-purple focus:outline-none focus:ring-0"
                          placeholder="Seleziona una data"
                        />
                      </div>
                    </div>

                    <div className="my-4 mt-4 flex flex-row justify-between align-middle">
                      <div></div>
                      <div className="my-auto mb-4 flex items-center justify-center gap-4 md:justify-end">
                        <TrendLabel
                          price={Number(
                            chartData[chartData.length - 1]?.variation.toFixed(
                              4
                            )
                          )}
                        />
                        <p className="text-lg font-bold text-gray-800">
                          {chartData[chartData.length - 1]?.price}{' '}
                          <span className="font-normal uppercase">eur</span>
                        </p>
                      </div>
                    </div>

                    <div className="flex h-full w-full">
                      {!isLoading && <LineChartDashboard data={chartData} />}
                      {isLoading && (
                        <div className="my-auto flex h-full w-full">
                          <Spinner className="my-60 flex w-full text-purple" />
                        </div>
                      )}
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoProductPresentational;
