import { useTranslation } from 'react-i18next';
import { ContractStatus } from '../../lib/constants/contracts';
import { useMemo } from 'react';

interface Props {
  status: ContractStatus;
}

const ContractStatusBadge: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation('DASHBOARD');

  const badgeData = useMemo(() => getBadgeData(status), [status]);

  return (
    <div className="flex items-center rounded-md bg-white py-1 px-2">
      <div className={`mr-1 h-2 w-2 rounded-full ${badgeData.bg}`} />
      <p className="m-0 text-xs font-bold">{t(badgeData.label)}</p>
    </div>
  );
};

const getBadgeData = (status: ContractStatus) => {
  switch (status) {
    case ContractStatus.DRAFT:
      return {
        bg: 'bg-gray-300',
        label: 'MY_CONTRACTS:CONTRACT_DRAFT',
      };
    case ContractStatus.SUSPENDED:
      return {
        bg: 'bg-yellow-600',
        label: 'MY_CONTRACTS:CONTRACT_SUSPENDED',
      };
    case ContractStatus.ACTIVE:
      return {
        bg: 'bg-green-300',
        label: 'MY_CONTRACTS:CONTRACT_ACTIVE',
      };
    case ContractStatus.CLOSED:
      return {
        bg: 'bg-red-600',
        label: 'MY_CONTRACTS:CONTRACT_CLOSED',
      };

    default:
      return { bg: '', label: '' };
  }
};

export default ContractStatusBadge;
