import axios from 'axios';

const { REACT_APP_OTP_URL } = process.env;

const confirmPhone = async (token: string, code: string) => {
  const response = await axios.get(`${REACT_APP_OTP_URL!}/phone-confirmation`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      code,
    },
  });

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default confirmPhone;
