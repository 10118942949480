import axios, { AxiosResponse } from 'axios';
import { UserStatistics } from '../../interfaces/IStatistics';

const { REACT_APP_STATISTICS_URL } = process.env;

const findOne = async ({
  token,
  userId,
}: {
  token?: string | null;
  userId?: string;
}) => {
  const response = await axios.get<UserStatistics>(
    `${REACT_APP_STATISTICS_URL}/users/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default findOne;
