interface Props {
  name?: string;
}

const AppHead: React.FC<Props> = ({ name }) => {
  return (
    <head>
      <title>{name} | Rigsave Capital</title>
      <meta />
      <meta name="name" content={`${name} | Rigsave Capital`} />
      <meta name="description" content="Rigsave global equity value" />
    </head>
  );
};
export default AppHead;
