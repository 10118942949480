import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from '../../lib/utils/classNames';

interface IDatePickerPresentational {
  label: string;
  id: string;
  name: string;
  autoComplete?: string;
  hasError?: boolean;
  helperText?: string;
  disabled?: boolean;
  minDate?: string | number;
  maxDate?: string | number;
}

const DatePickerPresentational = forwardRef(
  (
    {
      label,
      id,
      name,
      autoComplete,
      hasError,
      helperText,
      disabled = false,
      minDate,
      maxDate,
      ...rest
    }: IDatePickerPresentational,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const { t } = useTranslation();

    return (
      <div>
        <label className="block font-sans text-base font-normal text-purple">
          {label}
        </label>
        <div className="relative">
          <input
            ref={ref}
            id={id}
            name={name}
            autoComplete={autoComplete}
            type="date"
            min={minDate}
            max={maxDate}
            className={classNames(
              'mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 text-base text-purple focus:border-purple focus:outline-none focus:ring-0',
              disabled
                ? 'cursor-not-allowed opacity-50'
                : 'cursor-auto opacity-100'
            )}
            placeholder={t('COMMON:PICK_A_DATE')}
            disabled={disabled}
            {...rest}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {hasError && (
              <ExclamationCircleIcon
                className="m-5 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
        {helperText && (
          <p
            className={`mt-2 text-sm text-${hasError ? 'red-600' : 'gray-500'}`}
            id={`${id}-${hasError ? 'error' : 'helper'}`}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  }
);

export default DatePickerPresentational;
