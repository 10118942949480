import { StringParam, useQueryParam } from 'use-query-params';
import { ContractStatus } from '../../lib/constants/contracts';
import { useMemo } from 'react';
import classNames from '../../lib/utils/classNames';

interface Props {
  onChangeContractStatusFilter: (status?: ContractStatus) => void;
}

const ContractStatusFiltersTab: React.FC<Props> = ({
  onChangeContractStatusFilter,
}) => {
  const [status] = useQueryParam('status', StringParam);

  const tabs = useMemo(
    () => [
      {
        name: 'Attivi',
        value: ContractStatus.ACTIVE,
        current: status === ContractStatus.ACTIVE,
      },
      {
        name: 'Chiusi',
        value: ContractStatus.CLOSED,
        current: status === ContractStatus.CLOSED,
      },
      {
        name: 'Bozza',
        value: ContractStatus.DRAFT,
        current: status === ContractStatus.DRAFT,
      },
      {
        name: 'Sospesi',
        value: ContractStatus.SUSPENDED,
        current: status === ContractStatus.SUSPENDED,
      },
      {
        name: 'Tutti',
        value: undefined,
        current: status === undefined,
      },
    ],
    [status]
  );

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 text-xs focus:border-violet focus:ring-0"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-2" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={classNames(
                tab.current
                  ? 'bg-gray-100 text-gray-800'
                  : 'text-gray-500 hover:text-gray-800',
                'rounded-md p-2 text-xs font-medium'
              )}
              aria-current={tab.current ? 'page' : undefined}
              onClick={() => onChangeContractStatusFilter(tab.value)}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default ContractStatusFiltersTab;
