// import { PencilIcon } from '@heroicons/react/solid';
// import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { PrimaryButtonFull } from '../../components/Buttons';
import HeaderPage from '../../components/Navigation/HederPage';
import Sidebar from '../../components/Navigation/Sidebar';
import { IStrapiFile } from '../../lib/interfaces';
import { useAuth } from '../../providers/AuthProvider';
import ContractualDoc from './ProfileData/ContractualDoc';
import IdentityDoc from './ProfileData/IdentityDoc';
import Registry from './ProfileData/Registry';
import Survey from './ProfileData/Survey';
import ActionInfoBox from '../../components/VariusElements/ActionInfoBox';
import AppHead from '../../components/Headers/AppHead';
import moment from 'moment';
import { KYCStatus } from '../../lib/interfaces/IUser';

interface IProfilePresentational {
  userDocuments: IStrapiFile[];
  contractualDocuments: IStrapiFile[];
  onClick?: (url: string, fileName: string) => void;
  onUpdateDocument: () => void;
}

const ProfilePresentational: React.FC<IProfilePresentational> = (props) => {
  const [{ user }] = useAuth();
  const { userDocuments, contractualDocuments, onClick, onUpdateDocument } =
    props;
  const navigate = useNavigate();
  const { t } = useTranslation('LOGIN');

  return (
    <>
      <AppHead name={t('HEAD_NAME_PROFILE')} />
      <div className="flex h-full min-h-screen font-sans">
        <Sidebar />
        <div className="flex-1 lg:pl-52">
          <div className="py-10 px-6">
            <div>
              <HeaderPage title={t('PROFILE:TITLE')} />
              <div>
                <ActionInfoBox
                  condition={!user?.onboardingDone}
                  action={() => navigate('/survey')}
                  actionText="Compila"
                  message={'Non hai ancora concluso il questionario'}
                />
                <ActionInfoBox
                  condition={
                    !user?.kyc || user?.kyc?.status !== KYCStatus.SUCCESS
                  }
                  action={() => navigate('/kyc')}
                  actionText="Identificati"
                  message={"Non hai ancora eseguito l'identificazione KYC"}
                />
                <ActionInfoBox
                  condition={
                    !!user?.kyc &&
                    user?.kyc?.status === KYCStatus.SUCCESS &&
                    moment(user.kyc.documentExpirationDate).isBefore(moment())
                  }
                  action={onUpdateDocument}
                  actionText="Aggiorna"
                  message={t('PROFILE:UPDATE_USER_DOCUMENT_WARNING')}
                />
                <ActionInfoBox
                  condition={!user?.emailConfirmed}
                  action={() =>
                    navigate('/email-confirm', {
                      state: { redirectTo: '/profile' },
                    })
                  }
                  actionText="Verifica"
                  message={t('PROFILE:CONFIRM_EMAIL_WARNING')}
                />
                {/* <ActionInfoBox
                  condition={!user?.phoneConfirmed}
                  action={() =>
                    navigate('/phone-confirm', {
                      state: { redirectTo: '/profile' },
                    })
                  }
                  actionText="Verifica"
                  message={t('PROFILE:CONFIRM_PHONE_WARNING')}
                /> */}
              </div>
            </div>
            <div className="mt-6 w-full">
              <Registry />
              <IdentityDoc files={userDocuments} onClick={onClick} />
              <ContractualDoc files={contractualDocuments} onClick={onClick} />
              <Survey />
            </div>

            <div className="mx-auto mt-8 w-2/5 text-center">
              <Link to="/survey" state={{ isEditing: true }}>
                <PrimaryButtonFull text={t('PROFILE:MODIFY_DATA_BTN')} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePresentational;
