import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { sendOTP, verifyOTP } from '../../lib/fetch/2fa';
import { useAlert } from '../../providers/AlertProvider';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import VerifyOTPPresentational from '../ConfirmEmail/ConfirmEmailPresentational';

export interface IVerifyCodeForm {
  code: string;
}

const VerifyOTP = () => {
  const [loading, setLoading] = useState(false);
  const [, dispatchAlertChange] = useAlert();
  const [{ token }, dispatchAuthChange] = useAuth();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [lang] = i18n.languages;
  const [locale] = lang.split('-');

  useEffect(() => {
    // TODO: LET USER CHOOSE METHOD WHEN SMS OTP IS OK
    sendVerify('email');
  }, []);

  const sendVerify = async (method: 'phone' | 'email') => {
    setLoading(true);
    const { error } = await await sendOTP(token!, method, locale);
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }

    setLoading(false);
  };

  const onVerifyCode = async (values: IVerifyCodeForm) => {
    setLoading(true);
    const { error, data } = await verifyOTP(token!, values.code);
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    const { user } = data;
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user,
    });

    setLoading(false);
    navigate('/');
  };

  return <VerifyOTPPresentational loading={loading} onSubmit={onVerifyCode} />;
};

export default VerifyOTP;
