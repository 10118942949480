import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { register } from '../../lib/fetch/auth';
import { useAlert } from '../../providers/AlertProvider';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import RegistrationPresentational from './RegistrationPresentational';
import { v4 as uuidv4 } from 'uuid';

export interface IRegisterForm {
  email: string;
  emailConfirm: string;
  password: string;
  passwordConfirm: string;
}

enum STRAPI_REGISTRATION_ERROR_MESSAGES {
  EmailAlreadyTaken = 'Email is already taken',
}

const Registration = () => {
  const { t } = useTranslation('LOGIN');
  const [, dispatchAuthChange] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onRegister = async (values: IRegisterForm) => {
    setLoading(true);
    const { error, data: registerResponse } = await register({
      uuid: uuidv4(),
      username: values.email,
      email: values.email,
      password: values.password,
    });
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      if (message === STRAPI_REGISTRATION_ERROR_MESSAGES.EmailAlreadyTaken) {
        message = t('EMAIL_ALREADY_TAKEN');
      }
      setLoading(false);
      return dispatchAlertChange({
        open: true,
        message,
      });
    }
    const { jwt, user } = registerResponse;

    dispatchAuthChange({
      type: AuthActionType.Login,
      token: jwt,
      user,
    });

    navigate('/email-confirm', { state: { redirectTo: '/additional-data' } });
  };

  return (
    <RegistrationPresentational loading={loading} onRegister={onRegister} />
  );
};

export default Registration;
