import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';
import { useDebounce } from 'use-debounce';
import { addErrorInterceptor } from '../../lib/interceptors';
import { me } from '../../lib/fetch/auth';
import { IUser } from '../../lib/interfaces';
import { KYCStatus } from '../../lib/interfaces/IUser';

enum AuthErrorStates {
  Unauthorized = 401,
}

export enum AuthActionType {
  Login = 'login',
  Logout = 'logout',
  SetUser = 'setUser',
  SetAppLoaded = 'setAppLoaded',
}

interface AuthState {
  appLoaded: boolean;
  isAuthenticated: boolean;
  token: string | null;
  user?: IUser;
  isVerified?: boolean;
}

interface AuthAction {
  type: AuthActionType;
  token?: string;
  user?: IUser;
  remember?: boolean;
}

const INITIAL_AUTH_STATE: AuthState = Object.freeze({
  appLoaded: false,
  isAuthenticated: false,
  token: null,
  isVerified: false,
});

const isUserVerified = (user?: IUser): boolean =>
  !!user?.emailConfirmed &&
  !!user?.onboardingDone &&
  user.kyc?.status === KYCStatus.SUCCESS; // && user.phoneConfirmed;

const reducer = (state: AuthState, action: AuthAction): AuthState => {
  const { type, token = null, user } = action;
  switch (type) {
    case AuthActionType.Logout:
      return {
        ...INITIAL_AUTH_STATE,
        appLoaded: true,
      };
    case AuthActionType.Login:
      return {
        ...state,
        isAuthenticated: true,
        appLoaded: true,
        token,
        user,
      };
    case AuthActionType.SetAppLoaded:
      return {
        ...state,
        appLoaded: true,
      };
    case AuthActionType.SetUser:
      return {
        ...state,
        isAuthenticated: true,
        appLoaded: true,
        isVerified: isUserVerified(user),
        user,
      };
    default:
      return { ...state };
  }
};

const AuthContext = createContext<[AuthState, (_action: AuthAction) => void]>([
  { ...INITIAL_AUTH_STATE },
  (_action: AuthAction) => {},
]);

const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const { token } = cookies;
  const isAuthenticated = !!token;
  const initialState = {
    ...INITIAL_AUTH_STATE,
    isAuthenticated,
    token,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { appLoaded } = state;
  const [shouldCallMe] = useDebounce(isAuthenticated && !appLoaded, 100);

  const setCookiesAndDispatch = (action: AuthAction) => {
    const { type, token = null, remember = false } = action;
    switch (type) {
      case AuthActionType.Login:
        remember && setCookie('token', token);
        break;
      case AuthActionType.Logout:
        removeCookie('token');
        break;
      default:
        break;
    }
    return dispatch(action);
  };

  useEffect(() => {
    addErrorInterceptor(AuthErrorStates.Unauthorized, () =>
      setCookiesAndDispatch({
        type: AuthActionType.Logout,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    shouldCallMe
      ? (async () => {
          const { error, data: user } = await me(token);
          if (error)
            setCookiesAndDispatch({
              type: AuthActionType.Logout,
            });
          else dispatch({ type: AuthActionType.SetUser, user });
        })()
      : dispatch({ type: AuthActionType.SetAppLoaded });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCallMe]);

  return (
    <AuthContext.Provider value={[state, setCookiesAndDispatch]}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
