import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from '../../lib/utils/classNames';
import SmallPrimaryButtonFull from '../Buttons/SmallPrimaryButtonFull';

const PaymentTypeBox = ({
  title,
  description,
  linkText,
  disabled = false,
}: {
  title: string;
  description: string;
  linkText: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('MY_CONTRACTS');
  return (
    <div
      className={classNames(
        'flex flex-col rounded-md border border-gray-200 px-4 py-6',
        disabled && 'opacity-70'
      )}
    >
      <div className="flex-1">
        <h3 className="mb-1 text-3xl font-bold text-purple">{title}</h3>

        <p
          className="my-6 text-sm text-gray-500"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>

      <Link to={linkText} className={disabled ? 'pointer-events-none' : ''}>
        <SmallPrimaryButtonFull text={t('CHOOSE_BTN')} />
      </Link>
    </div>
  );
};

export default PaymentTypeBox;
