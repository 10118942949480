import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  XIcon,
} from '@heroicons/react/solid';
import classNames from '../../lib/utils/classNames';

interface Props {
  description: string;
  type: 'info' | 'warning' | 'error';
  children?: JSX.Element;
  className?: string;
  title?: string;
}

const InfoBox: React.FC<Props> = ({
  description,
  type,
  children,
  className,
  title,
}) => {
  return (
    <div
      className={classNames(
        type === 'info' && 'mt-2 rounded-md bg-blue-50 p-4',
        type === 'warning' && 'mt-2 rounded-md bg-yellow-50 p-4',
        type === 'error' && 'mt-2 rounded-md bg-red-50 p-4',
        className
      )}
    >
      <div className="flex">
        <div className="my-auto flex-shrink-0">
          {type === 'info' && (
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          )}
          {type === 'warning' && (
            <ExclamationCircleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          )}
          {type === 'error' && (
            <XIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          )}
        </div>
        <div className="ml-3 flex flex-col">
          <span
            className={classNames(
              type === 'info' && 'text-sm font-bold text-blue-700',
              type === 'warning' && 'text-sm font-bold text-yellow-700',
              type === 'error' && 'text-sm font-bold text-red-700',
              'my-auto'
            )}
          >
            {title}
          </span>
          <p
            className={classNames(
              type === 'info' && 'text-sm text-blue-700',
              type === 'warning' && 'text-sm text-yellow-700',
              type === 'error' && 'text-sm text-red-700',
              'my-auto'
            )}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
      </div>

      {children}
    </div>
  );
};
export default InfoBox;
