import React from 'react';
import { useTranslation } from 'react-i18next';
import DragAndDrop from '../../../components/Form/DragAndDrop';
import FileUploadPrev from '../../../components/Form/FileUploadPrev';
import { IStrapiFile } from '../../../lib/interfaces';

interface IStep3 {
  residentialFiles: IStrapiFile[];
  acceptedFiles: readonly string[];
  onResidenceFileChange: (_: File[]) => Promise<void>;
  onFileDelete: (_: IStrapiFile) => Promise<void>;
}

const Step3: React.FC<IStep3> = (props) => {
  const {
    acceptedFiles = [],
    residentialFiles = [],
    onResidenceFileChange,
    onFileDelete,
  } = props;

  const { t } = useTranslation('SURVEY');
  return (
    <div className="pt-6 pb-12 md:px-10">
      <h2 className="mb-6 text-3xl font-bold text-purple">
        {t('RESIDENCE_DOCUMENTS')}
      </h2>

      <DragAndDrop
        acceptedFiles={acceptedFiles}
        onFileChange={onResidenceFileChange}
      />

      <div className="mt-8 flex flex-col gap-4">
        {residentialFiles.map((residentialFile, index) => (
          <FileUploadPrev
            key={index}
            file={residentialFile}
            onDelete={(file) => onFileDelete(file as IStrapiFile)}
          />
        ))}
      </div>
    </div>
  );
};

export default Step3;
