import classNames from '../../lib/utils/classNames';

interface Props {
  title: string;
  className?: string;
}

const InfoContainer: React.FC<Props> = ({ children, title, className }) => {
  return (
    <div
      className={classNames(
        'col-span-4 w-full rounded-md border border-gray-200 px-4 py-6',
        className
      )}
    >
      <p className="font-regular text-base text-violet">{title}</p>
      {children}
    </div>
  );
};

export default InfoContainer;
