import React from 'react';
import { useTranslation } from 'react-i18next';
import AppHead from '../../components/Headers/AppHead';
import HeaderPage from '../../components/Navigation/HederPage';
import Pagination from '../../components/Navigation/Pagination';
import Sidebar from '../../components/Navigation/Sidebar';
import InfoBox from '../../components/VariusElements/InfoBox';
import INews from '../../lib/interfaces/INews';
import SingleNewsCard from './SingleNewsCard';

interface Props {
  news: INews[];
  currentPage: number;
  pageCount: number;
  onChangePage: (page: number) => void;
}

const NewsListPresentational: React.FC<Props> = ({
  news = [],
  currentPage,
  pageCount,
  onChangePage = () => {},
}) => {
  const { t } = useTranslation('NEWS');
  return (
    <>
      <AppHead name={t('HEAD_NAME')} />
      <div className="flex h-full min-h-screen font-sans">
        <Sidebar />
        <div className="flex-1 lg:pl-52">
          <div className="flex h-full flex-col px-6 pt-10">
            <HeaderPage title={t('TITLE')} />

            <div className="flex w-full flex-col gap-4">
              {!news ||
                (!news.length && (
                  <InfoBox description={t('NO_NEWS')} type={'info'} />
                ))}
              {news.map((singleNews, idx) => (
                <SingleNewsCard
                  singleNews={singleNews}
                  key={`single-news-${idx}`}
                />
              ))}
            </div>
            <div className="mt-auto">
              <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                onChangePage={onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsListPresentational;
