import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  createEnumParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';

import { list as getWireTransferData } from '../../lib/fetch/wireTransfer';

import { ContractStatus } from '../../lib/constants/contracts';
import { PaymentMethod, PaymentType } from '../../lib/constants/payments';
import {
  confirmContract,
  createContract,
  generateDocument,
  generateSignatureCode,
  getContract,
  updateContract,
  verifySignature,
} from '../../lib/fetch/contracts';
import { remove as removeFile } from '../../lib/fetch/files';
import { getProduct } from '../../lib/fetch/products';
import {
  ContractSignatureType,
  IContractPayload,
  IStrapiFile,
  PACFrequency,
} from '../../lib/interfaces';
import getObjectPaths from '../../lib/utils/getObjectPath';
import UnauthorizedPage from '../../lib/utils/UnauthorizedPage';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import ContractWizardPresentational from './ContractWizardPresentational';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { Gender } from '../../lib/constants/survey';

export const QUERY_PARAMS_SCHEMA = Object.freeze({
  contractId: NumberParam,
  productId: NumberParam,
  contractNumber: StringParam,
  oneTimeAmount: NumberParam,
  subscriptionType: createEnumParam([
    PaymentType.ONE_TIME,
    PaymentType.ONE_TIME_PAC,
    PaymentType.PAC,
  ]),
  paymentMethod: createEnumParam([
    PaymentMethod.STRIPE,
    PaymentMethod.WIRE_TRANSFER,
  ]),
  pacAmount: NumberParam,
  pacDuration: NumberParam,
  productName: StringParam,
  contractTitle: StringParam,
  pacFrequency: createEnumParam([
    PACFrequency.MONTHLY,
    PACFrequency.QUARTERLY,
    PACFrequency.HALF_YEARLY,
    PACFrequency.ANNUAL,
  ]),
  isUpdatingSubAmount: StringParam,
});

const STEPS_COUNT = 6;

const ContractWizard = () => {
  const [{ token, isVerified, user }] = useAuth();
  const [query, setQuery] = useQueryParams(QUERY_PARAMS_SCHEMA);
  const [currentStep, setCurrentStep] = useState(0);
  const queryClient = useQueryClient();
  const contractLoaded = useRef(false);
  const navigate = useNavigate();
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('SURVEY');

  const { contractId, productId, isUpdatingSubAmount, contractTitle } = query;

  // TODO: handle errors

  const productQuery = useQuery(
    ['product', productId],
    () => getProduct(token!, productId!),
    { enabled: !!productId }
  );

  const contractQuery = useQuery(
    ['contract', contractId, isUpdatingSubAmount],
    () => getContract(token!, contractId!),
    {
      enabled: !!contractId || !!isUpdatingSubAmount,
      refetchOnWindowFocus: false,
      onSuccess({ data }) {
        const contract = data.data;

        const userId = user?.id;
        const contractTitle = query.contractTitle;
        const step = isUpdatingSubAmount
          ? contract?.attributes?.updatingData?.step || 0
          : contract?.attributes?.contractData?.step || 0;

        const sectionData: 'updatingData' | 'contractData' =
          isUpdatingSubAmount && contract?.attributes.updatingData
            ? 'updatingData'
            : 'contractData';

        formMethods.reset({
          user: userId,
          product: Number(productId) || contract?.attributes?.product?.data?.id, //
          contractTitle,
          fromClient: true,
          isUpdatingSubAmount: !!isUpdatingSubAmount,
          contractData: {
            id: contract.attributes.contractData.id,
            step,
            customerData: contract?.attributes[sectionData]?.customerData,
            bankData: {
              iban: contract?.attributes[sectionData].bankData?.iban || '',
              bank: contract?.attributes[sectionData].bankData?.bank || '',
              registeredTo:
                contract?.attributes[sectionData].bankData?.registeredTo || '',
              transferFiles: contract?.attributes[
                sectionData
              ]?.bankData?.transferFiles?.data?.map((f) => f.id),
            },
            subscriptionData:
              contract?.attributes[sectionData]?.subscriptionData,
            customerDocument: {
              ...contract?.attributes[sectionData]?.customerDocument,
              documentFiles: contract?.attributes[
                sectionData
              ]?.customerDocument?.documentFiles?.data?.map((f) => f.id),
            },
          },
        });

        if (!contract.attributes.updatingData && isUpdatingSubAmount) {
          formMethods.setValue(
            'contractData.subscriptionData.privacyConsent',
            false
          );
          formMethods.setValue(
            'contractData.subscriptionData.pacAutomaticChargeConsent',
            false
          );
          formMethods.setValue(
            'contractData.subscriptionData.pacAmount',
            Number(query.pacAmount)
          );
        }
      },
    }
  );

  const { data: transferData } = useQuery(['wireTransferData'], () =>
    getWireTransferData(token!)
  );

  const contract = contractQuery.data?.data.data;

  useEffect(() => {
    if (!contract) {
      //Qua entro solo se sto creando il contratto, quindi non c'è il contratto
      return formMethods.reset({
        user: user?.id,
        product: Number(productId),
        contractTitle,
        fromClient: true,
        contractData: {
          subscriptionData: {
            paymentMethod: query.paymentMethod || PaymentMethod.WIRE_TRANSFER,
            subscriptionType: query.subscriptionType || PaymentType.ONE_TIME,
            oneTimeAmount: query.oneTimeAmount || undefined,
            pacAmount: query.pacAmount || undefined,
            pacDuration: query.pacDuration || undefined,
            pacFrequency: (query.pacFrequency as PACFrequency) || undefined,
            productName: query.productName + '',
          },
          customerData: {
            firstName: user?.firstName + '',
            lastName: user?.lastName + '',
            gender: user?.gender || Gender.MALE,
            phone: user?.phone + '',
            fiscalCode: user?.fiscalCode + '',
            country: user?.country + '',
            district: user?.district + '',
            address: user?.address + '',
            houseNumber: user?.houseNumber + '',
            city: user?.city + '',
            postalCode: user?.postalCode + '',
            birthDate: user?.birthDate + '',
            birthCountry: user?.birthCountry + '',
            birthDistrict: user?.birthDistrict + '',
            birthCity: user?.birthCity + '',
          },
          step: 0,
        },
      });
    }

    if (!contractLoaded.current && !isUpdatingSubAmount) {
      setCurrentStep(contract.attributes.contractData.step);
      contractLoaded.current = true;
    }
    if (!contractLoaded.current && isUpdatingSubAmount) {
      setCurrentStep(contract?.attributes?.updatingData?.step || 0);
      contractLoaded.current = true;
    }
  }, [contract, isUpdatingSubAmount, user]);

  const createMutation = useMutation(
    (values: IContractPayload) => createContract(token!, values),
    {
      onSuccess({ data }) {
        queryClient.invalidateQueries(['contract']);
        const { data: newContract } = data;
        setQuery({
          ...query,
          contractId: newContract.id,
          productId: newContract.attributes.product.data.id,
          contractNumber: newContract.attributes.contractNumber,
          paymentMethod: PaymentMethod.WIRE_TRANSFER,
        });
        onNextStep();
      },
      onError(error) {
        console.error('Error creating contract', error);
        dispatchAlertChange({
          open: true,
          message: 'Errore durante la creazione del contratto',
        });
      },
    }
  );

  const formMethods = useForm<IContractPayload>({});

  const updateMutation = useMutation(
    (values: FormData) => updateContract(token!, contractId!, values),
    {
      onSuccess() {
        queryClient.invalidateQueries(['contract']);
        if (currentStep === 5)
          return navigate(
            `/thankyou?paymentMethod=${PaymentMethod.WIRE_TRANSFER}&contractNumber=${contract?.attributes.contractNumber}`
          );
        onNextStep();
      },
      onError(error) {
        console.error('Error updating contract', error);
        dispatchAlertChange({
          open: true,
          message: "Errore durante l'aggiornamento del contratto",
        });
      },
    }
  );
  const generateDocumentMutation = useMutation(
    () => generateDocument(token!, contractId!, !!isUpdatingSubAmount),
    {
      onSuccess() {
        queryClient.invalidateQueries(['contract']);
        onNextStep();
      },
      onError(error) {
        console.error('Error generating contract', error);
        dispatchAlertChange({
          open: true,
          message: 'Errore durante la compilazione del contratto',
        });
      },
    }
  );

  const generateSignatureCodeMutation = useMutation(
    ({
      signatureName,
      isUpdatingSubAmount,
    }: {
      signatureName: ContractSignatureType;
      isUpdatingSubAmount: boolean;
    }) =>
      generateSignatureCode(
        token!,
        contractId!,
        signatureName,
        isUpdatingSubAmount
      ),
    {
      onSuccess() {
        queryClient.invalidateQueries(['contract']);
      },
      onError(error) {
        console.error('Error generating otp code', error);
        dispatchAlertChange({
          open: true,
          message: 'Errore durante la generazione del codice OTP',
        });
      },
    }
  );

  const signContractMutation = useMutation(
    ({
      signatureName,
      code,
      isUpdatingSubAmount,
    }: {
      signatureName: ContractSignatureType;
      code: string;
      isUpdatingSubAmount: boolean;
    }) =>
      verifySignature(
        token!,
        contractId!,
        signatureName,
        code,
        isUpdatingSubAmount
      ),
    {
      onSuccess() {
        queryClient.invalidateQueries(['contract']);
      },
      onError(error) {
        console.error('Error signing contract', error);
        dispatchAlertChange({ open: true, message: 'Codice firma non valido' });
      },
    }
  );

  const confirmContractMutation = useMutation(
    () => confirmContract(token!, contractId!, !!isUpdatingSubAmount),
    {
      onSuccess() {
        queryClient.invalidateQueries(['contract']);
        if (formMethods.getValues().isUpdatingSubAmount)
          return navigate(
            `/thankyou?paymentMethod=${PaymentMethod.WIRE_TRANSFER}&contractNumber=${contract?.attributes.contractNumber}`
          );

        onNextStep();
      },
      onError(error) {
        console.error('Error updating contract', error);
        dispatchAlertChange({
          open: true,
          message: "Errore durante l'aggiornamento del contratto",
        });
      },
    }
  );

  const onChangeStep = (step: number) => {
    if (step > STEPS_COUNT - 1) return;
    if (
      step > currentStep &&
      (contract?.attributes.contractData.step || 0) < step
    )
      return;
    setCurrentStep(step);
  };

  const onNextStep = () => {
    if (currentStep + 1 > STEPS_COUNT - 1) return;
    setCurrentStep(currentStep + 1);
  };

  const onCreateContract = async (values: IContractPayload) => {
    values.contractData.step = currentStep + 1;
    await createMutation.mutateAsync(values);
  };

  const onUpdateContract = async (values: IContractPayload) => {
    if (values.contractData.step < currentStep + 1)
      values.contractData.step = currentStep + 1;

    const data = {};
    const formData = new FormData();
    getObjectPaths(values)
      .map((val: any) => val.join('.'))
      .forEach((path) => {
        const value = _.get(values, path);

        if (value instanceof File) {
          formData.append(
            `files.${path.replace(/\.\d+$/, '')}`,
            value,
            value.name
          );
        } else {
          _.set(data, path, value);
        }
      });

    formData.append('data', JSON.stringify(data));

    await updateMutation.mutateAsync(formData);
  };

  const onGenerateSignatureCode = async (
    signatureName: ContractSignatureType
  ) => {
    await generateSignatureCodeMutation.mutateAsync({
      signatureName,
      isUpdatingSubAmount: !!isUpdatingSubAmount,
    });
  };

  const onSignContract = async (
    signatureName: ContractSignatureType,
    code: string
  ) => {
    await signContractMutation.mutateAsync({
      signatureName,
      code,
      isUpdatingSubAmount: !!isUpdatingSubAmount,
    });
  };

  const onFileDelete = async (fileToDelete: IStrapiFile) => {
    await removeFile(token!, fileToDelete);
    dispatchAlertChange({
      message: t('FILE_DELETE_SUCCESS', { name: fileToDelete.name }),
      open: true,
      type: AlertType.Success,
    });
    contractQuery.refetch();
  };

  if (productQuery.data?.error)
    return (
      <UnauthorizedPage message="Prodotto non trovato" redirect={'/products'} />
    );

  if (contract && contract.attributes.status !== ContractStatus.DRAFT) {
    <UnauthorizedPage
      message="Sottoscrizione già attiva"
      redirect={'/products'}
    />;
  }

  if (!isVerified)
    return (
      <UnauthorizedPage
        message="Completa il tuo profilo per effettuare un versamento"
        redirect={'/profile'}
      />
    );

  if (!productId && !contractId)
    return (
      <UnauthorizedPage
        message="Nessun prodotto selezionato"
        redirect={'/products'}
      />
    );

  return (
    <ContractWizardPresentational
      contract={contract}
      currentStep={currentStep}
      loading={
        contractQuery.isFetching ||
        createMutation.isLoading ||
        generateDocumentMutation.isLoading ||
        updateMutation.isLoading ||
        createMutation.isLoading ||
        generateSignatureCodeMutation.isLoading ||
        confirmContractMutation.isLoading ||
        signContractMutation.isLoading
      }
      formMethods={formMethods}
      onChangeStep={onChangeStep}
      onNextStep={onNextStep}
      onCreateContract={onCreateContract}
      onUpdateContract={onUpdateContract}
      onGenerateDocument={async () =>
        void (await generateDocumentMutation.mutateAsync())
      }
      onGenerateSignatureCode={onGenerateSignatureCode}
      onSignContract={onSignContract}
      onConfirmContract={async () => void confirmContractMutation.mutateAsync()}
      onFileDelete={onFileDelete}
      wireTransferData={transferData?.data.data.attributes}
    />
  );
};

export default ContractWizard;
