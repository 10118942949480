import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Registration from './pages/Registration';
import ConfirmEmail from './pages/ConfirmEmail';
import AdditionalData from './pages/AdditionalData';
import ConfirmNumber from './pages/ConfirmNumber';
import RegistrationMade from './pages/RegistrationMade';
import Dashboard from './pages/Dashboard';
import Survey from './pages/Survey';
import SurveyCompleted from './pages/SurveyCompleted';
import './App.css';
import Profile from './pages/Profile';
import PaymentType from './pages/PaymentType';
import Payment from './pages/Payment';
import ThankYou from './pages/ThankYou';
import InfoProduct from './pages/InfoProduct';
import ContractDetail from './pages/ContractDetail';
import TransactionHistory from './pages/TransactionHistory';
import Settings from './pages/Settings';
import { PrivateRoute } from './components/Auth';
import VerifyOTP from './pages/VerifyOTP';
import ListProducts from './pages/ListProducts';
import ListNews from './pages/ListNews';
import Forgot from './pages/Forgot';
import Reset from './pages/Reset';
import PaymentFailed from './pages/PaymentFailed';
import ContractWizard from './pages/ContractWizard';
import Kyc from './pages/Kyc';
import VariationsHistory from './pages/VariationsHistory';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute element={<Dashboard />} />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/reset" element={<Reset />} />
      <Route path="/signup" element={<Registration />} />
      <Route
        path="/email-confirm"
        element={<PrivateRoute element={<ConfirmEmail />} />}
      />
      <Route
        path="/phone-confirm"
        element={<PrivateRoute element={<ConfirmNumber />} />}
      />
      <Route
        path="/verify-code"
        element={<PrivateRoute element={<VerifyOTP />} />}
      />
      <Route
        path="/additional-data"
        element={<PrivateRoute element={<AdditionalData />} />}
      />
      <Route
        path="/signup-completed"
        element={<PrivateRoute element={<RegistrationMade />} />}
      />
      <Route path="/survey" element={<PrivateRoute element={<Survey />} />} />
      <Route path="/kyc" element={<PrivateRoute element={<Kyc />} />} />
      <Route
        path="/survey-completed"
        element={<PrivateRoute element={<SurveyCompleted />} />}
      />
      {/* <Route
        path="/contracts"
        element={<PrivateRoute element={<MyContracts />} />}
      /> */}
      <Route
        path="/subscribe"
        element={<PrivateRoute element={<ContractWizard />} />}
      />
      <Route
        path="/payment-type"
        element={<PrivateRoute element={<PaymentType />} />}
      />
      <Route path="/payment" element={<PrivateRoute element={<Payment />} />} />
      <Route
        path="/thankyou"
        element={<PrivateRoute element={<ThankYou />} />}
      />
      <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
      <Route
        path="/products"
        element={<PrivateRoute element={<ListProducts />} />}
      />
      <Route
        path="/products/:id"
        element={<PrivateRoute element={<InfoProduct />} />}
      />
      <Route
        path="/contracts/:id"
        element={<PrivateRoute element={<ContractDetail />} />}
      />
      <Route path="/news" element={<PrivateRoute element={<ListNews />} />} />
      <Route
        path="/transaction-history"
        element={<PrivateRoute element={<TransactionHistory />} />}
      />
      <Route
        path="/transaction-history/:id"
        element={<PrivateRoute element={<TransactionHistory />} />}
      />
      <Route
        path="/variations-history/:id"
        element={<PrivateRoute element={<VariationsHistory />} />}
      />
      <Route
        path="/settings"
        element={<PrivateRoute element={<Settings />} />}
      />
      <Route
        path="/payment-failed"
        element={<PrivateRoute element={<PaymentFailed />} />}
      />
    </Routes>
  );
};

export default App;
