import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { PrimaryButtonFull } from '../../components/Buttons';
import AppHead from '../../components/Headers/AppHead';
import StepsHeader from '../../components/StepsHeader';
import StepsHeaderMobile from '../../components/StepsHeader/StepsHeaderMobile';
import { IStrapiFile, IUser, IUserAssessment } from '../../lib/interfaces';
import { useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';

export const STEPS_TITLES = Object.freeze([
  'STEP1_TITLE',
  'STEP2_TITLE',
  'STEP3_TITLE',
  'STEP4_TITLE',
]);

interface ISurveyPresentational {
  currentStep: number;
  isEditing: boolean;
  loading: boolean;
  acceptedFiles: readonly string[];
  userDocuments: IStrapiFile[];
  residentialDocuments: IStrapiFile[];
  onChangeStep: (step: number) => void;
  onSubmitUser: (values: IUser) => Promise<void>;
  onSubmitUserAssessment: (values: IUserAssessment) => Promise<void>;
  onFileChange: (_: File[]) => Promise<void>;
  onResidenceFileChange: (_: File[]) => Promise<void>;
  onFileDelete: (_: IStrapiFile) => Promise<void>;
  onNextStep: () => Promise<void>;
}

const SurveyPresentational: React.FC<ISurveyPresentational> = (props) => {
  const {
    currentStep,
    isEditing,
    loading = false,
    acceptedFiles = [],
    userDocuments = [],
    residentialDocuments,
    onChangeStep = (_: number) => {},
    onSubmitUser = async (_: IUser) => {},
    onSubmitUserAssessment = async (_: IUserAssessment) => {},
    onFileChange,
    onResidenceFileChange,
    onFileDelete,
    onNextStep = async () => {},
  } = props;

  const [{ user }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('SURVEY');
  const formMethods = useForm<IUser & IUserAssessment>({
    defaultValues: {
      ...user,
      ...user?.user_assessment,
    },
  });
  const { handleSubmit } = formMethods;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    formMethods.reset({
      ...user,
      ...user?.user_assessment,
    });
  }, [user]);

  const getStep = (currentStep: number) => {
    switch (currentStep) {
      case 0:
        return <Step1 />;
      case 1:
        return (
          <Step2
            acceptedFiles={acceptedFiles}
            files={userDocuments}
            onFileChange={onFileChange}
            onFileDelete={onFileDelete}
          />
        );
      case 2:
        return (
          <Step3
            residentialFiles={residentialDocuments}
            acceptedFiles={acceptedFiles}
            onResidenceFileChange={onResidenceFileChange}
            onFileDelete={onFileDelete}
          />
        );
      case 3:
        return <Step4 />;
      default:
        return isEditing ? (
          <Navigate replace to="/profile" />
        ) : (
          <Navigate replace to="/survey-completed" />
        );
    }
  };

  const onSaveAndContinue = async () => {
    switch (currentStep) {
      case 0:
        handleSubmit(
          async (values) => {
            await onSubmitUser(values);
            onNextStep();
          },
          () =>
            dispatchAlertChange({
              open: true,
              message: t('COMMON:VALIDATION_ERROR'),
            })
        )();
        break;
      case 3:
        handleSubmit(
          async (values) => {
            await onSubmitUserAssessment(values);
            onNextStep();
          },
          () =>
            dispatchAlertChange({
              open: true,
              message: t('COMMON:VALIDATION_ERROR'),
            })
        )();
        break;
      default:
        onNextStep();
        break;
    }
  };

  const onSaveAndExit = async () => {
    switch (currentStep) {
      case 0:
        handleSubmit(
          async (values) => {
            await onSubmitUser(values);
            onNextStep();
            navigate(isEditing ? '/profile' : '/');
          },
          () =>
            dispatchAlertChange({
              open: true,
              message: t('COMMON:VALIDATION_ERROR'),
            })
        )();
        break;
      case 3:
        handleSubmit(
          async (values) => {
            await onSubmitUserAssessment(values);
            onNextStep();
            navigate(isEditing ? '/profile' : '/');
          },
          () =>
            dispatchAlertChange({
              open: true,
              message: t('COMMON:VALIDATION_ERROR'),
            })
        )();
        break;
      default:
        onNextStep();
        navigate(isEditing ? '/profile' : '/');
        break;
    }
  };

  const onExitWithoutSave = () => {
    navigate(isEditing ? '/profile' : '/');
  };

  return (
    <>
      <AppHead name={t('HEAD_NAME')} />
      <div className="min-h-screen px-6 font-sans">
        <div className="flex w-full flex-row items-center justify-between py-6">
          <img
            className="w-28 md:w-40"
            src="../images/logo-rigsave.png"
            alt="Rigsave"
          />

          <div>
            <PrimaryButtonFull
              text={t('SAVE_LOGOUT')}
              onClick={onSaveAndExit}
            />
            <span
              onClick={onExitWithoutSave}
              className={
                'flex w-full cursor-pointer justify-center py-2 px-4 text-base font-bold text-violet underline hover:text-purple focus:outline-none'
              }
            >
              {t('NO_SAVE_LOGOUT')}
            </span>
          </div>
        </div>

        <div className="mx-auto max-w-4xl">
          <div className="hidden md:block">
            <StepsHeader
              currentStep={currentStep}
              steps={STEPS_TITLES}
              onChangeStep={onChangeStep}
            />
          </div>
          <div className="block md:hidden">
            <StepsHeaderMobile
              currentStep={currentStep}
              steps={STEPS_TITLES}
              onChangeStep={onChangeStep}
            />
          </div>
        </div>

        <form>
          <FormProvider {...formMethods}>{getStep(currentStep)}</FormProvider>

          <div className="mx-auto my-8 w-2/5 text-center">
            <PrimaryButtonFull
              text={t('COMMON:CONTINUE')}
              loading={loading}
              onClick={onSaveAndContinue}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default SurveyPresentational;
