import { useEffect, useMemo, useState } from 'react';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import ProfilePresentational from './ProfilePresentational';
import { IStrapiFile } from '../../lib/interfaces';
import { me } from '../../lib/fetch/auth';
import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import listContracts from '../../lib/fetch/contracts/list';
import { useNavigate } from 'react-router-dom';
import { resetDocument } from '../../lib/fetch/kyc';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const navigate = useNavigate();
  const [{ user, token }, dispatchAuthChange] = useAuth();
  const { t } = useTranslation();
  const [, dispatchAlertChange] = useAlert();
  const [userDocuments, setUserDocuments] = useState<IStrapiFile[]>([]);

  const download = (url: string, fileName: string) => {
    axios
      .get(url, {
        responseType: 'blob',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 60000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const contractsQuery = useQuery(
    ['contracts', user?.id],
    () => listContracts({ token: token!, userId: user!.id }),
    { enabled: !!token && !!user?.id }
  );

  const { data: { data: { data: contracts = [] } = {} } = {} } = contractsQuery;
  const contractualDocuments = useMemo(
    () =>
      contracts
        .filter((contract) => !!contract.attributes.compiledContract?.data)
        .map((contract) => ({
          id: contract.attributes.compiledContract!.data.id,
          ...contract.attributes.compiledContract!.data.attributes,
        })),
    [contracts]
  );

  const pacVariationDocs: IStrapiFile[] = useMemo(() => {
    const lastVariations: IStrapiFile[] = contracts
      .filter(
        (contract) =>
          !!contract.attributes.compiledContract?.data &&
          !!contract.attributes?.history &&
          !!contract.attributes.contractData.subscriptionData
            .updatePACFrequencyModule?.data?.attributes
      )
      .map((contract) => ({ ...contract }))
      .map((contract) => ({
        ...getFileAttributes(
          contract.attributes.contractData.subscriptionData
            .updatePACFrequencyModule?.data?.attributes
        ),
      }));
    const historyDocs: IStrapiFile[] = contracts
      .map((_contract) => _contract.attributes.history)
      .filter(
        (_history) =>
          Array.isArray(_history) && _history.length > 1 && !!_history
      )
      .flat()
      .filter(
        (_historyContract) =>
          Boolean(_historyContract) &&
          _historyContract?.subscriptionData?.updatePACFrequencyModule?.data
      )
      .map((_historyContract) =>
        getFileAttributes(
          _historyContract?.subscriptionData?.updatePACFrequencyModule?.data
            ?.attributes
        )
      );

    return [...lastVariations, ...historyDocs];
  }, [contracts]);

  const resetDocumentMutation = useMutation(['kyc', 'resetDocument'], () =>
    resetDocument(token!)
  );

  const onUpdateDocument = async () => {
    try {
      const res = await resetDocumentMutation.mutateAsync();
      if (res.data.ok) navigate('/kyc');
    } catch (error) {
      console.error('Error resetting kyc document', error);
      dispatchAlertChange({
        open: true,
        type: AlertType.Error,
        message: t('COMMON:SOMETHING_WENT_WRONG'),
      });
    }
  };

  const refetchProfile = async () => {
    const { data: updatedUser } = await me(token!);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
  };

  useEffect(() => {
    refetchProfile();
  }, []);

  useEffect(() => {
    setUserDocuments([
      ...(user?.personalDocuments || []),
      ...(user?.residentialDocuments || []),
      ...(user?.kyc?.documentImages || []),
    ]);
  }, [user]);

  return (
    <>
      <ProfilePresentational
        userDocuments={userDocuments}
        contractualDocuments={[
          ...contractualDocuments,
          ...(pacVariationDocs || []),
        ]}
        onClick={download}
        onUpdateDocument={onUpdateDocument}
      />
    </>
  );
};

const getFileAttributes = (file: IStrapiFile | undefined): IStrapiFile => {
  return {
    id: Number(file?.id),
    name: file?.name + '',
    hash: file?.hash + '',
    ext: file?.ext + '',
    mime: file?.mime + '',
    size: Number(file?.size),
    url: file?.url + '',
  };
};

export default Profile;
