const RespLogoLogin = () => {
  return (
    <div className="fixed top-0 left-0 block w-full bg-violet p-4 lg:hidden">
      <img
        className="w-28"
        src="../images/logo-rigsave-white.svg"
        alt="Rigsave"
      />
    </div>
  );
};

export default RespLogoLogin;
