import { PaperClipIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import packageDotJSON from '../../../../package.json';
import PrivacyPolicyModal from '../../../components/Modals/PrivacyPolicyModal';

const { version } = packageDotJSON;
export default function GeneralTab() {
  const { t } = useTranslation('SETTINGS');
  return (
    <>
      <div className="py-6 ">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-violet">
            {t('GENERAL')}
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            {t('GENERAL_DESCRIPTION')}
          </p>
        </div>

        <div className="mt-5">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">
                {t('PRIVACY_POLICY')}
              </dt>
              <dd className="mt-1 sm:col-span-2 sm:mt-0">
                <PrivacyPolicyModal />
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">
                {t('VERSION')}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {version}
              </dd>
            </div>

            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">
                {t('ATTACHMENTS')}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        resume_back_end_developer.pdf
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <button className="font-medium text-violet hover:text-purple">
                        Download
                      </button>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        coverletter_back_end_developer.pdf
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <button className="font-medium text-violet hover:text-purple">
                        Download
                      </button>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
