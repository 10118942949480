import { CheckIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

interface IStepsHeader {
  currentStep: number;
  steps: readonly string[];
  onChangeStep: (_: number) => void;
  disabled?: boolean;
}

export default function StepsHeader({
  currentStep,
  steps,
  onChangeStep = (_: number) => {},
  disabled = false,
}: IStepsHeader) {
  const { t } = useTranslation('SURVEY');

  return (
    <nav aria-label="Progress">
      <ol className="md:flex">
        {steps.map((stepName, index) => (
          <li key={index} className="relative md:flex md:flex-1">
            {currentStep > index ? (
              <button
                className="group flex w-full cursor-pointer items-center disabled:cursor-not-allowed"
                onClick={() => {
                  onChangeStep(index);
                }}
                disabled={disabled}
              >
                <span className="flex items-center px-2 py-4 text-sm font-bold">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-purple group-hover:bg-violet">
                    <CheckIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-bold text-gray-800">
                    {t(stepName)}
                  </span>
                </span>
              </button>
            ) : currentStep === index ? (
              <span
                className="flex items-center px-2 py-4 text-sm font-bold"
                aria-current="step"
                onClick={() => {
                  onChangeStep(index);
                }}
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-gray-100">
                  <span className="text-purple">{index + 1}</span>
                </span>
                <span className="ml-4 text-sm font-bold text-purple">
                  {t(stepName)}
                </span>
              </span>
            ) : (
              <span
                className="group flex items-center"
                onClick={() => {
                  onChangeStep(index);
                }}
              >
                <span className="flex items-center px-2 py-4 text-sm font-bold">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-gray-100 group-hover:border-gray-400">
                    <span className="text-gray-300 group-hover:text-violet">
                      {index + 1}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-bold text-gray-500 group-hover:text-violet">
                    {t(stepName)}
                  </span>
                </span>
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
