const currency = (amount: number | null | undefined) => {
  if (!amount && amount !== 0) return '-';

  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'EUR',
  }).format(amount);
};

export default currency;
