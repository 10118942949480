// import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { forwardRef, ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';

interface IRadioGroupPresentational {
  label: string;
  id: string;
  name: string;
  autoComplete?: string;
  hasError?: boolean;
  helperText?: string;
  disabled?: boolean;
  options: readonly { name: string; value: string }[];
}

const RadioGroupPresentational = forwardRef(
  (
    {
      label,
      id,
      name,
      autoComplete,
      hasError,
      helperText,
      disabled = false,
      options = [],
      ...rest
    }: IRadioGroupPresentational,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const { t } = useTranslation();
    return (
      <div>
        <label className="font-sans text-base font-normal text-purple">
          {label}
        </label>

        <fieldset className="mt-4">
          <div className="space-y-4">
            {options.map(({ name: fieldLabel, value }, index) => (
              <div key={index} className="flex items-center">
                <input
                  ref={ref}
                  id={id}
                  name={name}
                  value={value}
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  {...rest}
                />
                <label
                  htmlFor={id}
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  {t(fieldLabel)}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        {/* TODO: NEED DESIGNER HELP FOR POSITIONING */}
        {/* <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          {hasError && (
            <ExclamationCircleIcon
              className="m-5 h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
        </div> */}
        {helperText && (
          <p
            className={`mt-2 text-sm text-${hasError ? 'red-600' : 'gray-500'}`}
            id={`${id}-${hasError ? 'error' : 'helper'}`}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  }
);

export default RadioGroupPresentational;
