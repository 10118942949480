import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CurrencyEuroIcon, XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
// import { Switch } from '@headlessui/react';
// import classNames from '../../lib/utils/classNames';
// import PaymentInput from '../Form/PaymentInput';

interface Props {
  totalAmount?: string;
}

const GetRefundModal: React.FC<Props> = ({ totalAmount }) => {
  const [open, setOpen] = useState(false);
  const [{ user }] = useAuth();

  const [refundValue, setRefundValue] = useState<number>(
    Number(totalAmount || 0)
  );
  // const [enabled, setEnabled] = useState(false);
  const { t } = useTranslation('MY_CONTRACTS');
  const { REACT_APP_NOTIFY_ADMIN_EMAIL } = process.env;

  const onChangeRefund = (val: string) => {
    const insertedAmount = Number(val.replace(/[^0-9,]/g, '') || 0);
    if (Number(insertedAmount) > Number(totalAmount))
      return setRefundValue(Number(totalAmount));
    return setRefundValue(insertedAmount);
  };

  const toggle = ({ openMailTo }: { openMailTo?: boolean }) => {
    setOpen((v) => !v);
    if (openMailTo && window)
      window.location.href = `mailto:${REACT_APP_NOTIFY_ADMIN_EMAIL}?subject=Richiesta rimborso quote&body=${t(
        'REFUND_QUOTES',
        { name: `${user?.firstName} ${user?.lastName}`, amount: refundValue }
      )}`;
    setRefundValue(Number(totalAmount));
  };

  return (
    <>
      <button
        onClick={() => toggle({})}
        type="button"
        className="flex w-full items-center justify-center rounded-md border border-green-600 bg-white py-2 px-4 text-sm font-bold text-green-600 shadow-sm hover:bg-green-100 hover:text-green-700 focus:outline-none "
      >
        {t('GET_REFUND_BTN')}
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto font-sans"
          onClose={() => toggle({})}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-2xl sm:p-6 sm:align-middle">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => toggle({})}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <CurrencyEuroIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t('GET_REFUND_BTN')}
                    </Dialog.Title>
                    <div className="mt-6">
                      <p className="mb-2 text-sm text-gray-500">
                        {t('CURRENTLY_ACCUMULATED')}
                      </p>
                      <div className="flex w-full items-start justify-end gap-4 rounded-md bg-purple bg-opacity-25 px-4 py-6">
                        <input
                          className="mx-2 flex w-2/3 flex-row border-none bg-transparent text-right text-5xl font-bold text-purple focus:border-none focus:ring-0"
                          type="text"
                          defaultValue={refundValue}
                          onChange={({ target: { value } }) =>
                            onChangeRefund(value)
                          }
                          value={refundValue}
                        />
                        <span className="font-bold uppercase text-purple">
                          eur
                        </span>
                      </div>

                      <p className="mb-2 mt-2 text-sm text-gray-500">
                        *{t('AMOUNT_BEFORE_TAXATION_INFO')}
                      </p>
                      <p className="mb-2 mt-2 text-sm text-gray-500">
                        *{t('NAV_VARIATION_INFO')}
                      </p>
                      <form>
                        {/* <div className="my-4 flex items-center gap-4">
                          <Switch
                            checked={enabled}
                            onChange={setEnabled}
                            className={classNames(
                              enabled ? 'bg-green-600' : 'bg-gray-200',
                              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'
                            )}
                          >
                            <span className="sr-only">Use setting</span>
                            <span
                              className={classNames(
                                enabled ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                              )}
                            >
                              <span
                                className={classNames(
                                  enabled
                                    ? 'opacity-0 duration-100 ease-out'
                                    : 'opacity-100 duration-200 ease-in',
                                  'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                )}
                                aria-hidden="true"
                              >
                                <svg
                                  className="h-3 w-3 text-gray-400"
                                  fill="none"
                                  viewBox="0 0 12 12"
                                >
                                  <path
                                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <span
                                className={classNames(
                                  enabled
                                    ? 'opacity-100 duration-200 ease-in'
                                    : 'opacity-0 duration-100 ease-out',
                                  'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                )}
                                aria-hidden="true"
                              >
                                <svg
                                  className="h-3 w-3 text-green-600"
                                  fill="currentColor"
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                </svg>
                              </span>
                            </span>
                          </Switch>
                          <p className="text-base font-normal text-purple">
                            {t('GET_COMPLETE_REFUND_LABEL')}
                          </p>
                        </div> */}

                        {/* ----
                          NB: La visualizzazione dell'input deve avvenire solamente se la richiesta del riborso NON È per la cifra totale, in caso contrario, l'utente non visualizzerà l'input 
                      ---- */}
                        {/* {!enabled && <PaymentInput label={t('ADD_IMPORT')} />} */}
                      </form>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:bg-green-300 disabled:text-gray-600 disabled:hover:cursor-not-allowed sm:ml-3 sm:w-auto sm:text-sm"
                    disabled={!refundValue}
                    onClick={() => {
                      toggle({ openMailTo: true });
                    }}
                  >
                    {t('GET_REFUND_BTN')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-violet focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => toggle({})}
                  >
                    {t('COMMON:CANCEL')}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default GetRefundModal;
