import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../../components/Buttons';
import AppHead from '../../components/Headers/AppHead';
import HeaderPage from '../../components/Navigation/HederPage';
import Sidebar from '../../components/Navigation/Sidebar';
import {
  BankDataVariation,
  IContractConfirmed,
  IContractData,
} from '../../lib/interfaces';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import PACVariation from '../../components/VariusElements/PACVariation';

interface Props {
  contractQuery: UseQueryResult<
    {
      error: AxiosResponse<any, any> | undefined;
      data: {
        data: IContractConfirmed;
      };
      status: number;
    },
    unknown
  >;
}

const VariationsHistoryPresentational: React.FC<Props> = ({
  contractQuery,
}) => {
  const { t } = useTranslation('PAC_VARIATIONS');
  const navigate = useNavigate();

  return (
    <>
      <AppHead name={t('HEAD_TITLE')} />
      <div className="flex h-full min-h-screen font-sans">
        <Sidebar />
        <div className="flex-1 lg:pl-52">
          <div className="flex h-full flex-col px-6 pt-10">
            <HeaderPage title={t('HEAD_TITLE')} />

            <BackButton onClick={() => navigate(-1)} text={t('COMMON:BACK')} />

            <div className="flex w-full flex-col gap-4">
              {contractQuery.data?.data?.data?.attributes?.history?.map(
                (history, idx) => (
                  <PACVariation
                    history={getPACVariationData({
                      contract: contractQuery.data.data.data,
                      history: history,
                    })}
                    key={`variation-${idx}`}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const getPACVariationData = ({
  contract,
  history,
}: {
  contract: IContractConfirmed;
  history: IContractData;
}) => {
  const { toAmount, toBankData, fromBankData, fromAmount } = getHistoryData(
    contract,
    history,
    contract.attributes.history?.findIndex((elem) => elem.id === history.id) ||
      0
  );
  return {
    fromAmount,
    toAmount,
    id: history.id + '',
    date: history.updatedTime,
    toBankData,
    fromBankData,
  };
};

const getBankData = (contractData?: IContractData) => {
  if (!contractData?.bankData) return null;
  return {
    bankName: contractData?.bankData.bank,
    IBANCode: contractData?.bankData.iban,
    holder: contractData?.bankData.registeredTo,
  };
};

const getHistoryData = (
  contract: IContractConfirmed,
  actualHistory: IContractData,
  idx: number
) => {
  const data = [
    ...(contract?.attributes?.history || []),
    contract.attributes.contractData,
  ].find((elem, _idx) => _idx === idx + 1);

  const { toAmount, fromAmount, fromBankData, toBankData } =
    getHistoryVariations({
      nextHistory: data,
      actualHistory,
    });

  return {
    toAmount,
    fromAmount,
    toBankData,
    fromBankData,
  };
};

const getHistoryVariations = ({
  actualHistory,
  nextHistory,
}: {
  actualHistory?: IContractData;
  nextHistory?: IContractData;
}) => {
  let toAmount = nextHistory?.subscriptionData.pacAmount || null;
  let fromAmount = actualHistory?.subscriptionData.pacAmount || null;
  let fromBankData = getBankData(actualHistory);
  let toBankData = getBankData(nextHistory);

  if (Number(toAmount || 0) === Number(fromAmount)) {
    toAmount = null;
    fromAmount = null;
  }

  if (!hasBankDataChanged({ fromBankData, toBankData })) {
    fromBankData = null;
    toBankData = null;
  }

  return { fromAmount, toAmount, fromBankData, toBankData };
};

const hasBankDataChanged = ({
  fromBankData,
  toBankData,
}: {
  fromBankData?: BankDataVariation | null;
  toBankData?: BankDataVariation | null;
}) => {
  const hasHolderChanged = fromBankData?.holder !== toBankData?.holder;
  const hasIBANChanged = fromBankData?.IBANCode !== toBankData?.IBANCode;
  const hasBankNameChanged = fromBankData?.bankName !== toBankData?.bankName;

  return hasHolderChanged || hasIBANChanged || hasBankNameChanged;
};

export default VariationsHistoryPresentational;
