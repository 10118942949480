import { ContractStatus } from '../constants/contracts';
import { ProductType } from '../constants/payments';
import { IContractConfirmed, IProduct } from '../interfaces';
import { ProductStatistics } from '../interfaces/IStatistics';
import currency from './currency';

interface Increments {
  initialBalance: number;
  currentBalance: number | null;
  increment: number | null;
  fees: number;
  averageLoadingPrice: number | null;
  currentValue: number | null;
}

export const getProductListStatistics = (
  products: ProductStatistics[]
): Partial<Increments> => {
  return products.map(getProductStatistics).reduce(
    (acc, _prodStatsistic) => ({
      currentBalance:
        (_prodStatsistic?.currentBalance || 0) + acc.currentBalance,
      increment: 0,
      initialBalance: 0,
      averageLoadingPrice: 0,
    }),
    {
      currentBalance: 0,
      increment: 0,
      initialBalance: 0,
      averageLoadingPrice: 0,
    }
  );
};

/**
 *
 * @param product
 * @returns initial balance, increment and actual balance of a product
 */
export const getProductStatistics = (
  product: ProductStatistics | null | undefined
): Increments | null | undefined => {
  if (!product) return null;

  /**
   * actualBalance è il valore attuali delle quote, dato dal prodotto di actualValue e totalShares, che sono il totale delle quote
   * actualValue è il prezzo più aggiornato del valore delle quote
   *
   * totalCouponBonds è la somma totale delle cedole. Ce l'hanno soltanto i prodotti statici di RigSave.
   */
  const currentBalance = getProductCurrentBalance(product);
  /**
   * initialBalance è la somma totale dei pagamenti netti per un prodotto
   * totalDisinvestedAmount è la somma disinvestita ed è negativa (o zero)
   */
  const initialBalance = product.totalAmount;
  const averageLoadingPrice = product.lastAverageLoadPrice;
  const fees = product.fees;
  const increment = getProductIncrement(product);

  return {
    initialBalance,
    increment,
    currentBalance,
    fees,
    averageLoadingPrice,
    currentValue: product.actualValue,
  };
};

const getProductCurrentBalance = (
  product: ProductStatistics | null | undefined
) => {
  if (!product) return null;

  const {
    productType,
    totalAmount,
    totalCouponBonds,
    totalShares,
    actualValue,
  } = product;

  const totalAmountForClosedContracts =
    getTotalAmountForClosedContracts(product);

  switch (productType) {
    case ProductType.STATIC:
      return (
        totalAmount +
        totalCouponBonds -
        Number(totalAmountForClosedContracts || 0)
      );
    case ProductType.STANDARD:
      return (
        Number((actualValue * totalShares).toFixed(2)) -
        -Number(totalAmountForClosedContracts || 0)
      );
    default:
      return null;
  }
};

/**
 *
 * @param product
 * @returns a number
 *
 * il cliente ci ha chiesto che non vorrebbe vedere conteggiati gli importi dei
 * contratti chiusi nel totale controvalore investimento nella dashboard.
 * Vorrebbe, invece, visualizzarlo nei dettagli e nei riassunti dei singoli prodotti.
 */
const getTotalAmountForClosedContracts = (
  product: ProductStatistics | null | undefined
): number => {
  if (!product || product.productType === ProductType.STANDARD) return 0;
  const { excludeClosedContracts, totalAmountForClosedContracts } = product;

  if (excludeClosedContracts) return totalAmountForClosedContracts;

  return 0;
};

const getProductIncrement = (product: ProductStatistics) => {
  switch (product.productType) {
    case ProductType.STATIC:
      return getIncrementForStaticProduct(product);
    case ProductType.STANDARD:
      return getIncrementForStandardProduct(product);
    default:
      return null;
  }
};

const getIncrementForStaticProduct = (
  product: ProductStatistics
): number | null => {
  const { totalCouponBonds, totalAmount } = product;

  if (!totalCouponBonds || !totalAmount) return null;

  return (totalCouponBonds / totalAmount) * 100;
};

const getIncrementForStandardProduct = (
  product: ProductStatistics
): number | null => {
  const { lastAverageLoadPrice, actualValue } = product;

  if (!lastAverageLoadPrice || !actualValue) return null;

  return (
    ((Number(actualValue) - Number(lastAverageLoadPrice)) /
      Number(lastAverageLoadPrice)) *
    100
  );
};

export const shouldUserUploadBankDocs = (contract?: IContractConfirmed) => {
  if (Number(contract?.attributes?.history?.length || 0) > 0) return false;
  if (
    contract?.attributes.status === ContractStatus.SUSPENDED &&
    !contract.attributes.contractData.bankData?.transferFiles?.data
  )
    return true;
  return false;
};

export const isStaticProduct = (product?: IProduct | null) => {
  return product?.attributes?.type === ProductType.STATIC;
};

export const approximateNumber = ({
  number,
  decimalsNum = 0,
}: {
  number?: number | null;
  decimalsNum?: number;
}) => {
  const castNumber = Number(number);

  if (Number.isInteger(castNumber)) {
    return castNumber;
  } else {
    return (
      Math.round(castNumber * Math.pow(10, decimalsNum)) /
      Math.pow(10, decimalsNum)
    );
  }
};

export const getPriceValue = (lastAverageLoadPrice: number | null) => {
  if (!lastAverageLoadPrice) return '-';
  return currency(lastAverageLoadPrice);
};
