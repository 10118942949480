const SmallWhiteButtonFull = ({
  text,
  onClick
}: {
  text: string;
  onClick?: () => any;
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className="flex w-full justify-center rounded-md border border-transparent bg-white py-2 px-4 text-sm font-bold text-violet shadow-sm hover:bg-violet hover:text-white focus:outline-none"
    >
      {text}
    </button>
  );
};

export default SmallWhiteButtonFull;
