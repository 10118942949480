import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from 'use-query-params';
import HeaderPage from '../../components/Navigation/HederPage';
import Sidebar from '../../components/Navigation/Sidebar';
import FaqsTab from './Components/FaqsTab';
import GeneralTab from './Components/GeneralTab';
import MyTab from '../../components/Navigation/MyTab';
import IFaq from '../../lib/interfaces/IFaq';
import SecurityTab, { ISecurityUpdateForm } from './Components/SecurityTab';
import AppHead from '../../components/Headers/AppHead';

interface Props {
  faqs: IFaq[];
  onSubmitSecurity: (values: ISecurityUpdateForm) => void;
}

const SettingsPresentational: React.FC<Props> = ({
  faqs = [],
  onSubmitSecurity,
}) => {
  const { t } = useTranslation('SETTINGS');
  const [query] = useQueryParam('tab');
  const tabs = [
    {
      name: 'Generale',
      current: query === 'general',
      href: '/#/settings?tab=general',
    },
    {
      name: 'Sicurezza',
      current: query === 'security',
      href: '/#/settings?tab=security',
    },
    {
      name: 'Faq',
      current: query === 'faq',
      href: '/#/settings?tab=faq',
    },
  ];
  return (
    <>
      <AppHead name={t('HEAD_NAME')} />
      <div className="flex h-full min-h-screen font-sans">
        <Sidebar />
        <div className="flex-1 lg:pl-52">
          <div className="py-10 px-6">
            <HeaderPage title={t('TITLE')} />

            <MyTab tabs={tabs}>
              {query === 'general' && <GeneralTab />}
              {query === 'security' && (
                <SecurityTab onSubmit={onSubmitSecurity} />
              )}
              {query === 'faq' && <FaqsTab faqs={faqs} />}
            </MyTab>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPresentational;
