import { useAuth } from '../../providers/AuthProvider';
import PaymentTypePresentational from './PaymentTypePresentational';
import UnauthorizedPage from '../../lib/utils/UnauthorizedPage';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useEffect } from 'react';

const PaymentType = () => {
  const [{ isVerified }] = useAuth();
  const [productId] = useQueryParam('productId', NumberParam);

  useEffect(() => {
    if (!productId) return;
  }, [productId]);

  if (!isVerified)
    return (
      <UnauthorizedPage
        message="Completa il tuo profilo per effettuare un versamento"
        redirect={'/profile'}
      />
    );

  if (!productId)
    return (
      <UnauthorizedPage
        message="Nessun prodotto selezionato"
        redirect={'/products'}
      />
    );

  return <PaymentTypePresentational productId={productId} />;
};

export default PaymentType;
