import { ArrowRightIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import {
  PaymentMethod,
  PaymentStatus,
  PaymentType,
} from '../../lib/constants/payments';
import { IPayment } from '../../lib/interfaces';

interface Props {
  payment: IPayment;
  getCheckoutUrl: (id: number) => void;
}

const SingleTransactionBig: React.FC<Props> = ({ payment, getCheckoutUrl }) => {
  const { t } = useTranslation('DASHBOARD');

  const canRetryPayment =
    (payment.attributes?.status === PaymentStatus.FAILED ||
      payment.attributes?.status === PaymentStatus.PENDING) &&
    payment.attributes.method === PaymentMethod.STRIPE;

  return (
    <div className="flex flex-col items-center justify-between rounded-md bg-gray-100 p-4 sm:flex-row">
      <p className="text-center text-lg text-gray-800 sm:w-1/3  sm:text-left">
        {t('TRANSACTION_CODE')}:
        <span className="font-semibold">{payment.id}</span> -{' '}
        {match(payment.attributes?.type)
          .with(PaymentType.ONE_TIME, () => (
            <span className="font-regular text-base text-violet">
              {t('MY_PAYMENTS:SINGLE_PAYMENT')}
            </span>
          ))
          .with(PaymentType.PAC, () => (
            <span className="font-regular text-base text-violet">PAC</span>
          ))
          .with(PaymentType.ONE_TIME_PAC, () => (
            <span className="font-regular text-base text-violet">
              {t('MY_PAYMENTS:SINGLE_PAYMENT_PAC')}
            </span>
          ))
          .with(undefined, () => null)
          .exhaustive()}
        <br />
        <span className="text-sm text-gray-500">
          {t('TRANSACTIONS:PAYMENT_BY')}{' '}
          {match(payment.attributes?.method)
            .with(PaymentMethod.WIRE_TRANSFER, () => (
              <b className="text-teal-500">{t('MY_CONTRACTS:WIRE_TRANSFER')}</b>
            ))
            .with(PaymentMethod.STRIPE, () => (
              <b className="text-teal-500">{t('MY_CONTRACTS:CREDIT_CARD')}</b>
            ))
            .with(undefined, () => null)
            .exhaustive()}
        </span>
      </p>
      <ArrowRightIcon className="my-2  h-5 w-5 rotate-90 text-gray-800 sm:w-1/3 sm:rotate-0" />
      <div className="text-center sm:w-1/3 sm:text-right">
        <div className="flex items-center justify-end">
          <p className="text-base text-gray-800">
            {moment(payment.attributes?.createdAt).format('DD/MM/YYYY hh:mm')} -{' '}
            {match(payment.attributes?.status)
              .with(PaymentStatus.PAID, () => {
                if (payment.attributes?.isDisinvestment) {
                  return (
                    <span className="text-red-600">
                      {t('MY_PAYMENTS:DISINVESTED')}
                    </span>
                  );
                }

                return (
                  <span className="text-green-600">
                    {t('MY_PAYMENTS:PAID')}
                  </span>
                );
              })
              .with(PaymentStatus.PROCESSING, () => (
                <span className="text-yellow-600">
                  {t('MY_PAYMENTS:PROCESSING')}
                </span>
              ))
              .with(PaymentStatus.PENDING, () => (
                <span className="text-yellow-600">
                  {t('MY_PAYMENTS:PENDING')}
                </span>
              ))
              .with(PaymentStatus.FAILED, () => (
                <span className="text-red-600">{t('MY_PAYMENTS:FAILED')}</span>
              ))
              .with(PaymentStatus.ERROR, () => (
                <span className="text-red-600">{t('MY_PAYMENTS:ERROR')}</span>
              ))
              .with(undefined, () => null)
              .exhaustive()}
          </p>
        </div>
        <p className="text-2xl font-bold text-violet">
          {Math.abs(
            Number(payment.attributes?.oneTimeAmount) ||
              0 + Number(payment.attributes?.subscriptionAmount || 0)
          )?.toLocaleString('it')}{' '}
          {payment.attributes?.currency}
        </p>
        {canRetryPayment && (
          <div className="mt-2">
            <button
              onClick={() => getCheckoutUrl(payment.id)}
              className="flex w-full justify-center rounded-md border border-transparent bg-violet py-1 px-4 text-sm font-bold text-white shadow-sm hover:bg-purpleDark focus:outline-none"
            >
              {t('MY_PAYMENTS:RETRY')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleTransactionBig;
