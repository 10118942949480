import { useTranslation } from 'react-i18next';
import classNames from '../../lib/utils/classNames';

interface Props {
  text?: string;
  className?: string;
}

const NoData: React.FC<Props> = ({ text, className }) => {
  const { t } = useTranslation();
  return (
    <span
      className={classNames(
        'w-full text-center italic text-purple/75',
        className
      )}
    >
      {text || t('COMMON:NO_DATA_FOUND')}
    </span>
  );
};

export default NoData;
