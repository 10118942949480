import { useNavigate } from 'react-router-dom';
import { ProductType } from '../../lib/constants/payments';
import GetRefundModal from '../Modals/GetRefundModal';
import PaymentBlockModal from '../Modals/PaymentBlockModal';
import UserPaymentElegibilityModal from '../Modals/UserPaymentElegibilityModal';
import { useTranslation } from 'react-i18next';
import { IContractConfirmed } from '../../lib/interfaces';
import { ProductStatistics } from '../../lib/interfaces/IStatistics';
import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import { UseQueryResult } from '@tanstack/react-query';
import { getProductStatistics } from '../../lib/utils/helpers';

interface Props {
  contract: IContractConfirmed | undefined;
  contractStatsQuery: Partial<
    UseQueryResult<
      {
        error: AxiosResponse<any, any> | undefined;
        data: ProductStatistics;
        status: number;
        statusText: string;
        headers: AxiosResponseHeaders;
      },
      unknown
    >
  >;
}

const ContractActionsComponent: React.FC<Props> = ({
  contract,
  contractStatsQuery,
}) => {
  const { t } = useTranslation('DASHBOARD');
  const navigate = useNavigate();

  if (
    contract?.attributes.product.data.attributes.type === ProductType.STATIC ||
    !contract
  )
    return <></>;

  return (
    <div className="mt-6 flex flex-col gap-4">
      <UserPaymentElegibilityModal
        buttonText={t('POUR')}
        link={`/payment-type?productId=${contract?.attributes.product.data.id}`}
      />
      {contractStatsQuery?.data?.data && (
        <div className="grid grid-cols-2 gap-4">
          <PaymentBlockModal />
          <GetRefundModal
            totalAmount={Number(
              getProductStatistics(
                contractStatsQuery?.data?.data
              )?.currentBalance?.toFixed(2)
            )?.toLocaleString('it')}
          />
        </div>
      )}
      {contract?.attributes?.history?.length !== 0 && (
        <button
          onClick={() => navigate(`/variations-history/${contract?.id}`)}
          className='focus:outline-none" mx-auto inline-flex cursor-pointer items-center border border-transparent bg-white text-sm font-bold text-violet hover:text-purple'
        >
          {t('VIEW_HISTORY_VARIATIONS')}
        </button>
      )}
    </div>
  );
};

export default ContractActionsComponent;
