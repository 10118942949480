import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PrimaryButtonFull } from '../../components/Buttons';
import StepsHeader from '../../components/StepsHeader';
import StepsHeaderMobile from '../../components/StepsHeader/StepsHeaderMobile';
import {
  ContractSignatureType,
  IContract,
  IContractPayload,
  IStrapiFile,
} from '../../lib/interfaces';
import { useQueryParams } from 'use-query-params';
import { useAlert } from '../../providers/AlertProvider';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import { QUERY_PARAMS_SCHEMA } from '.';
import Step6 from './Steps/Step6';
import { ContractStatus } from '../../lib/constants/contracts';
import { IWireTransferDataAttributes } from '../../lib/fetch/wireTransfer';

export const STEPS_TITLES = Object.freeze([
  'CONTRACT.STEP1_TITLE',
  'CONTRACT.STEP2_TITLE',
  'CONTRACT.STEP3_TITLE',
  'CONTRACT.STEP4_TITLE',
  'CONTRACT.STEP5_TITLE',
]);

interface IContractWizardPresentational {
  currentStep: number;
  contract?: IContract;
  loading: boolean;
  onChangeStep: (step: number) => void;
  onNextStep: () => void;
  onCreateContract: (values: IContractPayload) => Promise<void>;
  onUpdateContract: (values: IContractPayload) => Promise<void>;
  onGenerateDocument: () => Promise<void>;
  formMethods: UseFormReturn<IContractPayload, any>;
  onGenerateSignatureCode: (
    signatureName: ContractSignatureType
  ) => Promise<void>;
  onSignContract: (
    signatureName: ContractSignatureType,
    code: string
  ) => Promise<void>;
  onConfirmContract: () => Promise<void>;
  onFileDelete: (_: IStrapiFile) => Promise<void>;
  wireTransferData?: IWireTransferDataAttributes;
}

const ContractWizardPresentational: React.FC<IContractWizardPresentational> = (
  props
) => {
  const {
    currentStep,
    loading = false,
    onChangeStep = (_: number) => {},
    onNextStep,
    onCreateContract,
    onSignContract,
    onGenerateSignatureCode,
    onConfirmContract,
    onUpdateContract,
    onGenerateDocument,
    contract,
    onFileDelete,
    formMethods,
    wireTransferData,
  } = props;
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('SURVEY');

  const { handleSubmit } = formMethods;
  const [query] = useQueryParams(QUERY_PARAMS_SCHEMA);
  const { isUpdatingSubAmount } = query;
  const [sectionData, setSectionData] = useState<
    'updatingData' | 'contractData'
  >(isUpdatingSubAmount === 'true' ? 'updatingData' : 'contractData');
  const stepsNames = useMemo(() => {
    if (isUpdatingSubAmount !== 'true')
      return [...STEPS_TITLES, 'CONTRACT.STEP6_TITLE'];
    return STEPS_TITLES;
  }, [isUpdatingSubAmount]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    return setSectionData(
      isUpdatingSubAmount === 'true' ? 'updatingData' : 'contractData'
    );
  }, [isUpdatingSubAmount]);

  const getStep = (currentStep: number) => {
    switch (currentStep) {
      case 0:
        return <Step1 disabled={isUpdatingSubAmount === 'true'} />;
      case 1: {
        return (
          <Step2
            files={(
              contract?.attributes[sectionData]?.customerDocument?.documentFiles
                ?.data || []
            ).map((f) => ({ id: f.id, ...f.attributes }))}
            onFileDelete={onFileDelete}
            disabled={isUpdatingSubAmount === 'true'}
          />
        );
      }
      case 2:
        return (
          <Step3
            files={(
              contract?.attributes[sectionData]?.bankData?.transferFiles
                ?.data || []
            ).map((f) => ({ id: f.id, ...f.attributes }))}
            onFileDelete={onFileDelete}
            disabled={isUpdatingSubAmount === 'true'}
          />
        );
      case 3:
        return (
          <Step4
            contract={contract}
            isUpdatingSubAmount={isUpdatingSubAmount === 'true'}
            isLoading={loading}
          />
        );
      case 4:
        return (
          <Step5
            contract={contract}
            onGenerateSignatureCode={onGenerateSignatureCode}
            onSignContract={onSignContract}
            isLoading={loading}
            sectionData={sectionData}
          />
        );
      case 5:
        return isUpdatingSubAmount !== 'true' ? (
          <Step6
            files={(
              contract?.attributes[sectionData]?.bankData?.transferFiles
                ?.data || []
            ).map((f) => ({ id: f.id, ...f.attributes }))}
            onFileDelete={onFileDelete}
            disabled={!!isUpdatingSubAmount}
            wireTransferData={wireTransferData}
          />
        ) : (
          <></>
        );
      default:
        return null;
    }
  };

  const onSave = (shouldExit: boolean) => async () => {
    switch (currentStep) {
      case 0:
        handleSubmit(
          async (values) => {
            if (contract) {
              await onUpdateContract(values);
            } else {
              await onCreateContract(values);
            }
            shouldExit && navigate('/products');
          },
          () =>
            dispatchAlertChange({
              open: true,
              message: t('COMMON:VALIDATION_ERROR'),
            })
        )();
        break;
      case 1:
        handleSubmit(
          async (values) => {
            await onUpdateContract(values);
            shouldExit && navigate('/products');
          },
          () =>
            dispatchAlertChange({
              open: true,
              message: t('COMMON:VALIDATION_ERROR'),
            })
        )();
        break;
      case 2:
        handleSubmit(
          async (values) => {
            await onUpdateContract(values);
            await onGenerateDocument();
            shouldExit && navigate('/products');
          },
          () =>
            dispatchAlertChange({
              open: true,
              message: t('COMMON:VALIDATION_ERROR'),
            })
        )();
        break;
      case 3:
        handleSubmit(
          async (values) => {
            await onUpdateContract(values);
            onNextStep();
            shouldExit && navigate('/products');
          },
          () =>
            dispatchAlertChange({
              open: true,
              message: t('COMMON:VALIDATION_ERROR'),
            })
        )();
        break;
      case 4:
        handleSubmit(
          async () => {
            shouldExit && navigate('/');
            if (
              isUpdatingSubAmount !== 'true' &&
              (!contract?.attributes[sectionData]?.contractSignature?.signed ||
                !contract?.attributes[sectionData]?.privacySignature?.signed ||
                contract.attributes.status !== ContractStatus.DRAFT)
            )
              return onNextStep();
            await onConfirmContract();
          },
          () =>
            dispatchAlertChange({
              open: true,
              message: t('COMMON:VALIDATION_ERROR'),
            })
        )();
        break;
      case 5:
        handleSubmit(
          async (values) => {
            await onUpdateContract(values);
            shouldExit && navigate('/products');
          },
          () =>
            dispatchAlertChange({
              open: true,
              message: t('COMMON:VALIDATION_ERROR'),
            })
        )();
        break;
      default:
        break;
    }
  };

  const onExitWithoutSave = () => {
    navigate('/');
  };

  return (
    <>
      <div className="min-h-screen px-6 font-sans">
        <div className="flex w-full flex-row items-center justify-between py-6">
          <img
            className="w-28 md:w-40"
            src="../images/logo-rigsave.png"
            alt="Rig save"
          />

          <div>
            <PrimaryButtonFull text={t('SAVE_LOGOUT')} onClick={onSave(true)} />
            <span
              onClick={onExitWithoutSave}
              className={
                'flex w-full cursor-pointer justify-center py-2 px-4 text-base font-bold text-violet underline hover:text-purple focus:outline-none'
              }
            >
              Esci senza salvare
            </span>
          </div>
        </div>

        <div className="mx-auto max-w-4xl">
          <div className="hidden md:block">
            <StepsHeader
              currentStep={currentStep}
              steps={stepsNames}
              onChangeStep={onChangeStep}
              disabled={currentStep === 5}
            />
          </div>
          <div className="block md:hidden">
            <StepsHeaderMobile
              currentStep={currentStep}
              steps={stepsNames}
              onChangeStep={onChangeStep}
              disabled={currentStep === 5}
            />
          </div>
        </div>

        <form>
          <FormProvider {...formMethods}>{getStep(currentStep)}</FormProvider>

          <div className="mx-auto my-8 w-2/5 text-center">
            <PrimaryButtonFull
              text={t('COMMON:CONTINUE')}
              loading={loading}
              onClick={onSave(false)}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default ContractWizardPresentational;
