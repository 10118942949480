import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { LogoutIcon } from '@heroicons/react/solid';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import { UserIcon as UserIconOutline } from '@heroicons/react/outline';
import { UserIcon as UserIconSolid } from '@heroicons/react/solid';
import classNames from '../../lib/utils/classNames';

const ProfileMenu = () => {
  const { t } = useTranslation('PROFILE');
  const [{ user }, dispatchAuthChange] = useAuth();
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="group block flex-shrink-0">
        <div className="flex items-center justify-end">
          <p className="text-sm font-medium text-purple">
            {user?.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : user?.email}
          </p>
          <span className="ml-3 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md border border-gray-200 bg-white">
            <UserIconSolid className="h-6 w-6 text-purple" />
          </span>
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/profile"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <UserIconOutline
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  {t('TITLE')}
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  onClick={() =>
                    dispatchAuthChange({
                      type: AuthActionType.Logout,
                    })
                  }
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex w-full items-center px-4 py-2 text-sm'
                  )}
                >
                  <LogoutIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  {t('SIGN_OUT')}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileMenu;
