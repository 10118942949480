import { StringParam, useQueryParam } from 'use-query-params';

import { IWireTransferDataAttributes } from '../../lib/fetch/wireTransfer';

interface Props {
  wireTransferData?: IWireTransferDataAttributes;
}

const WireTransferBox = ({ wireTransferData }: Props) => {
  const [contractNumber] = useQueryParam('contractNumber', StringParam);
  if (!wireTransferData) return null;

  const {
    bankAddressFirstLine,
    bankAddressSecondLine,
    bankCountry,
    bankName,
    beneficiary,
    iban,
  } = wireTransferData as IWireTransferDataAttributes;

  return (
    <div className="mt-2 w-full rounded-md border border-gray-200 bg-gray-50 p-4 text-gray-500">
      <p className="mb-4 text-xs font-light">
        {!!contractNumber ? (
          <>
            Pagamento Fondo RigSave in riferimento alla sottoscrizione{' '}
            <span className="font-bold">{contractNumber}</span>
          </>
        ) : (
          'La causale del bonifico verrà comunicata dopo la conferma'
        )}
      </p>
      <p className="text-sm font-normal">
        <span className="font-semibold">{beneficiary || ''}</span>
        <br />
        <span className="font-semibold">{iban || ''}</span>
        <br />
        {bankName || ''}
        <br />
        {bankAddressFirstLine || ''}
        <br />
        {bankAddressSecondLine || ''}
        <br />
        {bankCountry || ''}
      </p>
    </div>
  );
};

export default WireTransferBox;
