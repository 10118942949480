import React from 'react';
import { useTranslation } from 'react-i18next';
import StaticInput from '../../../components/Form/StaticInput';
import currency from '../../../lib/utils/currency';
import { useAuth } from '../../../providers/AuthProvider';

const POLITICAL_EXPOSURE_WIKI_URL =
  'https://it.wikipedia.org/wiki/Persona_Politicamente_Esposta';

const Survey: React.FC<{}> = () => {
  const [{ user }] = useAuth();
  const { user_assessment } = user || {};
  const { t } = useTranslation('SURVEY');

  return (
    <div className="mb-6 rounded-md border border-gray-200 px-4 py-6">
      <h4 className="font-regular mb-4 text-xl text-violet">{t('TITLE')}</h4>
      <div className="grid grid-cols-2 gap-6 lg:grid-cols-4">
        <StaticInput
          title={t('MARITAL_STATUS')}
          value={
            (user_assessment?.maritalStatus &&
              t(`MARITAL_STATUS_${user_assessment?.maritalStatus}`)) ||
            '-'
          }
        />
        <StaticInput
          title={t('EDUCATION_QUALIFICATION')}
          value={
            (user_assessment?.studyDegree &&
              t(`STUDY_DEGREE_${user_assessment?.studyDegree}`)) ||
            '-'
          }
        />
        <StaticInput
          title={t('INCOME_AMOUNT')}
          value={
            (user_assessment?.monthlyIncome &&
              currency(user_assessment?.monthlyIncome)) ||
            '-'
          }
        />
        <StaticInput
          title={t('FUNDS_INVESTED')}
          value={
            (user_assessment?.fundsSource &&
              t(`FUNDS_SOURCE_${user_assessment?.fundsSource}`)) ||
            '-'
          }
        />
        <StaticInput
          title={t('MY_VALUE')}
          value={
            (user_assessment?.goodsAmount &&
              t(`GOODS_AMOUNT_${user_assessment?.goodsAmount}`)) ||
            '-'
          }
        />
        <StaticInput
          title={
            <span>
              {t(`POLITICAL_EXPOSURE_1`)}
              <a
                href={POLITICAL_EXPOSURE_WIKI_URL}
                target="_blank"
                rel="noreferrer"
              >
                {t('(wiki)')}
              </a>{' '}
              {t('POLITICAL_EXPOSURE_2')}
            </span>
          }
          value={
            (user_assessment?.politicalExposure &&
              t(`COMMON:${user_assessment?.politicalExposure}`)) ||
            '-'
          }
        />
        <StaticInput
          title={t('EXPERIENCE')}
          value={
            (user_assessment?.financialKnowledge &&
              t(
                `FINANCIAL_KNOWLEDGE_${user_assessment?.financialKnowledge}`
              )) ||
            '-'
          }
        />
        <StaticInput
          title={t('RISK_PROPENSION')}
          value={
            (user_assessment?.riskAppetite &&
              t(`RISK_APPETITE_${user_assessment?.riskAppetite}`)) ||
            '-'
          }
        />
        <StaticInput
          title={t('INVESTMENT_INTENTION')}
          value={
            (user_assessment?.investmentIntention &&
              t(
                `INVESTMENT_INTENTION_${user_assessment?.investmentIntention}`
              )) ||
            '-'
          }
        />
        <StaticInput
          title={t('INVESTMENT_OBJECTIVE')}
          value={
            (user_assessment?.investmentGoal &&
              t(`INVESTMENT_GOAL_${user_assessment?.investmentGoal}`)) ||
            '-'
          }
        />
      </div>
    </div>
  );
};

export default Survey;
