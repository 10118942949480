import axios from 'axios';
import QueryString from 'qs';
import { StrapiResponseList } from '../../interfaces/Generics';
import { CouponBond } from '../../interfaces/ICouponBonds';
const { REACT_APP_BACKEND_URL } = process.env;

const intance = axios.create();

interface Props {
  query?: object;
  token?: string | null;
}

const find = async (data: Props) => {
  const { query, token } = data;
  const result = await intance.get<StrapiResponseList<CouponBond>>(
    `${REACT_APP_BACKEND_URL}/coupon-bonds?${QueryString.stringify(query)}`,
    { headers: token ? { authorization: `Bearer ${token}` } : undefined }
  );

  return result.data;
};

const couponBondsAPIs = { find };

export default couponBondsAPIs;
