import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ILoginForm } from '.';
import { PrimaryButtonFull } from '../../components/Buttons';
import InputPresentational from '../../components/Form/InputPresentational';
import AppHead from '../../components/Headers/AppHead';
import BgLogin from '../../components/VariusElements/BgLogin';
import RespLogoLogin from '../../components/VariusElements/RespLogoLogin';
import packages from '../../../package.json';

interface ILoginPresentational {
  loading: boolean;
  onLogin: (formData: ILoginForm) => void;
}

const LoginPresentational: React.FC<ILoginPresentational> = (props) => {
  const { loading = false, onLogin = (_formData: ILoginForm) => {} } = props;
  const { t } = useTranslation('LOGIN');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>();

  return (
    <>
      <AppHead name="Login" />
      <div className="flex min-h-screen font-sans">
        <BgLogin />

        <div className="relative flex w-3/5 flex-1 flex-col justify-between py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <RespLogoLogin />
          <div />
          <div className="mx-auto w-full max-w-xl">
            <h2 className="text-3xl font-bold text-purple">{t('LOGIN')}</h2>

            <div className="mt-8">
              <form onSubmit={handleSubmit(onLogin)}>
                <div className="grid grid-cols-1 gap-6">
                  <InputPresentational
                    label={t('LOGIN_LABEL')}
                    id="identifier"
                    autoComplete="email"
                    {...register('identifier', {
                      required: t('COMMON:REQUIRED') as string,
                    })}
                    hasError={!!errors.identifier}
                    helperText={errors.identifier?.message}
                  />
                  <InputPresentational
                    label={t('PASSWORD')}
                    id="password"
                    type="password"
                    autoComplete="current-password"
                    {...register('password', {
                      required: t('COMMON:REQUIRED') as string,
                    })}
                    hasError={!!errors.password}
                    helperText={errors.password?.message}
                  />
                </div>

                <div className="mt-6 mb-12 flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember"
                      aria-describedby="remember-description"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-purple focus:ring-purple"
                      {...register('remember')}
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      {t('REMEMBER_ME')}
                    </label>
                  </div>

                  <div className="text-sm">
                    <Link
                      to="/forgot"
                      className="font-medium text-violet hover:text-purple"
                    >
                      {t('FORGOT_PASSWORD')}
                    </Link>
                  </div>
                </div>

                <div className="mx-auto w-3/5 lg:mr-auto lg:ml-0">
                  <PrimaryButtonFull
                    loading={loading}
                    text={t('LOGIN')}
                    type="submit"
                  />
                </div>
              </form>
            </div>
            <p className="mt-6 text-center text-base text-gray-800 lg:absolute lg:top-6 lg:right-6 lg:mt-0">
              {t('NO_ACCOUNT_QUESTION')}{' '}
              <Link
                to="/signup"
                className="font-bold text-purple underline underline-offset-1 hover:text-violet"
              >
                {t('SIGN_IN')}
              </Link>
            </p>
          </div>
          <span className="bottom-3 mx-auto flex text-center text-sm text-gray-600">
            v{packages.version}
          </span>
        </div>
      </div>
    </>
  );
};

export default LoginPresentational;
