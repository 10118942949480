export enum PaymentStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  FAILED = 'FAILED',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
}

export enum PaymentType {
  PAC = 'PAC',
  ONE_TIME = 'ONE-TIME',
  ONE_TIME_PAC = 'ONE-TIME_PAC',
}

export enum ProductType {
  STANDARD = 'STANDARD',
  STATIC = 'STATIC',
}

export enum PaymentMethod {
  STRIPE = 'STRIPE',
  WIRE_TRANSFER = 'WIRE-TRANSFER',
}
