import axios from 'axios';

const { REACT_APP_OTP_URL } = process.env;

const sendEmailConfirmation = async (token: string, lang: string) => {
  const response = await axios.post(
    `${REACT_APP_OTP_URL!}/send-email-confirmation`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        locale: lang,
      },
    }
  );

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default sendEmailConfirmation;
