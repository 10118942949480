import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SmallOutlinePrimaryButtonFull } from '../../components/Buttons';
import UserPaymentElegibilityModal from '../../components/Modals/UserPaymentElegibilityModal';
import { IProduct } from '../../lib/interfaces';

interface Props {
  product: IProduct;
}

const ProductCard: React.FC<Props> = ({ product }) => {
  const {
    attributes = { name: '', description: '', initialIssuePrice: '' },
    id,
  } = product;
  const { name, description, initialIssuePrice } = attributes;
  const { t } = useTranslation();

  return (
    <div className="my-2 mr-2 w-full rounded-md bg-purple px-4 py-6">
      <p className="mb-1 text-base font-bold text-white line-clamp-2">{name}</p>
      <div className="flex items-start justify-end gap-4">
        <div>
          <span className="flex text-sm font-light text-white">
            {t('PRODUCTS:START_PRICE')}
          </span>
          <div className="flex text-right">
            <p className="mb-1 text-5xl font-bold text-white">
              {initialIssuePrice || 20}
            </p>
            <span className="ml-4 flex font-bold uppercase text-white">
              {product.attributes?.currency || 'EUR'}
            </span>
          </div>
        </div>
      </div>

      <div className="mt-6 flex flex-col gap-4">
        <p className="mb-3 text-white line-clamp-2">{description}</p>

        <UserPaymentElegibilityModal
          buttonText={t('PRODUCTS:BUY_NOW')}
          link={`/payment-type?productId=${id}`}
        />
        <Link to={`/products/${id}`}>
          <SmallOutlinePrimaryButtonFull text={t('DASHBOARD:DETAIL')} />
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
