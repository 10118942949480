import { useTranslation } from 'react-i18next';
import { PaymentMethod } from '../../lib/constants/payments';
import { IWireTransferDataAttributes } from '../../lib/fetch/wireTransfer';
import WireTransferBox from './WireTransferBox';

interface Props {
  wireTransferData?: IWireTransferDataAttributes;
  paymentMethod: PaymentMethod;
  onClick: (paymentMethod: PaymentMethod) => void;
}

const RadioGroupPayment: React.FC<Props> = ({
  paymentMethod,
  onClick,
  wireTransferData,
}) => {
  const { t } = useTranslation('MY_CONTRACTS');

  return (
    <>
      <fieldset className="mt-4 mb-6">
        <div className="space-y-6">
          {/* <div key="payment1" className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                id="payment1"
                aria-describedby="description`"
                name="card_method"
                type="radio"
                className="h-4 w-4 border-gray-300 text-violet focus:ring-violet"
                checked={paymentMethod === PaymentMethod.STRIPE}
                onChange={() => onClick(PaymentMethod.STRIPE)}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="payment1" className="font-medium text-gray-800">
                {t('CREDIT_CARD')}
              </label>
              <img
                className="mt-2 h-auto w-48"
                src="../images/credit-cards.svg"
                alt="credit cards"
              />
            </div>
          </div> */}

          <div key="payment2" className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                id="payment2"
                aria-describedby="description`"
                name="plan"
                type="radio"
                className="h-4 w-4 border-gray-300 text-violet focus:ring-violet"
                checked={paymentMethod === PaymentMethod.WIRE_TRANSFER}
                onChange={() => onClick(PaymentMethod.WIRE_TRANSFER)}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="payment2" className="font-medium text-gray-800">
                {t('WIRE_TRANSFER')}
              </label>
              <WireTransferBox wireTransferData={wireTransferData} />
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default RadioGroupPayment;
