import { IStrapiFile } from '../../lib/interfaces';

interface IFileReadPrev {
  onClick?: (url: string, fileName: string) => void;
  file: IStrapiFile | { name: string; url: string };
}

export default function FileReadPrev(props: IFileReadPrev) {
  const { file, onClick } = props;
  const { name, url } = file;
  return (
    <div className="flex w-full flex-row items-center justify-between">
      <a href={url} target="_blank" rel="noreferrer">
        <p className="text-base font-bold text-purple underline underline-offset-1 hover:text-violet">
          {name}
        </p>
      </a>
      <div
        onClick={() => onClick && onClick(url, name)}
        className="cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className=" h-9 w-9 min-w-[2.25rem] text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}
