import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IPayment } from '../../lib/interfaces';
import classNames from '../../lib/utils/classNames';
import SmallWhiteButtonFull from '../Buttons/SmallWhiteButtonFull';
import SingleTransaction from './SingleTransaction';

interface Props {
  contractId?: number;
  payments?: IPayment[];
  onChangePaymentsDateFilter: (paymentsDateFilter?: string) => void;
  paymentsDateFilter: string | undefined;
}
const TransactionsBoxDashboard: React.FC<Props> = ({
  payments = [],
  contractId,
  onChangePaymentsDateFilter,
  paymentsDateFilter,
}) => {
  const { t } = useTranslation('DASHBOARD');
  return (
    <div className="w-full rounded-md bg-purple bg-opacity-25 px-4 py-6">
      <div className="mb-4 flex items-center justify-between">
        <p className="font-regular text-base text-purple">
          {t('TRANSACTIONS')}
        </p>

        <div className="flex flex-row items-center gap-2">
          <button
            onClick={() => onChangePaymentsDateFilter(undefined)}
            className={classNames(
              'text-sm font-medium uppercase hover:text-violet',
              !paymentsDateFilter ? 'text-white underline' : 'text-purple'
            )}
          >
            {t('ALL_FILTER')}
          </button>
          <button
            onClick={() =>
              onChangePaymentsDateFilter(
                moment().subtract(3, 'months').toISOString()
              )
            }
            className={classNames(
              'text-sm font-medium uppercase hover:text-violet',
              paymentsDateFilter ? 'text-white underline' : 'text-purple'
            )}
          >
            {t('RECENT_FILTER')}
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {(!payments || !payments.length) && (
          <div className="rounded-sm bg-white py-4 px-3">
            {t('TRANSACTIONS:NO_TRANSACTIONS')}
          </div>
        )}
        {payments.map((payment, idx) => (
          <SingleTransaction payment={payment} key={`payment-${idx}`} />
        ))}
      </div>

      {!!payments && !!payments.length && (
        <div className="mt-4 ml-auto table">
          <Link
            to={
              contractId
                ? `/transaction-history/${contractId}`
                : '/transaction-history'
            }
          >
            <SmallWhiteButtonFull text={t('COMMON:SHOW_ALL_BTN')} />
          </Link>
        </div>
      )}
    </div>
  );
};

export default TransactionsBoxDashboard;
