import SumsubWebSdk from '@sumsub/websdk-react';
import { MessageHandler } from '@sumsub/websdk/types/types';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { BackButton } from '../../components/Buttons';
import Spinner from '../../components/Spinner';
import { me } from '../../lib/fetch/auth';
import { getVerificationToken } from '../../lib/fetch/kyc';
import { KYCStatus } from '../../lib/interfaces/IUser';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';

const Kyc = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [lang] = i18n.languages;
  const [locale, country] = lang.split('-');
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const [isWizardComplete, setIsWizardComplete] = useState(false);

  const refetchProfile = async () => {
    const { data: updatedUser } = await me(token!);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
  };

  const {
    data: { token: verificationToken } = {},
    isLoading,
    refetch: refetchToken,
  } = useQuery(['kyc', 'getToken'], () => getVerificationToken(token!), {
    enabled: !!token,
    refetchOnWindowFocus: false,
    onError: (error) => {
      console.log('Error getting kyc token', error);
    },
  });

  const tokenExpirationHandler = async () => {
    console.log('called expiration handler');
    const { data } = await refetchToken();
    if (!data) throw new Error('TOKEN_NOT_FOUND');
    console.log('got token', data);
    return data?.token;
  };

  const handleMessage: MessageHandler = (type, payload) => {
    switch (type as string) {
      case 'idCheck.applicantReviewComplete':
        setIsWizardComplete(true);
        refetchProfile();
        break;
    }
  };

  const isError = !isLoading && !verificationToken;
  const isCompleted =
    isWizardComplete || user?.kyc?.status === KYCStatus.SUCCESS;

  return (
    <div className="p-6">
      <div className="flex max-w-min" onClick={() => navigate(-1)}>
        <BackButton text={t('COMMON:BACK')} />
      </div>

      {isLoading ? (
        <div className="my-auto flex h-full w-full">
          <Spinner className="my-60 flex w-full text-purple" />
        </div>
      ) : (
        <>
          {!!verificationToken && (
            <SumsubWebSdk
              accessToken={verificationToken}
              expirationHandler={tokenExpirationHandler}
              config={{
                email: user?.email,
                phone: user?.phone,
                lang: locale,
                country,
              }}
              onMessage={handleMessage}
              onError={(error) => {
                console.error('sumsub error', error);
                dispatchAlertChange({
                  open: true,
                  type: AlertType.Error,
                  message: t('COMMON:SOMETHING_WENT_WRONG'),
                });
              }}
            />
          )}
          {isError && (
            <div className="mt-10 w-full text-center font-bold text-purple">
              Qualcosa è andato storto, prova a ricaricare la pagina o contatta
              il supporto tecnico
            </div>
          )}
          {isCompleted && (
            <div className="mt-12 flex w-full flex-col items-center gap-6">
              <Link
                to="/"
                className="font-bold text-purple underline underline-offset-1 hover:text-violet"
              >
                {t('LOGIN:GO_TO_DASHBOARD')}
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Kyc;
