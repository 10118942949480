import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { Link } from 'react-router-dom';
import { PrimaryButtonFull } from '../../components/Buttons';
import AppHead from '../../components/Headers/AppHead';
import { PaymentMethod } from '../../lib/constants/payments';
import { IWireTransferDataAttributes } from '../../lib/fetch/wireTransfer';
import piggyBankLottie from '../../lotties/piggy-bank-coin-in.json';

type Props = {
  paymentMethod?: PaymentMethod | null;
  wireTransferData?: IWireTransferDataAttributes;
};

const ThankYouPresentational = ({ wireTransferData, paymentMethod }: Props) => {
  const { t } = useTranslation('THANK_YOU');
  return (
    <>
      <AppHead name={t('HEAD_NAME')} />
      <div className="flex min-h-screen bg-gray-50 font-sans">
        <div className="flex w-full flex-col items-center justify-center px-12 pb-12">
          <div className="mx-auto max-w-3xl">
            <Lottie
              loop
              animationData={piggyBankLottie}
              play
              style={{ width: 250, height: 200 }}
              className="mx-auto"
            />

            <h1
              className="mb-8 text-center text-4xl font-bold text-violet"
              dangerouslySetInnerHTML={{
                __html: t('TITLE'),
              }}
            />

            {paymentMethod === PaymentMethod.WIRE_TRANSFER && (
              //TODO: uncomment when payment confirm email is handled
              /* <p
              className="mb-8 text-center text-sm text-gray-600"
              dangerouslySetInnerHTML={{
                __html: t('DESCRIPTION'),
              }}
            /> */

              <div className="mb-6">
                {/* <WireTransferBox wireTransferData={wireTransferData} /> */}
              </div>
            )}

            <Link to="/">
              <PrimaryButtonFull text={t('BACK_DASHBOARD_BTN')} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYouPresentational;
