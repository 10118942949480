import axios from 'axios';

const { REACT_APP_GET_VERIFICATION_URL } = process.env;

const getVerificationToken = async (token: string) => {
  const response = await axios.get<{ token: string }>(
    REACT_APP_GET_VERIFICATION_URL!,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export default getVerificationToken;
