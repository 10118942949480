import moment from 'moment';
import INews from '../../lib/interfaces/INews';

interface Props {
  singleNews: INews;
}

const SingleNewsCard: React.FC<Props> = ({ singleNews }) => {
  const { attributes = { title: '', description: '', date: '' } } = singleNews;
  const { title, description, date } = attributes;

  return (
    <div className="rounded-md bg-gray-100 p-4">
      <div className="mb-4 flex flex-col items-center justify-between sm:flex-row">
        <h4 className="text-lg font-semibold text-purple sm:text-left">
          {title}
        </h4>
        <p className="text-sm text-gray-500">
          {moment(date).format('DD/MM/YYYY hh:mm')}
        </p>
      </div>
      <p className="text-base font-normal text-violet">{description}</p>
    </div>
  );
};

export default SingleNewsCard;
