import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../lib/fetch/auth';
import { useAlert } from '../../providers/AlertProvider';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import LoginPresentational from './LoginPresentational';

export interface ILoginForm {
  identifier: string;
  password: string;
  remember: boolean;
}

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [, dispatchAlertChange] = useAlert();

  const [{ appLoaded, isAuthenticated }, dispatchAuthChange] = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(isAuthenticated && !appLoaded);
  }, [appLoaded, isAuthenticated]);

  const onLogin = async (formData: ILoginForm) => {
    const { identifier, password, remember } = formData;

    setLoading(true);
    const { error, data: loginResponse } = await login({
      identifier,
      password,
    });
    setLoading(false);
    const { jwt, user } = loginResponse || {};
    const { blocked } = user || {};

    if (error || blocked)
      return dispatchAlertChange({
        open: true,
        message: 'LOGIN:ERROR',
      });

    dispatchAuthChange({
      type: AuthActionType.Login,
      token: jwt,
      remember,
      user,
    });

    if (!user.emailConfirmed) {
      return navigate('/email-confirm', {
        state: { redirectTo: '/' },
      });
    }
    navigate('/verify-code');
  };

  return <LoginPresentational loading={loading} onLogin={onLogin} />;
};

export default Login;
