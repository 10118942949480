import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table';
import { NumberParam, useQueryParam } from 'use-query-params';
import { StrapiPagination } from '../../lib/interfaces/Generics';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from '../../lib/utils/classNames';
import NoData from './NoData';

interface Props<T> {
  data: T[];
  columns: ColumnDef<T>[];
  showPagination?: boolean;
  pagination?: StrapiPagination;
  className?: string;
}

const Table: React.FC<Props<any>> = ({
  data,
  columns,
  showPagination = false,
  pagination,
  className,
}) => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const { t } = useTranslation();

  const table = useReactTable({
    data,
    columns,
    pageCount: pagination?.pageCount,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    manualPagination: true,
    initialState: {
      pagination: {
        pageSize: pagination?.pageSize,
        pageIndex: pagination?.page,
      },
    },
  });

  if (data.length === 0) {
    return <NoData />;
  }

  return (
    <div className={classNames('flex h-full flex-col', className)}>
      <div className="relative flex-grow overflow-x-auto">
        <table className="w-full text-left text-sm text-violet rtl:text-right ">
          <thead className="text-xs uppercase text-violet ">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className="" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      scope="col"
                      className="px-3 py-1"
                      key={header.id}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr className="odd:bg-gray-200 even:bg-white" key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td className="px-3 py-4" key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showPagination && pagination && pagination.pageCount > 1 && (
        <div className="mt-auto flex w-full flex-row items-center justify-between gap-2 border-t pt-2">
          <div className="">
            {/* <PaginationButton
              onClick={() => setPage(1)}
              disabled={Number(page || 0) <= 1}
            >
              {'<<'}
            </PaginationButton> */}
            <PaginationButton
              onClick={() => setPage((page || 1) - 1)}
              disabled={Number(page || 0) <= 1}
            >
              {'<'}
            </PaginationButton>
          </div>
          <span className="flex items-center gap-1 text-violet">
            <div>
              {t('COMMON:PAGE_OF', {
                page: pagination.page,
                pageCount: pagination.pageCount,
              })}
            </div>
          </span>
          <div>
            <PaginationButton
              onClick={() => setPage(Number(page || 0) + 1)}
              disabled={Number(page) >= pagination.pageCount}
            >
              {'>'}
            </PaginationButton>
            {/* <PaginationButton
              onClick={() => setPage(pagination.pageCount)}
              disabled={Number(page) >= pagination.pageCount}
            >
              {'>>'}
            </PaginationButton> */}
          </div>
        </div>
      )}
    </div>
  );
};

const PaginationButton: React.FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = (props) => {
  return (
    <button
      {...props}
      className=" rounded-full p-4 py-2 hover:bg-gray-200 disabled:cursor-not-allowed disabled:bg-transparent disabled:text-gray-400"
    >
      {props.children}
    </button>
  );
};

export default Table;
