interface Props {
  label: string;
  value?: string | number | React.ReactElement | null;
}

const RowLabel: React.FC<Props> = ({ label, value }) => {
  return (
    <span className="flex flex-row justify-between">
      <p className="font-regular py-1 text-base text-violet">{label}</p>
      <p className="font-regular py-1 text-base font-bold text-violet">
        {value}
      </p>
    </span>
  );
};

export default RowLabel;
