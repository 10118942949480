import React from 'react';
import { useTranslation } from 'react-i18next';
import AppHead from '../../components/Headers/AppHead';
import HeaderPage from '../../components/Navigation/HederPage';
import Sidebar from '../../components/Navigation/Sidebar';
import { IProduct } from '../../lib/interfaces';
import ProductCard from './ProductCard';

interface Props {
  products: IProduct[];
}
const InfoProductPresentational: React.FC<Props> = ({ products = [] }) => {
  const { t } = useTranslation('PRODUCTS');

  return (
    <>
      <AppHead name={t('HEAD_NAME')} />
      <div className="flex h-full min-h-screen font-sans">
        <Sidebar />
        <div className="flex-1 lg:pl-52">
          <div className="py-10 px-6">
            <HeaderPage title={t('OUR_PRODUCTS')} />

            <div className="grid w-full grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
              {Array.isArray(products) &&
                products.map((product, idx) => (
                  <ProductCard product={product} key={`product-${idx}`} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoProductPresentational;
