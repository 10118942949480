import axios from 'axios';
import { IUser } from '../../interfaces';

export interface ILoginResponse {
  jwt: string;
  user: IUser;
}

const { REACT_APP_LOGIN_URL } = process.env;

interface ILoginRequest {
  identifier: string;
  password: string;
}

const login = async (credentials: ILoginRequest) => {
  const response = await axios.post<ILoginResponse>(
    REACT_APP_LOGIN_URL!,
    credentials
  );

  let error;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default login;
