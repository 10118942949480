import { PACFrequency } from '../interfaces';

export enum SubscriptionStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  CANCELED = 'CANCELED',
  ENDED = 'ENDED',
}

export const SUBSCRIPTION_DURATION_OPTIONS = Object.freeze([
  { name: '5 anni', value: '60' },
  { name: '10 anni', value: '120' },
  { name: '15 anni', value: '180' },
]);

export const SUBSCRIPTION_FREQUENCY_OPTIONS = Object.freeze([
  { name: 'Mensile', value: PACFrequency.MONTHLY },
  { name: 'Trimestrale', value: PACFrequency.QUARTERLY },
  { name: 'Semestrale', value: PACFrequency.HALF_YEARLY },
  { name: 'Annuale', value: PACFrequency.ANNUAL },
]);
