import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../../components/Buttons';
import AppHead from '../../components/Headers/AppHead';
import HeaderPage from '../../components/Navigation/HederPage';
import Pagination from '../../components/Navigation/Pagination';
import Sidebar from '../../components/Navigation/Sidebar';
import SingleTransactionBig from '../../components/VariusElements/SingleTransactionBig';
import { IPayment } from '../../lib/interfaces';

interface Props {
  payments?: IPayment[];
  getCheckoutUrl: (id: number) => void;
  currentPage: number;
  pageCount: number;
  onChangePage: (page: number) => void;
}

const TransactionHistoryPresentational: React.FC<Props> = ({
  payments = [],
  getCheckoutUrl,
  currentPage,
  pageCount,
  onChangePage = () => {},
}) => {
  const { t } = useTranslation('TRANSACTIONS');
  const navigate = useNavigate();

  return (
    <>
      <AppHead name={t('HEAD_NAME')} />
      <div className="flex h-full min-h-screen font-sans">
        <Sidebar />
        <div className="flex-1 lg:pl-52">
          <div className="flex h-full flex-col px-6 pt-10">
            <HeaderPage title={t('TRANSACTION_HISTORY')} />

            <BackButton onClick={() => navigate(-1)} text={t('COMMON:BACK')} />

            <div className="flex w-full flex-col gap-4">
              {payments.map((payment, idx) => (
                <SingleTransactionBig
                  payment={payment}
                  key={`payment-${idx}`}
                  getCheckoutUrl={getCheckoutUrl}
                />
              ))}
            </div>

            <div className="mt-auto">
              <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                onChangePage={onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionHistoryPresentational;
