import _ from 'lodash';

const getObjectPaths = (o: Record<string, any>) => {
  return _.transform(
    o,
    (acc: any[], v: any, k: any) => {
      const keys: any =
        _.isObject(v) && !_.isEmpty(v) && !(v instanceof File)
          ? _.map(getObjectPaths(v), (sk) => _.concat(k, ...sk))
          : [[k]];

      acc.push(...keys);
    },
    []
  );
};

export default getObjectPaths;
