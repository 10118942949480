import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { ChangeEventHandler, ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from '../../lib/utils/classNames';

interface ISelectPresentational {
  label: string;
  id: string;
  name: string;
  autoComplete?: string;
  hasError?: boolean;
  helperText?: string;
  disabled?: boolean;
  options: readonly { name: string; value: string }[];
  hasEmptyOption?: boolean;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  value?: string | number | readonly string[] | undefined;
}

const SelectPresentational = forwardRef(
  (
    {
      label,
      id,
      name,
      autoComplete,
      onChange,
      value,
      hasError,
      helperText,
      disabled = false,
      options = [],
      hasEmptyOption = false,
      ...rest
    }: ISelectPresentational,
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    const { t } = useTranslation();

    return (
      <div>
        <label className="block font-sans text-base font-normal text-purple">
          {label}
        </label>
        <div className="relative mt-1">
          <select
            ref={ref}
            id={id}
            name={name}
            autoComplete={autoComplete}
            disabled={disabled}
            className={classNames(
              'mt-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-base font-normal placeholder-gray-400 focus:border-purple focus:outline-none focus:ring-0',
              disabled
                ? 'cursor-not-allowed opacity-50'
                : 'cursor-auto opacity-100'
            )}
            {...rest}
            onChange={onChange}
            value={value}
          >
            {hasEmptyOption && (
              <option value="" disabled>
                ---
              </option>
            )}
            {options.map(({ name, value }, index) => (
              <option value={value} key={index}>
                {t(name)}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {hasError && (
              <ExclamationCircleIcon
                className="m-5 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            )}
          </div>
        </div>
        {helperText && (
          <p
            className={`mt-2 text-sm text-${hasError ? 'red-600' : 'gray-500'}`}
            id={`${id}-${hasError ? 'error' : 'helper'}`}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  }
);

export default SelectPresentational;
