import axios from 'axios';

export const downloadFile = async ({
  url,
  fileName,
}: {
  url?: string;
  fileName: string;
}) => {
  const response = await axios({
    url: url + '', //your url
    method: 'GET',
    responseType: 'blob', // important
  });

  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName + ''); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
