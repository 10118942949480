import { To, useNavigate } from 'react-router-dom';
import { useAlert } from '../../providers/AlertProvider';

interface Props {
  message: string;
  redirect?: To;
}

const UnauthorizedPage: React.FC<Props> = ({ message, redirect }) => {
  const navigate = useNavigate();
  const [, dispatchAlertChange] = useAlert();

  dispatchAlertChange({
    open: true,
    message,
  });
  navigate(redirect || '/');

  return null;
};
export default UnauthorizedPage;
