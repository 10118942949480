export enum ContractStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',

  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED',
}

export const contractStatus = Object.freeze(
  Object.keys(ContractStatus).map((k) => ({
    name: `MY_CONTRACTS:CONTRACT_${k}`,
    value: k,
  }))
);
