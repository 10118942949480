import VerticalNavigation from './VerticalNavigation';

export default function Sidebar() {
  return (
    <div className="fixed z-10 hidden w-52 lg:inline">
      <div className="flex h-screen flex-1 flex-col bg-purple">
        <VerticalNavigation />
      </div>
    </div>
  );
}
