import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InputPresentational from '../../components/Form/InputPresentational';
import PrimaryButtonFull from '../../components/Buttons/PrimaryButtonFull';
import { FixedAlert } from '../../components/Alert';
import BackButton from '../../components/Buttons/BackButton';
import { AlertType } from '../../providers/AlertProvider';
import { IResetForm } from './index';
import BgLogin from '../../components/VariusElements/BgLogin';
import RespLogoLogin from '../../components/VariusElements/RespLogoLogin';
import AppHead from '../../components/Headers/AppHead';

interface IResetPresentational {
  loading: boolean;
  result?: AlertType;
  resultTitle?: string;
  resultDescription?: string;
  onReset: (formData: IResetForm) => void;
}

const ResetPresentational = (props: IResetPresentational) => {
  const {
    loading = false,
    result,
    resultTitle,
    resultDescription,
    onReset = (_formData: IResetForm) => {},
  } = props;
  const { t } = useTranslation('RESET');
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<IResetForm>();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const navigate = useNavigate();

  useEffect(() => {
    code && setValue('code', code);
  }, [code]);

  return (
    <>
      <AppHead name="Reset Password" />
      <div className="flex min-h-screen font-sans">
        <BgLogin />

        <div className="relative flex w-3/5 flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <RespLogoLogin />
          <div className="mx-auto w-full max-w-xl">
            <div className="mb-10">
              <BackButton
                text={t('FORGOT:BACK_LOGIN')}
                onClick={() => navigate('/login')}
              />
            </div>
            <h2 className="text-3xl font-bold text-purple">{t('RESET')}</h2>
            <p className="mt-2 text-sm text-gray-600">{t('DESCRIPTION')}</p>

            <div className="mt-8 mb-6">
              <form onSubmit={handleSubmit(onReset)}>
                <div className="mb-6 grid grid-cols-1 gap-6">
                  <InputPresentational
                    label={t('NEW_PASSWORD')}
                    id="password"
                    type="password"
                    autoComplete="new-password"
                    {...register('password', {
                      required: t('COMMON:REQUIRED') as string,
                      minLength: {
                        message: t('VALIDATION_MIN') as string,
                        value: 6,
                      },
                      maxLength: {
                        message: t('VALIDATION_MAX') as string,
                        value: 20,
                      },
                    })}
                    hasError={!!errors.password}
                    helperText={errors.password?.message}
                  />
                  <InputPresentational
                    label={t('REPEAT_PASSWORD')}
                    id="passwordConfirmation"
                    type="password"
                    autoComplete="new-password"
                    {...register('passwordConfirmation', {
                      required: t('COMMON:REQUIRED') as string,
                      validate: (passwordConfirmation: string) => {
                        return (
                          passwordConfirmation === getValues('password') ||
                          (t('VALIDATION_CONFIRMATION') as string)
                        );
                      },
                    })}
                    hasError={!!errors.passwordConfirmation}
                    helperText={errors.passwordConfirmation?.message}
                  />
                </div>

                <div className="mx-auto w-3/5 lg:mr-auto lg:ml-0">
                  <PrimaryButtonFull
                    type="submit"
                    loading={loading}
                    text={t('RESET')}
                  />
                </div>
              </form>
            </div>
            <FixedAlert
              type={result}
              title={resultTitle}
              description={resultDescription}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPresentational;
