import { useTranslation } from 'react-i18next';
// import TrendLabel from '../Labels/TrendLabel';
import Spinner from '../Spinner';
import InfoBox from './InfoBox';
import LineChartDashboard from './LineChartDashboard';
import TabsChartFilter from './TabsChartFilter';
import { ChartFilters } from '../../pages/Dashboard';
import { IProductChartRecord } from '../../lib/interfaces/IProduct';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import { ProductStatistics } from '../../lib/interfaces/IStatistics';
import { IContractConfirmed } from '../../lib/interfaces';
import { ProductType } from '../../lib/constants/payments';
import Table from './Table';
import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { CouponBond } from '../../lib/interfaces/ICouponBonds';
import { StrapiData, StrapiResponseList } from '../../lib/interfaces/Generics';
import moment from 'moment';
import currency from '../../lib/utils/currency';
import InfoContainer from './InfoContainer';

interface Props {
  chartFilters: ChartFilters;
  onChangeChartFilters: React.Dispatch<React.SetStateAction<ChartFilters>>;
  onChangeChartFilter: (
    filter: 'from' | 'to'
  ) => (ev: React.ChangeEvent<HTMLInputElement>) => void;
  contract: IContractConfirmed | undefined;
  contractChartQuery: UseQueryResult<
    {
      error: AxiosResponse<any, any> | undefined;
      data: IProductChartRecord[];
      status: number;
      statusText: string;
      headers: AxiosResponseHeaders;
      request?: any;
    } | null,
    any
  >;
  chartData: IProductChartRecord[];
  couponBondsData: StrapiResponseList<CouponBond> | undefined;
  contractStatsQuery: Partial<
    UseQueryResult<
      {
        error: AxiosResponse<any, any> | undefined;
        data: ProductStatistics;
        status: number;
        statusText: string;
        headers: AxiosResponseHeaders;
      },
      unknown
    >
  >;
}

const ContractChart: React.FC<Props> = ({
  chartFilters,
  onChangeChartFilters,
  contractChartQuery,
  onChangeChartFilter,
  chartData,
  contractStatsQuery,
  contract,
  couponBondsData,
}) => {
  const { t } = useTranslation('DASHBOARD');

  const couponBondsColumns = useMemo<ColumnDef<StrapiData<CouponBond>>[]>(
    () => [
      {
        accessorKey: 'attributes.date',
        cell: (row) => (
          <div>{moment(row.getValue() + '').format('DD/MM/YYYY')}</div>
        ),
        id: 'id',
        header: () => <span>Data</span>,
      },
      {
        accessorKey: 'attributes.amount',
        cell: (row) => <div>{currency(row.getValue() as number)}</div>,
        header: () => <span>Importo</span>,
      },
    ],
    []
  );

  const KPIColumns = useMemo<ColumnDef<StrapiData<CouponBond>>[]>(
    () => [
      {
        accessorKey: 'date',
        cell: (row) => (
          <div>{moment(row.getValue() + '').format('DD/MM/YYYY')}</div>
        ),
        id: 'id',
        header: () => <span>Data</span>,
      },
      {
        accessorKey: 'value',
        cell: (row) => <div>{currency(row.getValue() as number)}</div>,
        header: () => <span>Valore</span>,
      },
    ],
    []
  );

  if (!contract) return <></>;

  if (contract?.attributes.product.data.attributes.type === ProductType.STATIC)
    return (
      <main className="mb-6 flex w-full flex-col gap-4 md:flex-row lg:col-span-8 lg:justify-between">
        {contract.attributes.product.data.attributes.couponBond && (
          <InfoContainer className="w-1/2" title={t('BOUND_COUPONS_LIST')}>
            <Table
              {...{
                data: couponBondsData?.data || [],
                columns: couponBondsColumns,
                showPagination: true,
                pagination: couponBondsData?.meta.pagination,
              }}
              className="py-4"
            />
          </InfoContainer>
        )}
        <InfoContainer className="w-1/2" title={t('LAST_KPIS')}>
          <Table
            {...{
              data:
                contract.attributes.product.data.attributes.marketValues
                  .sort(
                    (a, b) =>
                      new Date(b.date).getTime() - new Date(a.date).getTime()
                  )
                  .slice(0, 10) || [],
              columns: KPIColumns,
            }}
            className="py-4"
          />
        </InfoContainer>
      </main>
    );

  return (
    <aside className="col-span-12 lg:col-span-8 xl:block">
      <div className="w-full rounded-md border border-gray-200 px-4 py-6">
        <h3 className="mb-1 text-xl font-medium text-gray-800 md:text-3xl">
          {t('TREND_TITLE')}
        </h3>
        <div className="flex flex-col items-center justify-between gap-3 md:flex-row">
          <TabsChartFilter
            chartFilters={chartFilters}
            onChangeChartFilters={onChangeChartFilters}
          />
          <div className="flex items-center gap-3">
            <label className="text-xs font-normal text-gray-800">
              {t('COMMON:FROM')}:
            </label>
            <input
              type="date"
              value={chartFilters.from?.toISOString().slice(0, 10) || ''}
              onChange={onChangeChartFilter('from')}
              className="block rounded-md border-transparent bg-gray-200 py-2 px-3 text-xs text-gray-800 focus:border-purple focus:outline-none focus:ring-0"
              placeholder="Seleziona una data"
            />
            <label className="text-xs font-normal text-gray-800">
              {t('COMMON:TO')}
            </label>
            <input
              type="date"
              value={chartFilters.to?.toISOString().slice(0, 10) || ''}
              onChange={onChangeChartFilter('to')}
              className="block rounded-md border-transparent bg-gray-200 py-2 px-3 text-xs text-gray-800 focus:border-purple focus:outline-none focus:ring-0"
              placeholder="Seleziona una data"
            />
          </div>
        </div>

        {contractChartQuery.data?.data && (
          <div className="my-4 mt-4 flex flex-row justify-between align-middle">
            <InfoBox
              type="warning"
              description={t('DASHBOARD:NO_CHART_DATA')}
              className="py-2"
            />
            <div className="my-auto mb-4 flex items-center justify-center gap-4 md:justify-end">
              {/* <TrendLabel
                price={Number(
                  chartData[chartData.length - 1]?.variation?.toFixed(2)
                ).toLocaleString('it')}
              /> */}
              <p className="text-lg font-bold text-gray-800">
                {Number(
                  contractStatsQuery.data?.data?.actualValue?.toFixed(2)
                ).toLocaleString('it')}{' '}
                <span className="font-normal uppercase">eur</span>
              </p>
            </div>
          </div>
        )}

        <div className="flex h-full w-full">
          {contractChartQuery.data && !contractChartQuery.isFetching && (
            <LineChartDashboard data={chartData} />
          )}
          {contractChartQuery.isFetching && (
            <div className="my-auto flex h-full w-full">
              <Spinner className="my-60 flex w-full text-purple" />
            </div>
          )}
        </div>
      </div>
    </aside>
  );
};

export default ContractChart;
