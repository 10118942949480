import axios, { AxiosResponse } from 'axios';
import { IMeta, IPayment } from '../../interfaces';
import qs from 'qs';

const { REACT_APP_PAYMENTS_URL } = process.env;

export interface IPaymentResponse {
  data: IPayment[];
  meta: IMeta;
}

const list = async ({
  token,
  userId,
  contractId,
  page,
  limit,
  createdAt,
}: {
  token: string;
  userId: number;
  contractId?: number;
  page: number;
  limit: number;
  createdAt?: string;
}) => {
  const filters = {
    contract: { user: userId, id: contractId },
  } as {
    contract: {
      user: number;
      id: number;
    };
    createdAt?: any;
  };

  const pagination = {
    page,
    pageSize: limit,
  };

  if (createdAt) {
    filters.createdAt = { $gte: createdAt };
  }
  const query = {
    filters,
    pagination,
    sort: ['createdAt:desc'],
  };

  const response = await axios.get<IPaymentResponse>(
    `${REACT_APP_PAYMENTS_URL}?${qs.stringify(query)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default list;
