import { ArrowLeftIcon } from '@heroicons/react/outline';

const BackButton = ({
  text,
  onClick
}: {
  text: string;
  onClick?: () => any;
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className="mb-6 inline-flex items-center border border-transparent bg-white text-sm font-bold text-violet hover:text-purple focus:outline-none"
    >
      <ArrowLeftIcon className="mr-3 h-5 w-5" />
      {text}
    </button>
  );
};

export default BackButton;
