import axios, { AxiosResponse } from 'axios';
import IFaq from '../../interfaces/IFaq';

const { REACT_APP_FAQS_URL } = process.env;

const list = async (token: string) => {
  const response = await axios.get<{ data: IFaq[] }>(REACT_APP_FAQS_URL!, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default list;
