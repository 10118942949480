import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IVerifyCodeForm } from '.';
import { PrimaryButtonFull } from '../../components/Buttons';
import InputPresentational from '../../components/Form/InputPresentational';
import AppHead from '../../components/Headers/AppHead';
import BgLogin from '../../components/VariusElements/BgLogin';
import RespLogoLogin from '../../components/VariusElements/RespLogoLogin';
import { useAuth } from '../../providers/AuthProvider';

interface IVerifyOTPPresentational {
  loading: boolean;
  onSubmit: (_: IVerifyCodeForm) => void;
}

const ConfirmEmailPresentational: React.FC<IVerifyOTPPresentational> = (
  props
) => {
  const { loading = false, onSubmit = () => {} } = props;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IVerifyCodeForm>();

  const { t } = useTranslation('LOGIN');
  const [{ user }] = useAuth();
  return (
    <>
      <AppHead name={t('HEAD_NAME_CONFIRMATION_EMAIL')} />
      <div className="flex min-h-screen font-sans">
        <BgLogin />
        <div className="relative flex w-3/5 flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <RespLogoLogin />

          <div className="mx-auto w-full max-w-xl">
            <h2 className="text-3xl font-bold text-purple">
              {t('EMAIL_CONFIRM')}
            </h2>
            <p className="mt-2 text-base text-gray-800">
              {t('EMAIL_CONFIRM_DESCRIPTION_1')}
              {user?.superUserEmail || user?.email}
              {t('EMAIL_CONFIRM_DESCRIPTION_2')}
            </p>

            <div className="mt-8">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 gap-6">
                  <InputPresentational
                    label={t('CODE')}
                    id="code"
                    {...register('code', {
                      required: t('COMMON:REQUIRED') as string,
                    })}
                    type="text"
                    autoComplete="false"
                    hasError={!!errors.code}
                    helperText={errors.code?.message}
                  />
                </div>

                <div className="mx-auto mt-12 w-3/5 lg:mr-auto lg:ml-0">
                  <PrimaryButtonFull
                    text={t('COMMON:CONTINUE')}
                    loading={loading}
                    disabled={loading}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmEmailPresentational;
