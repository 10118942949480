import axios, { AxiosResponse } from 'axios';
import { IMeta } from '../../interfaces';
import INews from '../../interfaces/INews';
import qs from 'qs';

const { REACT_APP_NEWS_URL } = process.env;

export interface INewsResponse {
  data: INews[];
  meta: IMeta;
}

const list = async ({ token, page }: { token: string; page: number }) => {
  const pagination = {
    page,
    pageSize: 10,
  };
  const query = {
    pagination,
    sort: ['date:desc'],
  };
  const response = await axios.get<INewsResponse>(
    `${REACT_APP_NEWS_URL!}?${qs.stringify(query)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default list;
