import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import {
  HashRouter,
  Location,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { QueryParamProvider } from 'use-query-params';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './i18n';
import { AlertProvider } from './providers/AlertProvider';
import { AuthProvider } from './providers/AuthProvider';
import App from './App';
import Alert from './components/Alert';
import reportWebVitals from './reportWebVitals';
import './index.css';

const queryClient = new QueryClient();
/**
 * This is the main thing you need to use to adapt the react-router v6
 * API to what use-query-params expects.
 *
 * Pass this as the `ReactRouterRoute` prop to QueryParamProvider.
 */
const RouteAdapter = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      replace(location: Location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: Location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
};

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <AuthProvider>
          <AlertProvider>
            <HashRouter>
              <QueryParamProvider ReactRouterRoute={RouteAdapter as any}>
                <App />
                <Alert />
              </QueryParamProvider>
            </HashRouter>
          </AlertProvider>
        </AuthProvider>
      </CookiesProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
