import { DocumentType, Gender } from '../constants/survey';
import IStrapiFile from './IStrapiFile';
import IUserAssessment from './IUserAssessment';

export enum KYCStatus {
  INIT = 'INIT',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL', // Tentativo fallito utente può riprovare
  REJECT = 'REJECT', // Verifica fallita l'utente non può riprovare (es: frode, spam, necessario contattare assistenza)
}

export interface IUserKYC {
  id: number;
  sessionId: string;
  completed: boolean;
  status: KYCStatus;
  data: any;
  createdAt: string;
  updatedAt: string;
  documentExpirationDate: string;
  documentIssueDate: string;
  documentNumber: string;
  documentType: DocumentType;
  documentCountry: string;
  documentIssueEntity: string;
  documentImages: IStrapiFile[] | null;
}

export default interface IUser {
  // Strapi's properties for users:
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  createdAt: string;
  updatedAt: string;
  // Custom properties for users:
  uuid: string;
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  citizenship?: string;
  fiscalCode?: string;
  phone?: string;
  phone2?: string;
  birthDate?: string;
  birthCountry?: string;
  birthDistrict?: string;
  birthCity?: string;
  address?: string;
  houseNumber?: string;
  postalCode?: string;
  country?: string;
  district?: string;
  city?: string;
  conventionCode?: string;
  termsConditions?: boolean;
  onboardingStep: number;
  emailConfirmed: boolean;
  phoneConfirmed: boolean;
  kyc: IUserKYC | null;
  user_assessment: IUserAssessment | null;
  personalDocuments: IStrapiFile[] | null;
  contractualDocuments: IStrapiFile[] | null;
  residentialDocuments: IStrapiFile[] | null;
  onboardingDone: boolean;
  superUserEmail: string;
}
