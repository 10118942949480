import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IContractPayload, IStrapiFile } from '../../../lib/interfaces';
import DragAndDrop from '../../../components/Form/DragAndDrop';
import FileUploadPrev from '../../../components/Form/FileUploadPrev';
import WireTransferBox from '../../../components/VariusElements/WireTransferBox';
import { IWireTransferDataAttributes } from '../../../lib/fetch/wireTransfer';

interface Props {
  files: IStrapiFile[];
  onFileDelete: (_: IStrapiFile) => Promise<void>;
  disabled: boolean;
  wireTransferData?: IWireTransferDataAttributes;
}

const Step6: React.FC<Props> = (props) => {
  const { t } = useTranslation('SURVEY');
  const { files = [], onFileDelete, disabled, wireTransferData } = props;

  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<IContractPayload>();

  const formFiles = watch('contractData.bankData.transferFiles', []);

  return (
    <div className="pt-6 pb-12 md:px-10">
      <h2 className="mb-6 text-3xl font-bold text-purple">
        {t('CONTRACT.STEP6_TITLE')}
      </h2>
      <div className="mt-10 grid grid-rows-1 gap-6 md:grid-rows-2  lg:grid-rows-2">
        <div className="mb-6">
          <WireTransferBox wireTransferData={wireTransferData} />
        </div>
        <div>
          {!disabled && (
            <>
              <label className="block font-sans text-base font-normal text-purple">
                Distinta bonifico
              </label>
              <DragAndDrop
                maxFiles={1}
                acceptedFiles={['image/png', 'image/jpeg', 'application/pdf']}
                onFileChange={async (files) =>
                  setValue('contractData.bankData.transferFiles', files)
                }
                {...register('contractData.bankData.transferFiles', {
                  required: t('COMMON:REQUIRED') as string,
                })}
              />
              {errors.contractData?.bankData?.transferFiles && (
                <p className={`mt-2 text-sm text-red-600`}>
                  {
                    (
                      errors.contractData?.bankData
                        ?.transferFiles as unknown as FieldError
                    ).message
                  }
                </p>
              )}
            </>
          )}

          <div className="mt-8 flex flex-col gap-4">
            {files.map((file, index) => (
              <FileUploadPrev
                key={index}
                file={file}
                onDelete={(file) => onFileDelete(file as IStrapiFile)}
                disabled={disabled}
              />
            ))}
            {formFiles?.map((file, index) =>
              file instanceof File ? (
                <FileUploadPrev
                  key={index}
                  file={file}
                  onDelete={async () =>
                    setValue(
                      'contractData.bankData.transferFiles',
                      formFiles.filter((_, idx) => index !== idx)
                    )
                  }
                />
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step6;
