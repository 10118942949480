export default function StaticInput({
  title,
  value,
}: {
  title: string | JSX.Element;
  value: string | JSX.Element;
}) {
  return (
    <div>
      <p className="block font-sans text-sm font-normal text-violet">{title}</p>
      <p className="text-base font-semibold text-purple">{value}</p>
    </div>
  );
}
