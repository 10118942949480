// import { Fragment, useState } from 'react';
// import { Dialog, Transition } from '@headlessui/react';
// import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicyModal() {
  // const [open, setOpen] = useState(false);
  const { t } = useTranslation('SETTINGS');

  return (
    <a
      href={process.env.PUBLIC_URL + '/documents/PrivacyPolicy.pdf'}
      target="_blank"
      rel="noopener noreferrer"
      className="text-sm font-medium text-violet hover:cursor-pointer hover:text-purple"
    >
      {t('COMMON:VIEW')}
    </a>
  );

  // return (
  //   <>
  //     <button
  //       className="text-sm font-medium text-violet hover:cursor-pointer hover:text-purple"
  //       onClick={() => setOpen(true)}
  //     >
  //       {t('COMMON:VIEW')}
  //     </button>

  //           <span
  //             className="hidden sm:inline-block sm:h-screen sm:align-middle"
  //             aria-hidden="true"
  //           >
  //             &#8203;
  //           </span>
  //           <Transition.Child
  //             as={Fragment}
  //             enter="ease-out duration-300"
  //             enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  //             enterTo="opacity-100 translate-y-0 sm:scale-100"
  //             leave="ease-in duration-200"
  //             leaveFrom="opacity-100 translate-y-0 sm:scale-100"
  //             leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  //           >
  //             <div className="inline-block w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-5xl sm:p-6 sm:align-middle">
  //               <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
  //                 <button
  //                   type="button"
  //                   className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
  //                   onClick={() => setOpen(false)}
  //                 >
  //                   <span className="sr-only">Close</span>
  //                   <XIcon className="h-6 w-6" aria-hidden="true" />
  //                 </button>
  //               </div>
  //               <div className="w-full text-left">
  //                 <Dialog.Title
  //                   as="h3"
  //                   className="text-lg font-medium leading-6 text-gray-900"
  //                 >
  //                   {t('PRIVACY:HEADING')}
  //                 </Dialog.Title>
  //                 <div className="mt-6">
  //                   <p
  //                     className="mb-6 text-sm text-gray-500"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:INTRODUCTION'),
  //                     }}
  //                   />

  //                   <h4
  //                     className="mb-2 font-medium text-violet"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:DEFINITIONS_TITLE'),
  //                     }}
  //                   />
  //                   <p
  //                     className="mb-6 text-sm text-gray-500"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:DEFINITIONS_DESCRIPTION'),
  //                     }}
  //                   />

  //                   <h4
  //                     className="mb-2 font-medium text-violet"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:DATA_CONTROLLER_TITLE'),
  //                     }}
  //                   />
  //                   <p
  //                     className="mb-6 text-sm text-gray-500"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:DATA_CONTROLLER_DESCRIPTION'),
  //                     }}
  //                   />

  //                   <h4
  //                     className="mb-2 font-medium text-violet"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:PERSONAL_DATA_TITLE'),
  //                     }}
  //                   />
  //                   <p
  //                     className="mb-6 text-sm text-gray-500"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:PERSONAL_DATA_DESCRIPTION'),
  //                     }}
  //                   />

  //                   <h4
  //                     className="mb-2 font-medium text-violet"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:TREATMENT_PURPOSE_TITLE'),
  //                     }}
  //                   />

  //                   <p
  //                     className="mb-6 text-sm text-gray-500"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:TREATMENT_PURPOSE_DESCRIPTION'),
  //                     }}
  //                   />

  //                   <h4
  //                     className="mb-2 font-medium text-violet"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:DATA_RETENTION_PERIOD_TITLE'),
  //                     }}
  //                   />

  //                   <p
  //                     className="mb-6 text-sm text-gray-500"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:DATA_RETENTION_PERIOD_DESCRIPTION'),
  //                     }}
  //                   />

  //                   <h4
  //                     className="mb-2 font-medium text-violet"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:PERSONAL_DATA_COMUNICATION_TITLE'),
  //                     }}
  //                   />

  //                   <p
  //                     className="mb-6 text-sm text-gray-500"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t(
  //                         'PRIVACY:PERSONAL_DATA_COMUNICATION_DESCRIPTION'
  //                       ),
  //                     }}
  //                   />

  //                   <h4
  //                     className="mb-2 font-medium text-violet"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:PERSONAL_DATA_TRANSFER_TITLE'),
  //                     }}
  //                   />

  //                   <p
  //                     className="mb-6 text-sm text-gray-500"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:PERSONAL_DATA_TRANSFER_DESCRIPTION'),
  //                     }}
  //                   />

  //                   <h4
  //                     className="mb-2 font-medium text-violet"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:INTERESTED_PARTIES_RIGHTS_TITLE'),
  //                     }}
  //                   />

  //                   <p
  //                     className="mb-6 text-sm text-gray-500"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t(
  //                         'PRIVACY:INTERESTED_PARTIES_RIGHTS_DESCRIPTION'
  //                       ),
  //                     }}
  //                   />

  //                   <h4
  //                     className="mb-2 font-medium text-violet"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:PRIVACY_POLICY_CHANGES_TITLE'),
  //                     }}
  //                   />

  //                   <p
  //                     className="mb-6 text-sm text-gray-500"
  //                     dangerouslySetInnerHTML={{
  //                       __html: t('PRIVACY:PRIVACY_POLICY_CHANGES_DESCRIPTION'),
  //                     }}
  //                   />
  //                 </div>
  //               </div>
  //             </div>
  //           </Transition.Child>
  //         </div>
  //       </Dialog>
  //     </Transition.Root>
  //   </>
  // );
  //     <Transition.Root show={open} as={Fragment}>
  //       <Dialog
  //         as="div"
  //         className="fixed inset-0 z-10 overflow-y-auto font-sans"
  //         onClose={setOpen}
  //       >
  //         <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
  //           <Transition.Child
  //             as={Fragment}
  //             enter="ease-out duration-300"
  //             enterFrom="opacity-0"
  //             enterTo="opacity-100"
  //             leave="ease-in duration-200"
  //             leaveFrom="opacity-100"
  //             leaveTo="opacity-0"
  //           >
  //             <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  //           </Transition.Child>

  //           <span
  //             className="hidden sm:inline-block sm:h-screen sm:align-middle"
  //             aria-hidden="true"
  //           >
  //             &#8203;
  //           </span>
  //           <Transition.Child
  //             as={Fragment}
  //             enter="ease-out duration-300"
  //             enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  //             enterTo="opacity-100 translate-y-0 sm:scale-100"
  //             leave="ease-in duration-200"
  //             leaveFrom="opacity-100 translate-y-0 sm:scale-100"
  //             leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  //           >
  //             <div className="inline-block w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-5xl sm:p-6 sm:align-middle">
  //               <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
  //                 <button
  //                   type="button"
  //                   className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
  //                   onClick={() => setOpen(false)}
  //                 >
  //                   <span className="sr-only">Close</span>
  //                   <XIcon className="h-6 w-6" aria-hidden="true" />
  //                 </button>
  //               </div>
  //               <div className="w-full text-left">
  //                 <Dialog.Title
  //                   as="h3"
  //                   className="text-lg font-medium leading-6 text-gray-900"
  //                 >
  //                   {t('PRIVACY_POLICY')}
  //                 </Dialog.Title>
  //                 <div className="mt-6">
  //                   <h4 className="mb-2 font-medium text-violet">
  //                     Il passaggio standard del Lorem Ipsum, utilizzato sin dal
  //                     sedicesimo secolo
  //                   </h4>
  //                   <p className="mb-6 text-sm text-gray-500">
  //                     Lorem ipsum dolor sit amet, consectetur adipiscing elit,
  //                     sed do eiusmod tempor incididunt ut labore et dolore magna
  //                     aliqua. Ut enim ad minim veniam, quis nostrud exercitation
  //                     ullamco laboris nisi ut aliquip ex ea commodo consequat.
  //                     Duis aute irure dolor in reprehenderit in voluptate velit
  //                     esse cillum dolore eu fugiat nulla pariatur. Excepteur
  //                     sint occaecat cupidatat non proident, sunt in culpa qui
  //                     officia deserunt mollit anim id est laborum.
  //                   </p>

  //                   <h4 className="mb-2 font-medium text-violet">
  //                     La sezione 1.10.32 del "de Finibus Bonorum et Malorum",
  //                     scritto da Cicerone nel 45 AC
  //                   </h4>
  //                   <p className="mb-6 text-sm text-gray-500">
  //                     Sed ut perspiciatis unde omnis iste natus error sit
  //                     voluptatem accusantium doloremque laudantium, totam rem
  //                     aperiam, eaque ipsa quae ab illo inventore veritatis et
  //                     quasi architecto beatae vitae dicta sunt explicabo. Nemo
  //                     enim ipsam voluptatem quia voluptas sit aspernatur aut
  //                     odit aut fugit, sed quia consequuntur magni dolores eos
  //                     qui ratione voluptatem sequi nesciunt. Neque porro
  //                     quisquam est, qui dolorem ipsum quia dolor sit amet,
  //                     consectetur, adipisci velit, sed quia non numquam eius
  //                     modi tempora incidunt ut labore et dolore magnam aliquam
  //                     quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
  //                     exercitationem ullam corporis suscipit laboriosam, nisi ut
  //                     aliquid ex ea commodi consequatur? Quis autem vel eum iure
  //                     reprehenderit qui in ea voluptate velit esse quam nihil
  //                     molestiae consequatur, vel illum qui dolorem eum fugiat
  //                     quo voluptas nulla pariatur?
  //                   </p>

  //                   <h4 className="mb-2 font-medium text-violet">
  //                     Traduzione del 1914 di H. Rackham
  //                   </h4>
  //                   <p className="mb-6 text-sm text-gray-500">
  //                     But I must explain to you how all this mistaken idea of
  //                     denouncing pleasure and praising pain was born and I will
  //                     give you a complete account of the system, and expound the
  //                     actual teachings of the great explorer of the truth, the
  //                     master-builder of human happiness. No one rejects,
  //                     dislikes, or avoids pleasure itself, because it is
  //                     pleasure, but because those who do not know how to pursue
  //                     pleasure rationally encounter consequences that are
  //                     extremely painful. Nor again is there anyone who loves or
  //                     pursues or desires to obtain pain of itself, because it is
  //                     pain, but because occasionally circumstances occur in
  //                     which toil and pain can procure him some great pleasure.
  //                     To take a trivial example, which of us ever undertakes
  //                     laborious physical exercise, except to obtain some
  //                     advantage from it? But who has any right to find fault
  //                     with a man who chooses to enjoy a pleasure that has no
  //                     annoying consequences, or one who avoids a pain that
  //                     produces no resultant pleasure?
  //                   </p>

  //                   <h4 className="mb-2 font-medium text-violet">
  //                     La sezione 1.10.33 del "de Finibus Bonorum et Malorum",
  //                     scritto da Cicerone nel 45 AC
  //                   </h4>
  //                   <p className="mb-6 text-sm text-gray-500">
  //                     At vero eos et accusamus et iusto odio dignissimos ducimus
  //                     qui blanditiis praesentium voluptatum deleniti atque
  //                     corrupti quos dolores et quas molestias excepturi sint
  //                     occaecati cupiditate non provident, similique sunt in
  //                     culpa qui officia deserunt mollitia animi, id est laborum
  //                     et dolorum fuga. Et harum quidem rerum facilis est et
  //                     expedita distinctio. Nam libero tempore, cum soluta nobis
  //                     est eligendi optio cumque nihil impedit quo minus id quod
  //                     maxime placeat facere possimus, omnis voluptas assumenda
  //                     est, omnis dolor repellendus. Temporibus autem quibusdam
  //                     et aut officiis debitis aut rerum necessitatibus saepe
  //                     eveniet ut et voluptates repudiandae sint et molestiae non
  //                     recusandae. Itaque earum rerum hic tenetur a sapiente
  //                     delectus, ut aut reiciendis voluptatibus maiores alias
  //                     consequatur aut perferendis doloribus asperiores repellat.
  //                   </p>

  //                   <h4 className="mb-2 font-medium text-violet">
  //                     Traduzione del 1914 di H. Rackham
  //                   </h4>
  //                   <p className="mb-6 text-sm text-gray-500">
  //                     On the other hand, we denounce with righteous indignation
  //                     and dislike men who are so beguiled and demoralized by the
  //                     charms of pleasure of the moment, so blinded by desire,
  //                     that they cannot foresee the pain and trouble that are
  //                     bound to ensue; and equal blame belongs to those who fail
  //                     in their duty through weakness of will, which is the same
  //                     as saying through shrinking from toil and pain. These
  //                     cases are perfectly simple and easy to distinguish. In a
  //                     free hour, when our power of choice is untrammelled and
  //                     when nothing prevents our being able to do what we like
  //                     best, every pleasure is to be welcomed and every pain
  //                     avoided. But in certain circumstances and owing to the
  //                     claims of duty or the obligations of business it will
  //                     frequently occur that pleasures have to be repudiated and
  //                     annoyances accepted. The wise man therefore always holds
  //                     in these matters to this principle of selection: he
  //                     rejects pleasures to secure other greater pleasures, or
  //                     else he endures pains to avoid worse pains.
  //                   </p>
  //                 </div>
  //               </div>
  //             </div>
  //           </Transition.Child>
  //         </div>
  //       </Dialog>
  //     </Transition.Root>
  //   </>
  // );
}
