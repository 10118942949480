import {
  ExclamationCircleIcon,
  EyeIcon,
  EyeOffIcon,
} from '@heroicons/react/solid';
import { ChangeEvent, ForwardedRef, forwardRef, useState } from 'react';
import classNames from '../../lib/utils/classNames';

interface IInputPresentational {
  label?: string;
  name: string;
  placeholder?: string;
  id?: string;
  type?: string;
  autoComplete?: string;
  hasError?: boolean;
  helperText?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const InputPresentational = forwardRef(
  (
    {
      label,
      id,
      name,
      type = 'text',
      autoComplete,
      hasError,
      helperText,
      disabled = false,
      ...rest
    }: IInputPresentational,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [plainTextPassword, setPlainTextPassword] = useState(false);
    const isPasswordInput = type === 'password';
    return (
      <div>
        {!!label && (
          <label className="block font-sans text-base font-normal text-purple">
            {label}
          </label>
        )}
        <div className="relative mt-1">
          <input
            ref={ref}
            id={id}
            name={name}
            type={plainTextPassword ? 'text' : type}
            autoComplete={autoComplete}
            disabled={disabled}
            readOnly={disabled}
            className={classNames(
              'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-base font-normal placeholder-gray-400 focus:border-purple focus:outline-none focus:ring-0',
              disabled
                ? 'cursor-not-allowed opacity-50'
                : 'cursor-auto opacity-100'
            )}
            {...rest}
          />
          <div className="absolute inset-y-0 right-7 flex items-center pr-3">
            {hasError && (
              <ExclamationCircleIcon
                className="m-2 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            )}
            {isPasswordInput && (
              <button
                type="button"
                className=""
                onClick={() => setPlainTextPassword(!plainTextPassword)}
              >
                {plainTextPassword ? (
                  <EyeOffIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <EyeIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </button>
            )}
          </div>
        </div>
        {helperText && (
          <p
            className={`mt-2 text-sm text-${hasError ? 'red-600' : 'gray-500'}`}
            id={`${id}-${hasError ? 'error' : 'helper'}`}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  }
);

export default InputPresentational;
