import axios, { AxiosResponse } from 'axios';
import QueryString from 'qs';
import { IContractPayload, IContractResponse } from '../../interfaces';
const instance = axios.create();

const { REACT_APP_CONTRACTS_URL } = process.env;

const create = async (token: string, values: IContractPayload) => {
  const query = {
    populate: {
      contractData: {
        populate: '*',
      },
      product: '*',
    },
  };
  const response = await instance.post<IContractResponse>(
    `${REACT_APP_CONTRACTS_URL}?${QueryString.stringify(query)}`,
    { data: values },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
    throw error;
  }

  return {
    ...response,
    error,
  };
};

export default create;
