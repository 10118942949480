import axios from 'axios';

const { REACT_APP_RESET_DOCUMENT_URL } = process.env;

const resetDocument = async (token: string) => {
  const response = await axios.post<{ ok: number }>(
    REACT_APP_RESET_DOCUMENT_URL!,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};

export default resetDocument;
