import { useParams } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import VariationsHistoryPresentational from './VariationsHistoryPresentational';
import { useQuery } from '@tanstack/react-query';
import { getContract } from '../../lib/fetch/contracts';

const VariationsHistory: React.FC = () => {
  const [{ token }] = useAuth();
  const { id: contractId } = useParams();
  const contractQuery = useQuery(
    ['contract', contractId],
    () => getContract(token!, Number(contractId)),
    {
      enabled: !!contractId,
      refetchOnWindowFocus: false,
    }
  );

  return <VariationsHistoryPresentational contractQuery={contractQuery} />;
};

export default VariationsHistory;
