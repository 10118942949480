import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IRegisterForm } from '.';
import { PrimaryButtonFull } from '../../components/Buttons';
import InputPresentational from '../../components/Form/InputPresentational';
import AppHead from '../../components/Headers/AppHead';
import BgLogin from '../../components/VariusElements/BgLogin';
import RespLogoLogin from '../../components/VariusElements/RespLogoLogin';

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface IRegistrationPresentational {
  loading: boolean;
  onRegister: (_: IRegisterForm) => void;
}

const RegistrationPresentational: React.FC<IRegistrationPresentational> = (
  props
) => {
  const { loading = false, onRegister = (_: IRegisterForm) => {} } = props;
  const { t } = useTranslation('LOGIN');
  const {
    register,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm<IRegisterForm>();

  return (
    <>
      <AppHead name={t('HEAD_NAME_REGISTRATION')} />
      <div className="flex min-h-screen font-sans">
        <BgLogin />

        <div className="relative flex w-3/5 flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <RespLogoLogin />

          <div className="mx-auto w-full max-w-xl">
            <h2 className="text-3xl font-bold text-purple">
              {t('REGISTRATION_TITLE')}
            </h2>

            <div className="mt-8">
              <form onSubmit={handleSubmit(onRegister)}>
                <div className="grid grid-cols-1 gap-6">
                  <InputPresentational
                    label={t('COMMON:EMAIL')}
                    id="email"
                    {...register('email', {
                      required: t('COMMON:REQUIRED') as string,
                      pattern: {
                        value: emailRegExp,
                        message: t('INVALID_EMAIL'),
                      },
                    })}
                    type="email"
                    autoComplete="email"
                    hasError={!!errors.email}
                    helperText={errors.email?.message}
                  />
                  <InputPresentational
                    label={t('EMAIL_CONFIRM')}
                    id="emailConfirm"
                    {...register('emailConfirm', {
                      required: t('COMMON:REQUIRED') as string,
                      pattern: {
                        value: emailRegExp,
                        message: t('INVALID_EMAIL'),
                      },
                      validate: (value) =>
                        value === getValues('email') ||
                        (t('EMAIL_NOT_MATCH') as string),
                    })}
                    type="email"
                    autoComplete="false"
                    hasError={!!errors.emailConfirm}
                    helperText={errors.emailConfirm?.message}
                  />
                  <InputPresentational
                    label={t('PASSWORD')}
                    id="password"
                    {...register('password', {
                      required: t('COMMON:REQUIRED') as string,
                    })}
                    type="password"
                    autoComplete="new-password"
                    hasError={!!errors.password}
                    helperText={errors.password?.message}
                  />
                  <InputPresentational
                    label={t('PASSWORD_CONFIRM')}
                    id="passwordConfirm"
                    {...register('passwordConfirm', {
                      required: t('COMMON:REQUIRED') as string,
                      validate: (value) =>
                        value === getValues('password') ||
                        (t('PASSWORD_NOT_MATCH') as string),
                    })}
                    type="password"
                    autoComplete="new-password"
                    hasError={!!errors.passwordConfirm}
                    helperText={errors.passwordConfirm?.message}
                  />
                </div>

                <div className="mx-auto mt-12 w-3/5 lg:mr-auto lg:ml-0">
                  <PrimaryButtonFull
                    text={t('SIGN_IN')}
                    loading={loading}
                    disabled={loading}
                    type="submit"
                  />
                </div>
              </form>
            </div>

            <p className="mt-6 text-center text-base text-gray-800 lg:absolute lg:top-6 lg:right-6 lg:mt-0">
              {t('ALREADY_ACCOUNT_QUESTION')}{' '}
              <Link
                to="/login"
                className="font-bold text-purple underline underline-offset-1 hover:text-violet"
              >
                {t('LOGIN')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationPresentational;
