import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputPresentational from '../../../components/Form/InputPresentational';
import SelectPresentational from '../../../components/Form/SelectPresentational';
import InfoBox from '../../../components/VariusElements/InfoBox';
import {
  SUBSCRIPTION_DURATION_OPTIONS,
  SUBSCRIPTION_FREQUENCY_OPTIONS,
} from '../../../lib/constants/subscription';
import { NumberParam, useQueryParams } from 'use-query-params';
import { IProduct } from '../../../lib/interfaces';
import { ISubscriptionData } from '../../../lib/interfaces/IContract';
import { useQuery } from '@tanstack/react-query';
import { getContract } from '../../../lib/fetch/contracts';
import { useAuth } from '../../../providers/AuthProvider';

type Props = {
  product?: IProduct;
};

export const QUERY_PARAMS_SCHEMA = Object.freeze({
  contractId: NumberParam,
});

const Pac = ({ product }: Props) => {
  const { t } = useTranslation('MY_CONTRACTS');
  const [query] = useQueryParams(QUERY_PARAMS_SCHEMA);
  const [{ token }] = useAuth();

  const { contractId } = query;

  useQuery(
    ['contractDetail', contractId],
    () => getContract(token!, contractId!),
    {
      enabled: !!contractId,
      onSuccess({ data }) {
        reset({
          pacAmount:
            data.data.attributes?.contractData?.subscriptionData?.pacAmount,
          pacDuration:
            data.data.attributes?.contractData?.subscriptionData?.pacDuration,
          pacFrequency:
            data.data.attributes?.contractData?.subscriptionData?.pacFrequency,
          contractTitle: data.data.attributes?.contractTitle,
          paymentMethod:
            data.data.attributes.contractData.subscriptionData.paymentMethod,
        });
      },
    }
  );

  const {
    register,
    formState: { errors },
    reset,
  } = useFormContext<ISubscriptionData & { contractTitle?: string }>();

  return (
    <div className="mb-8">
      <div className="mb-6 border-b border-gray-200 pb-4">
        <div className="flex items-center justify-between gap-2">
          <h3 className="mb-2 text-xl font-medium text-purple">
            {t('PAYMENT_PAC_TITLE')}
          </h3>
          <p className="text-sm lowercase text-violet">
            {t('MINIMUM_MONTHLY')}
            <span className="ml-2 inline-flex items-center rounded-md bg-violet px-2.5 py-0.5 text-sm font-medium text-white">
              € {product?.attributes?.minimumSubscription}
            </span>
          </p>
        </div>
        <p
          className="text-sm text-gray-500"
          dangerouslySetInnerHTML={{
            __html: t('PAYMENT_PAC_DESCRIPTION'),
          }}
        />
      </div>
      <div className="mb-5">
        <InputPresentational
          label={'Nome contratto (opzionale)'}
          id="contractTitle"
          {...register('contractTitle')}
          type="string"
          autoComplete="false"
          hasError={!!errors.contractTitle}
          helperText={errors.contractTitle?.message}
          disabled={!!contractId}
        />
      </div>
      <div className="my-6 grid grid-cols-1 gap-4 md:grid-cols-3">
        <InputPresentational
          label={t('ADD_IMPORT')}
          id="subscriptionAmount"
          {...register('pacAmount', {
            required: t('COMMON:REQUIRED') as string,
            min: {
              value: product?.attributes?.minimumSubscription || 20,
              message: t('MINIMUM_AMOUNT_VALUE', {
                amount: product?.attributes?.minimumSubscription || 20,
              }),
            },
          })}
          type="number"
          autoComplete="false"
          hasError={!!errors.pacAmount}
          helperText={errors.pacAmount?.message}
        />
        <SelectPresentational
          label={t('INVESTMENT_DURATION')}
          disabled={!!contractId}
          id="subscriptionDuration"
          {...register('pacDuration', {
            required: t('COMMON:REQUIRED') as string,
          })}
          autoComplete="false"
          hasError={!!errors.pacDuration}
          helperText={errors.pacDuration?.message}
          options={SUBSCRIPTION_DURATION_OPTIONS}
        />
        <SelectPresentational
          label={t('INVESTMENT_FREQUENCY')}
          id="subscriptionFrequency"
          {...register('pacFrequency', {
            required: t('COMMON:REQUIRED') as string,
          })}
          autoComplete="false"
          hasError={!!errors.pacFrequency}
          helperText={errors.pacFrequency?.message}
          options={SUBSCRIPTION_FREQUENCY_OPTIONS}
          disabled={!!contractId}
        />
      </div>
      <InfoBox description={t('INVESTMENT_INFO_PAC')} type={'info'} />
    </div>
  );
};
export default Pac;
