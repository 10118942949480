import axios, { AxiosResponse } from 'axios';
import { IUser } from '../../interfaces';

const { REACT_APP_UPDATE_PROFILE_URL } = process.env;

export interface IUpdateSecurityRequest {
  id: number;
  email?: string;
  phone?: string;
  password?: string;
}

const updateSecurity = async (
  token: string,
  userId: IUser['id'],
  body: IUpdateSecurityRequest
) => {
  const response = await axios.put<IUser>(
    `${REACT_APP_UPDATE_PROFILE_URL!}/${userId}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default updateSecurity;
