import { PaymentMethod, PaymentType } from '../constants/payments';
import { DocumentType, Gender } from '../constants/survey';
import { ContractStatus } from './../constants/contracts';
import { StrapiResponseList } from './Generics';
import { CouponBond } from './ICouponBonds';
import IProduct from './IProduct';
import IStrapiFile from './IStrapiFile';
import ISubscription from './ISubscription';

export interface ISubscriptionData {
  id: number;
  subscriptionType: PaymentType;
  paymentMethod: PaymentMethod;
  oneTimeAmount?: number;
  updatePACFrequencyModule?: {
    data: {
      id: number;
      attributes: IStrapiFile;
    };
  };
  pacAmount?: number;
  pacDuration?: number;
  productName: string;
  privacyConsent?: boolean;
  pacAutomaticChargeConsent?: boolean;
  pacFrequency?: PACFrequency;
}

export enum PACFrequency {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  ANNUAL = 'ANNUAL',
  HALF_YEARLY = 'HALF_YEARLY',
}

export interface ICustomerData {
  id: number;
  firstName: string;
  lastName: string;
  gender: Gender;
  birthDate: string;
  birthCountry: string;
  birthDistrict: string;
  birthCity: string;
  phone: string;
  address: string;
  houseNumber: string;
  postalCode: string;
  country: string;
  district: string;
  city: string;
  fiscalCode: string;
}

export interface IBankData {
  id: number;
  iban: string;
  bank: string;
  registeredTo: string;
  transferFiles: {
    data: { id: number; attributes: Omit<IStrapiFile, 'id'> }[] | null;
  };
}

export interface ICustomerDocument {
  id: number;
  type: DocumentType;
  number: string;
  releaseDate: string;
  issuingEntity: string;
  country: string;
  documentFiles: {
    data: { id: number; attributes: Omit<IStrapiFile, 'id'> }[] | null;
  };
}

export interface IContractData {
  product: any;
  id: number;
  step: number;
  customerData: ICustomerData;
  bankData: IBankData;
  customerDocument: ICustomerDocument;
  subscriptionData: ISubscriptionData;
  contractSignature: IContractSignature;
  privacySignature: IContractSignature;
  updatedTime?: string;
}

export interface IContractSignature {
  OTPGenerationTimestamp: string | null;
  OTPVerificationTimestamp: string | null;
  signed: boolean;
}

export interface IContractDraft {
  id: number;
  attributes: {
    contractNumber: number;
    contractTitle?: string;
    name?: string;
    status: ContractStatus.DRAFT;
    createdAt: string;
    updatedAt: string | null;
    product: {
      data: IProduct;
    };
    contractData: IContractData;
    compiledContract?: {
      data: {
        id: number;
        attributes: Omit<IStrapiFile, 'id'>;
      };
    };
    contractSignature?: IContractSignature;
    privacySignature?: IContractSignature;
  };
}

export interface BankDataVariation {
  holder?: string;
  IBANCode?: string;
  bankName?: string;
}
export interface ContractHistory {
  id?: string;
  fromAmount?: string | number | null;
  toAmount?: string | number | null;
  fromBankData?: BankDataVariation | null;
  toBankData?: BankDataVariation | null;
  date?: string;
}

export interface IContractConfirmed {
  id: number;
  attributes: {
    name?: string;
    date?: string;
    contractTitle?: string;
    contractNumber: string;
    status:
      | ContractStatus.ACTIVE
      | ContractStatus.DRAFT
      | ContractStatus.CLOSED
      | ContractStatus.SUSPENDED;
    createdAt: string;
    updatedAt: string | null;
    couponBonds: StrapiResponseList<CouponBond>[];
    product: {
      data: IProduct;
    };
    expirationDate: string | null;
    monthsDuration: number | null;
    subscription?: {
      data: ISubscription;
    };
    contractData: IContractData;
    updatingData: IContractData;
    compiledContract?: {
      data: {
        id: number;
        attributes: Omit<IStrapiFile, 'id'>;
      };
    };
    totalInvestment: number;
    isUpdatingSubAmount?: boolean;
    history?: IContractData[];
  };
}

export interface IContractPayload {
  user?: number;
  name?: string;
  product: number;
  contractTitle?: string | null;
  contractData: {
    id: number;
    step: number;
    customerData: Omit<ICustomerData, 'id'>;
    bankData: Omit<Omit<IBankData, 'id'>, 'transferFiles'> & {
      transferFiles?: (File | number)[];
    };
    customerDocument?: Omit<Omit<ICustomerDocument, 'id'>, 'documentFiles'> & {
      documentFiles?: (File | number)[];
    };
    subscriptionData?: Omit<ISubscriptionData, 'id'>;
  };
  updatingData?: {
    step: number;
    customerData: Omit<ICustomerData, 'id'>;
    bankData: Omit<IBankData, 'id'>;
    customerDocument?: Omit<Omit<ICustomerDocument, 'id'>, 'documentFiles'> & {
      documentFiles?: (File | number)[];
    };
    subscriptionData?: Omit<ISubscriptionData, 'id'>;
  };
  isUpdatingSubAmount?: boolean | null;
  fromClient: boolean;
}

export interface IContractResponse {
  data: IContract;
  meta: {};
}

export type IContract = IContractConfirmed;

export enum ContractSignatureType {
  CONTRACT_SIGNATURE = 'contractSignature',
  PRIVACY_SIGNATURE = 'privacySignature',
}
