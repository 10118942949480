import axios, { AxiosResponse } from 'axios';

const { REACT_APP_PAYMENTS_URL } = process.env;

const getCheckoutUrl = async (token: string, id: number) => {
  const response = await axios.get<string>(
    `${REACT_APP_PAYMENTS_URL}/${id}/checkout`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default getCheckoutUrl;
