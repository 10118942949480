import { useTranslation } from 'react-i18next';
import RowLabel from './RowLabel';
import { ProductStatistics } from '../../lib/interfaces/IStatistics';
import { IContractConfirmed } from '../../lib/interfaces';
import currency from '../../lib/utils/currency';
import {
  approximateNumber,
  getPriceValue,
  getProductStatistics,
  isStaticProduct,
} from '../../lib/utils/helpers';
import moment from 'moment';
import { useMemo } from 'react';
import TrendLabel from '../Labels/TrendLabel';
import { ProductType } from '../../lib/constants/payments';

interface Props {
  statistics?: ProductStatistics | null;
  contract?: IContractConfirmed | null;
  hidePerformance?: boolean;
}

const StatisticsSummary: React.FC<Props> = ({
  statistics,
  contract,
  hidePerformance,
}) => {
  const { t } = useTranslation('DASHBOARD');

  const productStats = useMemo(
    () => getProductStatistics(statistics),
    [statistics]
  );

  return (
    <div>
      <span className="flex w-full border-t border-gray-400" />
      <div className="flex flex-col py-2">
        <RowLabel label={t('PRODUCT_NAME')} value={statistics?.name} />
        {!hidePerformance && (
          <RowLabel
            label={t('PERFORMANCE')}
            value={
              <TrendLabel className="text-md" price={productStats?.increment} />
            }
          />
        )}
        <RowLabel
          label={t('SHARES_HELD')}
          value={approximateNumber({
            number: statistics?.totalShares,
            decimalsNum: 4,
          }).toLocaleString('it')}
        />
        {statistics?.productType === ProductType.STANDARD && (
          <RowLabel
            label={t('AVERAGE_LOADING_PRICE')}
            value={getPriceValue(statistics.lastAverageLoadPrice)}
          />
        )}
        <RowLabel
          label={t('TOTAL_PAID')}
          value={currency(statistics?.totalAmount || 0)}
        />
        {statistics?.couponBond && (
          <RowLabel
            label={t('ANNUAL_PERFORMANCE')}
            value={`${statistics?.couponBond?.percentage?.toLocaleString(
              'it'
            )}%`}
          />
        )}
        {statistics?.couponBond && (
          <RowLabel
            label={t('COUPON_BOND_FREQUENCY')}
            value={t(statistics?.couponBond.frequency)}
          />
        )}

        {isStaticProduct(contract?.attributes?.product?.data) && (
          <RowLabel
            label={t('EXPIRATION_DATE')}
            value={
              contract?.attributes?.expirationDate
                ? moment(contract?.attributes?.expirationDate).format(
                    'DD/MM/YYYY'
                  )
                : '-'
            }
          />
        )}

        {isStaticProduct(contract?.attributes?.product?.data) && (
          <RowLabel
            label={t('DURATION')}
            value={t('MONTHS_DURATION', {
              duration: contract?.attributes?.monthsDuration || '-',
            })}
          />
        )}

        {contract?.attributes?.subscription?.data?.attributes?.amount && (
          <RowLabel
            label={t('MONTHLY_INSTALMENT')}
            value={currency(
              contract?.attributes?.subscription?.data?.attributes?.amount
            )}
          />
        )}
        {statistics?.totalDisinvestedAmount !== 0 && (
          <RowLabel
            label={t('TOTAL_DISINVESTED_AMOUNT')}
            value={currency(
              Math.abs(
                Number(statistics?.totalDisinvestedAmount.toFixed(2) || 0)
              )
            )}
          />
        )}
        {statistics?.totalDisinvestedShares !== 0 && (
          <RowLabel
            label={t('TOTAL_DISINVESTED_QUOTES')}
            value={Math.abs(
              Number(statistics?.totalDisinvestedShares.toFixed(4) || 0)
            ).toLocaleString('it')}
          />
        )}

        {!contract && statistics && (
          <span className="flex flex-row justify-between">
            <p className="font-regular py-1 text-base text-violet">
              {t('ACTUAL_VALUE')}
            </p>
            <p className="font-regular py-1 text-base font-bold text-violet">
              {currency(productStats?.currentBalance)}
            </p>
          </span>
        )}
      </div>
    </div>
  );
};

export default StatisticsSummary;
