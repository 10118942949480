import { useTranslation } from 'react-i18next';

const BgLogin = () => {
  const { t } = useTranslation('LOGIN');
  return (
    <div className="relative hidden w-0 flex-1 bg-rigsave-login bg-cover bg-left-bottom lg:block">
      <div className="mt-4 flex">
        <img
          className=" z-10 mx-auto w-40 justify-center"
          src="../images/logo-rigsave.png"
          alt="Rigsave"
        />
      </div>

      <div className="absolute inset-0 h-full w-full bg-purple/10" />
      <h3
        className="absolute right-6 bottom-6 w-4/5 text-right text-3xl font-normal text-white drop-shadow-xl"
        dangerouslySetInnerHTML={{
          __html: t('BG_DESCRIPTION'),
        }}
      />
    </div>
  );
};

export default BgLogin;
