import { useTranslation } from 'react-i18next';

interface IStepsHeaderMobile {
  currentStep: number;
  steps: readonly string[];
  onChangeStep: (_: number) => void;
  disabled?: boolean;
}

export default function StepsHeaderMobile({
  currentStep,
  steps,
  onChangeStep = (_: number) => {},
  disabled = false,
}: IStepsHeaderMobile) {
  const { t } = useTranslation('SURVEY');
  return (
    <nav className="flex items-center justify-center" aria-label="Progress">
      <p className="text-sm font-medium">
        {t('STEP')} {currentStep + 1} {t('COMMON:OF')} {steps.length}
      </p>
      <ol className="ml-8 flex items-center space-x-5">
        {steps.map((stepName, index) => (
          <li key={index}>
            {currentStep > index ? (
              <span
                className="block h-2.5 w-2.5 rounded-full bg-purple"
                onClick={() => {
                  onChangeStep(index);
                }}
              >
                <span className="sr-only">{t(stepName)}</span>
              </span>
            ) : currentStep === index ? (
              <span
                className="relative flex items-center justify-center"
                aria-current="step"
                onClick={() => {
                  onChangeStep(index);
                }}
              >
                <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                  <span className="h-full w-full rounded-full bg-purple opacity-30" />
                </span>
                <span
                  className="relative block h-2.5 w-2.5 rounded-full bg-purple"
                  aria-hidden="true"
                />
                <span className="sr-only">{t(stepName)}</span>
              </span>
            ) : (
              <span
                className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
                onClick={() => {
                  onChangeStep(index);
                }}
              >
                <span className="sr-only">{t(stepName)}</span>
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
