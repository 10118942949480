import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { me, update } from '../../lib/fetch/auth';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import AdditionalDataPresentational, {
  IAdditionalDataForm,
} from './AdditionalDataPresentational';

const AdditionalData = () => {
  const { t } = useTranslation();
  const [, dispatchAlertChange] = useAlert();
  const navigate = useNavigate();
  const [{ user, token }, dispatchAuthChange] = useAuth();

  const refetchProfile = async () => {
    const { data: updatedUser } = await me(token!);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
  };

  const onSubmit = async (values: IAdditionalDataForm) => {
    const { error } = await update(token!, { ...values, id: user!.id });
    if (error) {
      const { data } = error || {};
      let { message } = data || {};
      message ||= t('COMMON:SOMETHING_WENT_WRONG');

      return dispatchAlertChange({
        open: true,
        type: AlertType.Error,
        message,
      });
    }
    await refetchProfile();
    navigate('/phone-confirm', { state: { redirectTo: '/signup-completed' } });
  };
  return <AdditionalDataPresentational onSubmit={onSubmit} />;
};

export default AdditionalData;
