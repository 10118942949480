import { useEffect, useState } from 'react';
import { useAuth } from '../../providers/AuthProvider';
import listNews from '../../lib/fetch/news/list';
import INews from '../../lib/interfaces/INews';
import NewsListPresentational from './NewsListPresentational';
import { useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';

const ListNews: React.FC<{}> = () => {
  const [{ token }] = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [news, setNews] = useState<INews[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation();

  const fetchNews = async () => {
    const { error, data } = await listNews({
      token: token as string,
      page: currentPage,
    });
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      return dispatchAlertChange({
        open: true,
        message: message ? t(`ERRORS:${message}`) : t('COMMON:GENERIC_ERROR'),
      });
    }
    if (data) {
      const { meta } = data;
      setPageCount(meta.pagination.pageCount);
      setNews(data.data || []);
    }
  };

  useEffect(() => {
    fetchNews();
  }, [currentPage]);

  return (
    <NewsListPresentational
      news={news}
      currentPage={currentPage}
      pageCount={pageCount}
      onChangePage={setCurrentPage}
    />
  );
};

export default ListNews;
