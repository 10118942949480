import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../../components/Buttons';
import AppHead from '../../components/Headers/AppHead';

import HeaderPage from '../../components/Navigation/HederPage';
import Sidebar from '../../components/Navigation/Sidebar';
import PaymentTypeBox from '../../components/VariusElements/PaymentTypeBox';
import { PaymentType } from '../../lib/constants/payments';

type Props = {
  productId: number;
};

const PaymentTypePresentational = (props: Props) => {
  const { productId } = props;
  const { t } = useTranslation('MY_CONTRACTS');
  const navigate = useNavigate();

  return (
    <>
      <AppHead name={t('HEAD_NAME_PAYMENT_TYPE')} />
      <div className="flex h-full min-h-screen font-sans">
        <Sidebar />
        <div className="flex-1 lg:pl-52">
          <div className="py-10 px-6">
            <HeaderPage title={t('PAYMENT_TYPE_TITLE')} />

            <div className="w-full">
              <div className="w-full">
                <BackButton
                  onClick={() => navigate(-1)}
                  text={t('COMMON:BACK')}
                />
              </div>

              <h4 className="block font-sans text-lg font-normal text-purple">
                {t('PAYMENT_TYPE_DESCRIPTION')}
              </h4>

              <div className="mt-6 space-y-8 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
                <PaymentTypeBox
                  title={t('PAYMENT_ONE-TIME_TITLE')}
                  description={t('PAYMENT_ONE-TIME_DESCRIPTION')}
                  linkText={`/payment?productId=${productId}&paymentType=${PaymentType.ONE_TIME}`}
                />

                <PaymentTypeBox
                  title={t('PAYMENT_PAC_TITLE')}
                  description={t('PAYMENT_PAC_DESCRIPTION')}
                  linkText={`/payment?productId=${productId}&paymentType=${PaymentType.PAC}`}
                />

                <PaymentTypeBox
                  title={t('PAYMENT_ONE-TIME_PAC_TITLE')}
                  description={t('PAYMENT_ONE-TIME_PAC_DESCRIPTION')}
                  linkText={`/payment?productId=${productId}&paymentType=${PaymentType.ONE_TIME_PAC}`}
                />
              </div>
              {/* {!!hasActiveSubscription && (
                <h4 className="mt-5 block font-sans text-sm font-normal text-purple">
                  * Non puoi effettuare versamenti PAC perchè ne hai già uno
                  attivo per questo prodotto
                </h4>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentTypePresentational;
