import { XIcon } from '@heroicons/react/outline';
import { IStrapiFile } from '../../lib/interfaces';

type IFileUploadPrev = {
  file: IStrapiFile | File;
  onDelete: (file: IStrapiFile | File) => Promise<void>;
  disabled?: boolean;
};

const FileUploadPrev = ({ file, onDelete, disabled }: IFileUploadPrev) => {
  if (file instanceof File) {
    const { name } = file;
    const url = window.URL.createObjectURL(file);
    return (
      <div className="flex flex-row items-center">
        <button
          type="button"
          onClick={() => onDelete(file)}
          className={
            'mr-4 inline-flex items-center rounded-full border border-transparent p-0.5 text-red-600 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2' +
            disabled
              ? ' cursor-not-allowed'
              : ''
          }
          disabled={disabled}
        >
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2 h-9 w-9 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <a href={url} target="_blank" rel="noreferrer">
          <p className="text-base font-bold text-purple underline underline-offset-1 hover:text-violet">
            {name}
          </p>
        </a>
      </div>
    );
  }

  const { name, url } = file;

  return (
    <div className="flex flex-row items-center">
      <button
        type="button"
        onClick={() => onDelete(file)}
        className={
          'mr-4 inline-flex items-center rounded-full border border-transparent p-0.5 text-red-600 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2' +
          disabled
            ? ' cursor-not-allowed'
            : ''
        }
        disabled={disabled}
      >
        <XIcon className="h-5 w-5" aria-hidden="true" />
      </button>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="mr-2 h-9 w-9 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <a href={url} target="_blank" rel="noreferrer">
        <p className="text-base font-bold text-purple underline underline-offset-1 hover:text-violet">
          {name}
        </p>
      </a>
    </div>
  );
};

export default FileUploadPrev;
