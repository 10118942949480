interface Props {
  price?: number | string | null;
  className?: string;
}

const TrendLabel: React.FC<Props> = ({ price, className }) => {
  if (!price) return <>-</>;

  return (
    <p
      className={`text-md text-${
        Number(price) < 0 ? 'red-500' : 'green-500'
      } ${className}`}
    >
      {Number(Number(price).toFixed(2))?.toLocaleString('it')}%
    </p>
  );
};

export default TrendLabel;
