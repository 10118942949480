import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import IFaq from '../../../lib/interfaces/IFaq';
import classNames from '../../../lib/utils/classNames';

interface Props {
  faqs: IFaq[];
}

const FaqsTab: React.FC<Props> = ({ faqs = [] }) => {
  const { t } = useTranslation('SETTINGS');
  return (
    <>
      <div className="py-6">
        <div className="mb-8 space-y-1">
          <h3 className="text-lg font-medium leading-6 text-violet">
            {t('FAQS')}
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            {t('FAQS_DESCRIPTION')}
          </p>
        </div>

        {!!faqs && !!faqs.length && (
          <div className="bg-gray-100 px-4 pb-6">
            <dl className="space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure
                  as="div"
                  key={faq.attributes.question}
                  className="pt-6"
                >
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="flex w-full items-start justify-between text-left">
                          <span className="font-semibold text-purple">
                            {faq.attributes.question}
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? '-rotate-180' : 'rotate-0',
                                'h-6 w-6 transform text-violet'
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-sm text-violet">
                          {faq.attributes.answer}
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        )}
      </div>
    </>
  );
};
export default FaqsTab;
