import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InputPresentational from '../../components/Form/InputPresentational';
import PrimaryButtonFull from '../../components/Buttons/PrimaryButtonFull';
import { FixedAlert } from '../../components/Alert';
import BackButton from '../../components/Buttons/BackButton';
import { AlertType } from '../../providers/AlertProvider';
import { useNavigate } from 'react-router-dom';
import BgLogin from '../../components/VariusElements/BgLogin';
import RespLogoLogin from '../../components/VariusElements/RespLogoLogin';
import AppHead from '../../components/Headers/AppHead';

interface IForgotPresentational {
  loading: boolean;
  result?: AlertType;
  resultTitle?: string;
  resultDescription?: string;
  onForgot: (formData: { email: string }) => void;
}

const ForgotPresentational = (props: IForgotPresentational) => {
  const navigate = useNavigate();
  const {
    loading = false,
    result,
    resultTitle,
    resultDescription,
    onForgot = (_: { email: string }) => {},
  } = props;
  const { t } = useTranslation('FORGOT');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>();

  return (
    <>
      <AppHead name={t('HEAD_NAME')} />
      <div className="flex min-h-screen font-sans">
        <BgLogin />

        <div className="relative flex w-3/5 flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <RespLogoLogin />
          <div className="mx-auto w-full max-w-xl">
            <div className="mb-10">
              <BackButton
                text={t('BACK_LOGIN')}
                onClick={() => navigate('/login')}
              />
            </div>
            <h2 className="text-3xl font-bold text-purple">{t('TITLE')}</h2>
            <p className="mt-2 text-sm text-gray-600">{t('DESCRIPTION')}</p>

            <div className="mt-8 mb-6">
              <form onSubmit={handleSubmit(onForgot)}>
                <div className="mb-6 grid grid-cols-1 gap-6">
                  <InputPresentational
                    label={t('COMMON:EMAIL')}
                    id="email"
                    type="email"
                    autoComplete="email"
                    {...register('email', {
                      required: t('COMMON:REQUIRED') as string,
                    })}
                    hasError={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </div>
                <div className="mx-auto w-3/5 lg:mr-auto lg:ml-0">
                  <PrimaryButtonFull
                    loading={loading}
                    text={t('RESET')}
                    type="submit"
                  />
                </div>
              </form>
            </div>
            <FixedAlert
              type={result}
              title={resultTitle}
              description={resultDescription}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPresentational;
