import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { me, update as updateUser } from '../../lib/fetch/auth';
import {
  upload as uploadFiles,
  remove as removeFile,
} from '../../lib/fetch/files';
import {
  create as createUserAssessment,
  update as updateUserAssessment,
} from '../../lib/fetch/userAssessment';
import { IStrapiFile, IUser, IUserAssessment } from '../../lib/interfaces';
import filterFiles from '../../lib/utils/filterFiles';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { AuthActionType, useAuth } from '../../providers/AuthProvider';
import SurveyPresentational from './SurveyPresentational';

const { REACT_APP_FILES_PATH_PREFIX } = process.env;
const LAST_STEP_INDEX = 3;
const ACCEPTED_FILES = Object.freeze([
  'image/png',
  'image/jpeg',
  'application/pdf',
  'text/plain',
]);

const Survey = () => {
  const location = useLocation();
  const { state } = location;
  const { isEditing = false } = (state || {}) as { isEditing?: boolean };
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const { uuid } = user || {};
  const [currentStep, setCurrentStep] = useState(user?.onboardingStep || 0);
  const [loading, setLoading] = useState(false);
  const [userDocuments, setUserDocuments] = useState<IStrapiFile[]>([]);
  const [residentialDocuments, setResidentialDocuments] = useState<
    IStrapiFile[]
  >([]);
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('SURVEY');

  useEffect(() => {
    refetchProfile();
  }, []);

  useEffect(() => {
    setUserDocuments(user?.personalDocuments || []);
    setResidentialDocuments(user?.residentialDocuments || []);
  }, [user]);

  const refetchProfile = async () => {
    const { data: updatedUser } = await me(token!);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
  };

  const onFileChange = async (newFiles: File[]) => {
    setLoading(true);
    const filteredFiles = filterFiles(newFiles, {
      acceptedTypes: ACCEPTED_FILES,
      maxSizeMB: 10,
      unsupportedFileCallback: (file: File, messageKey: string) =>
        dispatchAlertChange({
          open: true,
          message: t(messageKey, { fileName: file.name }),
        }),
    });

    const { id: refId } = user || {};
    const path = REACT_APP_FILES_PATH_PREFIX
      ? `${REACT_APP_FILES_PATH_PREFIX}/${uuid}`
      : undefined;
    if (refId && filterFiles.length) {
      await uploadFiles(token!, {
        files: filteredFiles,
        refId,
        ref: 'plugin::users-permissions.user',
        field: 'personalDocuments',
        path,
      });

      dispatchAlertChange({
        message: t('FILE_UPLOAD_SUCCESS'),
        open: true,
        type: AlertType.Success,
      });

      await refetchProfile();
    }
    setLoading(false);
  };
  const onResidenceFileChange = async (newFiles: File[]) => {
    setLoading(true);
    const filteredFiles = filterFiles(newFiles, {
      acceptedTypes: ACCEPTED_FILES,
      maxSizeMB: 10,
      unsupportedFileCallback: (file: File, messageKey: string) =>
        dispatchAlertChange({
          open: true,
          message: t(messageKey, { fileName: file.name }),
        }),
    });

    const { id: refId } = user || {};
    const path = REACT_APP_FILES_PATH_PREFIX
      ? `${REACT_APP_FILES_PATH_PREFIX}/${uuid}`
      : undefined;
    if (refId && filterFiles.length) {
      await uploadFiles(token!, {
        files: filteredFiles,
        refId,
        ref: 'plugin::users-permissions.user',
        field: 'residentialDocuments',
        path,
      });

      dispatchAlertChange({
        message: t('FILE_UPLOAD_SUCCESS'),
        open: true,
        type: AlertType.Success,
      });

      await refetchProfile();
    }
    setLoading(false);
  };

  const onFileDelete = async (fileToDelete: IStrapiFile) => {
    setLoading(true);
    await removeFile(token!, fileToDelete);
    dispatchAlertChange({
      message: t('FILE_DELETE_SUCCESS', { name: fileToDelete.name }),
      open: true,
      type: AlertType.Success,
    });
    await refetchProfile();
    setLoading(false);
  };

  const onSubmitUser = async (values: IUser) => {
    setLoading(true);
    const {
      firstName,
      lastName,
      gender,
      citizenship,
      fiscalCode,
      phone,
      phone2,
      birthDate,
      birthCountry,
      birthDistrict,
      birthCity,
      address,
      houseNumber,
      postalCode,
      country,
      district,
      city,
      conventionCode,
      termsConditions,
    } = values;

    const userUpdate = {
      id: user!.id,
      firstName,
      lastName,
      gender,
      citizenship,
      fiscalCode,
      phone,
      phone2,
      birthDate,
      birthCountry,
      birthDistrict,
      birthCity,
      address,
      houseNumber,
      postalCode,
      country,
      district,
      city,
      conventionCode,
      termsConditions,
    };

    const { error } = await updateUser(token!, userUpdate);
    if (error) {
      let { data: { error: { message = undefined } = {} } = {} } = error;
      setLoading(false);
      throw new Error(message);
    }
    setLoading(false);
  };

  const onSubmitUserAssessment = async (values: IUserAssessment) => {
    setLoading(true);
    const {
      maritalStatus,
      studyDegree,
      monthlyIncome,
      fundsSource,
      goodsAmount,
      politicalExposure,
      financialKnowledge,
      riskAppetite,
      investmentGoal,
      investmentIntention,
      surveyTermsConditions,
    } = values;

    const userAssessment = {
      users_permissions_user: user!.id,
      maritalStatus,
      studyDegree,
      monthlyIncome:
        (!isNaN(Number(monthlyIncome)) && Number(monthlyIncome)) || undefined,
      fundsSource,
      goodsAmount,
      politicalExposure,
      financialKnowledge,
      riskAppetite,
      investmentGoal,
      investmentIntention,
      surveyTermsConditions,
    };

    if (!user?.user_assessment) {
      const { error } = await createUserAssessment(token!, userAssessment);
      if (error) {
        let { data: { error: { message = undefined } = {} } = {} } = error;
        setLoading(false);
        return dispatchAlertChange({
          open: true,
          message,
        });
      }
    } else {
      const { error } = await updateUserAssessment(token!, {
        ...userAssessment,
        id: user.user_assessment.id,
      });
      if (error) {
        let { data: { error: { message = undefined } = {} } = {} } = error;
        setLoading(false);
        return dispatchAlertChange({
          open: true,
          message,
        });
      }
    }
    setLoading(false);
  };

  const onNextStep = async () => {
    setLoading(true);
    const nextStep =
      currentStep < LAST_STEP_INDEX ? currentStep + 1 : currentStep;
    if (user!.onboardingStep < LAST_STEP_INDEX) {
      const { error } = await updateUser(token!, {
        id: user?.id,
        onboardingStep: nextStep,
      });
      if (error) {
        let { data: { error: { message = undefined } = {} } = {} } = error;
        setLoading(false);
        return dispatchAlertChange({
          open: true,
          message,
        });
      }
    }
    setLoading(false);
    refetchProfile();
    setCurrentStep(currentStep + 1);
  };

  const onChangeStep = (step: number) => {
    const canGoForward = step <= user!.onboardingStep;
    if (canGoForward) setCurrentStep(step);
  };

  return (
    <SurveyPresentational
      isEditing={isEditing}
      currentStep={currentStep}
      onChangeStep={onChangeStep}
      onSubmitUser={onSubmitUser}
      onSubmitUserAssessment={onSubmitUserAssessment}
      loading={loading}
      userDocuments={userDocuments}
      residentialDocuments={residentialDocuments}
      acceptedFiles={ACCEPTED_FILES}
      onFileChange={onFileChange}
      onResidenceFileChange={onResidenceFileChange}
      onFileDelete={onFileDelete}
      onNextStep={onNextStep}
    />
  );
};

export default Survey;
