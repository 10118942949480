import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import SmallOutlinePrimaryButtonFull from '../Buttons/SmallOutlinePrimaryButtonFull';
import { UserStatistics } from '../../lib/interfaces/IStatistics';
import Spinner from '../Spinner';
import StatisticsSummary from './StatisticsSummary';
import { getProductListStatistics } from '../../lib/utils/helpers';

interface Props {
  isLoading: boolean;
  userStatistics?: UserStatistics;
}

const InvestmentsBoxDashboard: React.FC<Props> = ({
  isLoading,
  userStatistics,
}) => {
  const [{ user }] = useAuth();
  const { t } = useTranslation('DASHBOARD');

  return (
    <div className="w-full rounded-md border border-gray-200 px-4 py-6">
      {userStatistics ? (
        <>
          <p className="font-regular mb-4 text-base text-violet">
            {t('INVESTMENT_VALUE')}
          </p>
          <div className="flex items-start justify-end gap-4">
            <div className="text-right">
              <p className="mb-1 text-5xl font-bold text-purple">
                {Number(
                  getProductListStatistics(
                    userStatistics.products.map((elem) => ({
                      ...elem,
                      excludeClosedContracts: true,
                    }))
                  ).currentBalance?.toFixed(2)
                )?.toLocaleString('it')}
              </p>
              {/* <TrendLabel
                price={
                  getProductStatistics(userStatistics?.products)?.increment
                }
                className="mb-1 text-2xl font-bold"
              /> */}
            </div>
            <span className="mr-4 font-bold uppercase text-purple">eur</span>
          </div>
        </>
      ) : !isLoading ? (
        t('NO_DATA')
      ) : null}

      {isLoading && !userStatistics && (
        <Spinner className="my-4 flex w-full text-purple" />
      )}

      {userStatistics &&
        userStatistics.products.map((product) => {
          return <StatisticsSummary statistics={product} />;
        })}

      {!user?.onboardingDone && (
        <div className="mt-6 flex flex-col gap-4">
          <Link to="/survey">
            <SmallOutlinePrimaryButtonFull
              text={t('LOGIN:FILL_IN_THE_QUESTIONNAIRE')}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default InvestmentsBoxDashboard;
