import axios, { AxiosResponse } from 'axios';
import { IUserAssessmentCreate, IUserAssessmentCreateResponse } from './create';

const { REACT_APP_USER_ASSESSMENTS_URL } = process.env;

export interface IUserAssessmentUpdate extends IUserAssessmentCreate {
  id: number;
}

export interface IUserAssessmentUpdateResponse
  extends IUserAssessmentCreateResponse {}

const update = async (token: string, assessment: IUserAssessmentUpdate) => {
  const response = await axios.put<{ data: IUserAssessmentUpdateResponse }>(
    `${REACT_APP_USER_ASSESSMENTS_URL!}/${assessment.id}`,
    { data: assessment },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  let error: AxiosResponse | undefined;
  if (axios.isAxiosError(response)) {
    error = response.response || response;
  }

  return {
    ...response,
    error,
  };
};

export default update;
