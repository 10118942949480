import React from 'react';
import { useTranslation } from 'react-i18next';
import DragAndDrop from '../../../components/Form/DragAndDrop';
import FileUploadPrev from '../../../components/Form/FileUploadPrev';
import { IStrapiFile } from '../../../lib/interfaces';

interface IStep2 {
  files: IStrapiFile[];
  acceptedFiles: readonly string[];
  onFileChange: (_: File[]) => Promise<void>;
  onFileDelete: (_: IStrapiFile) => Promise<void>;
}

const Step2: React.FC<IStep2> = (props) => {
  const { acceptedFiles = [], files = [], onFileChange, onFileDelete } = props;

  const { t } = useTranslation('SURVEY');
  return (
    <div className="pt-6 pb-12 md:px-10">
      <h2 className="mb-6 text-3xl font-bold text-purple">
        {t('IDENTITY_DOCUMENTS')}
      </h2>

      <DragAndDrop acceptedFiles={acceptedFiles} onFileChange={onFileChange} />

      <div className="mt-8 flex flex-col gap-4">
        {files.map((file, index) => (
          <FileUploadPrev
            key={index}
            file={file}
            onDelete={(file) => onFileDelete(file as IStrapiFile)}
          />
        ))}
      </div>
    </div>
  );
};

export default Step2;
