import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputPresentational from '../../../components/Form/InputPresentational';
import { useAuth } from '../../../providers/AuthProvider';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from '../../../i18n';
import { PrimaryButtonFull } from '../../../components/Buttons';

export interface ISecurityUpdateForm {
  email: string;
  phone: string;
  newPassword?: string;
  passwordConfirm?: string;
}

const validationSchema = yup.object({
  email: yup.string().email().required(i18n.t('COMMON:REQUIRED')),
  phone: yup.string().required(i18n.t('COMMON:REQUIRED')),
  newPassword: yup.string().optional(),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('newPassword')], i18n.t('LOGIN:PASSWORD_NOT_MATCH')),
});

export default function SecurityTab({
  onSubmit = () => {},
}: {
  onSubmit: (values: ISecurityUpdateForm) => void;
}) {
  const { t } = useTranslation('SETTINGS');
  const [{ user }] = useAuth();
  const resolver = yupResolver(validationSchema);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ISecurityUpdateForm>({
    defaultValues: {
      email: user?.email,
      phone: user?.phone,
    },
    resolver,
  });

  return (
    <>
      <div className="py-6 ">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-violet">
            {t('SECURITY')}
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            {t('SECURITY_DESCRIPTION')}
          </p>
        </div>

        <div className="mt-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">
                  {t('EMAIL')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <InputPresentational
                    type="email"
                    autoComplete="email"
                    {...register('email', {
                      required: t('COMMON:REQUIRED') as string,
                    })}
                    hasError={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">
                  {t('PHONE')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <InputPresentational
                    type="text"
                    autoComplete="tel"
                    {...register('phone', {
                      required: t('COMMON:REQUIRED') as string,
                    })}
                    hasError={!!errors.phone}
                    helperText={errors.phone?.message}
                  />
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">
                  {t('PASSWORD')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <InputPresentational
                    label={t('PASSWORD')}
                    type="password"
                    autoComplete="new-password"
                    {...register('newPassword', {
                      required: t('COMMON:REQUIRED') as string,
                    })}
                    hasError={!!errors.newPassword}
                    helperText={errors.newPassword?.message}
                  />
                  <br />
                  <InputPresentational
                    label={t('PASSWORD_CONFIRM')}
                    type="password"
                    autoComplete="new-password"
                    {...register('passwordConfirm', {
                      required: t('COMMON:REQUIRED') as string,
                    })}
                    hasError={!!errors.passwordConfirm}
                    helperText={errors.passwordConfirm?.message}
                  />
                </dd>
              </div>
            </dl>
            <div className="mt-4 flex justify-end">
              <div className="w-1/5">
                <PrimaryButtonFull text="Salva" type="submit" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
